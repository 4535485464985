import IconMenu from "anchor-ui/icon-menu";
import MenuItem from "anchor-ui/menu-item";
import {
  IconDelete,
  IconClose,
  IconReport,
  IconMore,
} from "anchor-ui/icons";
import { checkIfUserCanBeCalled } from "../../../utils/CallFunctions";

const ConversationButton = ({
  conversation,
  handleRemoveConversation,
  conversationName,
  handleInitiateCall,
}: {
  conversation: CometChat.Conversation | CometChat.Group,
  handleRemoveConversation: (conversation: CometChat.Conversation | CometChat.Group) => void,
  conversationName: string,
  handleInitiateCall: (user: CometChat.User) => void,
}) => {

  const isConversation = (conversation: CometChat.Conversation | CometChat.Group): conversation is CometChat.Conversation => {
    return 'getConversationType' in conversation && (conversation as CometChat.Conversation).getConversationType() === "user";
  };

  const getConversationWith = (conversation: CometChat.Conversation): CometChat.User => {
    return conversation.getConversationWith() as CometChat.User;
  };

  return (
    <IconMenu
      icon={
        isConversation(conversation) &&
          checkIfUserCanBeCalled(getConversationWith(conversation), true) ? (
          <IconMore />
        ) :
          <IconClose />}
    >
      <MenuItem
        text={`Verwijder ${conversationName}`}
        icon={<IconDelete />}
        onClick={() => handleRemoveConversation(conversation)}
      />

      {
        isConversation(conversation) &&
          checkIfUserCanBeCalled(getConversationWith(conversation), true) ? (
          <MenuItem
            text="Start audio of videogesprek"
            icon={<IconReport />}
            onClick={() => handleInitiateCall(getConversationWith(conversation))}
          />
        ) : <></>
      }
    </IconMenu>
  );
};

export default ConversationButton;
