import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { applyActionCode, Auth } from 'firebase/auth';
import ChatPlazaLogo from '../../assets/chatplaza-logo.svg';
import CardHeader from 'anchor-ui/card-header';

import Card from 'anchor-ui/card';
import Button from 'anchor-ui/button';
import { Functions, httpsCallable } from 'firebase/functions';

const VerifyEmailComponent = ({
  auth,
  oobCode,
  email,
  functions,
}: {
  auth: Auth,
  oobCode: string;
  email: string | null;
  functions: Functions;
}) => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const verifyEmail = async ({ auth, oobCode }: { auth: Auth, oobCode: string }) => {
    try {
      await applyActionCode(auth, oobCode);

      setSuccess(true);
    } catch (error) {
      setError('Ongeldige of verlopen verificatie code');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!oobCode || success) return;

    verifyEmail({ auth, oobCode });
  }, [oobCode, auth, success]);

  return (
    <section className="action-page">
      <Card className="action-card">
        <img src={ChatPlazaLogo} className="logo" />
        <CardHeader title="Verifieer Email" style={{ alignSelf: "center" }} />
        {loading ? (
          <p>Verifiëren...</p>
        ) : success ? (
          <>
            <p>Email {email} succesvol geverifieerd!</p>
            <Button
              inverted
              onClick={() => navigate('/login')}
              style={{ width: '100%' }}
            >
              Naar login
            </Button>
          </>
        ) : (
          <>
            <p>Verifieer email {email}</p>
            {error && <p className="action-error-message">{error}</p>}
          </>
        )}
      </Card>
    </section>
  );
};

export default VerifyEmailComponent;