import Modal from "anchor-ui/modal";
import Button from "anchor-ui/button";
import Input from "anchor-ui/input";
import { SetStateAction, useState, useRef, useEffect } from "react";
import { Functions, httpsCallable } from "firebase/functions";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import * as XLSX from "xlsx";
import { getGenderFromMetadata } from "../../utils/getGenderFromMetadata";
import { checkIfUserIsAdmin, checkIfUserIsModerator } from "../../utils/checkIfUserIsAdmin";
import List from "anchor-ui/list";
import ListItem from "anchor-ui/list-item";
import Avatar from "anchor-ui/avatar";
import AdminBadge from "anchor-ui/admin-badge";
import defaultAvatar from "../../assets/default_avatar.jpg";
import "./styles.css";

const ExportMessagesModal = ({
  isOpen,
  setIsOpen,
  setAlert,
  functions,
  friends,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  functions: Functions;
  friends: CometChat.User[];
}) => {
  // User search state
  const [searchInput, setSearchInput] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [userList, setUserList] = useState<CometChat.User[]>([]);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<CometChat.User | null>(null);

  // Firebase callable function
  const fetchAllUserMessages = httpsCallable(functions, "fetchAllUserMessages");
  const searchUsersAsync = httpsCallable(functions, "searchUsers");

  // Date selection state
  const today = new Date();
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(today.getMonth() - 6);

  const formatDate = (date: Date): string => {
    return date.toISOString().split('T')[0];
  };

  const [fromDate, setFromDate] = useState<string>(formatDate(sixMonthsAgo));
  const [toDate, setToDate] = useState<string>(formatDate(today));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<string>("");

  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null);

  // Handle search input focus and click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
        setSearchActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputFocus = () => {
    setSearchActive(true);
  };

  const searchUsers = async (searchKey: string) => {
    // if input is less than 3 characters, don't search
    if (searchKey.length < 3) {
      setIsSearching(false);
      return;
    }

    setIsSearching(true);

    const data = await searchUsersAsync({ searchKey }) as any;

    if (data) {
      const users = data.data.data;
      let userArray: CometChat.User[] = [];

      // make a unique user object for every user
      users.forEach((user: any) => {
        let userObj = new CometChat.User(user.uid);
        userObj.setName(user.name);
        userObj.setRole(user.role);
        userObj.setAvatar(user.avatar);
        userObj.setMetadata(user.metadata);
        userArray.push(userObj);
      });

      setUserList(userArray);
    }

    setIsSearching(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInput(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setIsSearching(true);
    setUserList([]);
    setTypingTimeout(setTimeout(() => searchUsers(value), 500));
  };

  const handleUserSelection = (user: CometChat.User) => {
    setSelectedUser(user);
    setSearchActive(false);
    setSearchInput(user.getName());
  };

  const handleDownload = async () => {
    if (!selectedUser) {
      setAlert({ message: "Selecteer eerst een gebruiker", type: "error" });
      return;
    }

    try {
      setIsLoading(true);
      setProgress("Berichten ophalen...");

      // Convert dates to timestamps
      const fromTimestamp = Math.floor(new Date(fromDate).getTime() / 1000).toString();
      let toTimestamp = Math.floor(new Date(toDate).getTime() / 1000 + 86399).toString(); // End of the day

      // Initialize an empty array to store all messages
      let allMessages: any[] = [];
      let hasMoreMessages = true;
      let fetchCounter = 0;
      let lastMessageTimestamp: string | null = null;

      // Keep fetching until there are no more messages
      while (hasMoreMessages) {
        fetchCounter++;
        setProgress(`Berichten ophalen...`);

        const result = await fetchAllUserMessages({
          uid: selectedUser.getUid(),
          fromTimestamp,
          toTimestamp
        });

        const data = result.data as { messages: any[], xlsxContent?: string, totalCount?: number };
        const batchMessages = data.messages;

        // If no messages or same timestamp as before, break out of the loop
        if (batchMessages.length === 0) {
          hasMoreMessages = false;
          break;
        }

        // Add the batch to our collection
        allMessages = allMessages.concat(batchMessages);

        // Check if we received the maximum number of messages (1000)
        if (batchMessages.length === 1000) {
          // Get the timestamp of the last message
          const currentLastMessageTimestamp = batchMessages[0].sentAt.toString();

          // Check if we're getting the same timestamp again
          if (currentLastMessageTimestamp === lastMessageTimestamp) {
            hasMoreMessages = false;
            break;
          }

          // Update the lastMessageTimestamp for the next iteration check
          lastMessageTimestamp = currentLastMessageTimestamp;

          // Update the toTimestamp to be slightly before the timestamp of the last message
          // This ensures we don't include the last message in the next fetch
          const lastTimestampNum = parseInt(currentLastMessageTimestamp);
          toTimestamp = (lastTimestampNum - 1).toString();
        } else {
          // If we received fewer than 1000 messages, we've got all of them
          hasMoreMessages = false;
        }
      }

      if (allMessages.length > 0) {
        setProgress("XLSX bestand genereren...");

        const filename = `${selectedUser.getName()}_berichten_${fromDate}_${toDate}.xlsx`;

        // Trigger download
        convertMessagesToExcel(allMessages, filename);

        setProgress("Bestand downloaden...");

        setAlert({
          message: `${allMessages.length} berichten geëxporteerd naar ${filename}`,
          type: "success"
        });
      } else {
        setAlert({ message: "Geen berichten gevonden voor deze periode", type: "warning" });
      }
    } catch (error) {
      console.error("Error downloading messages:", error);
      setAlert({
        message: "Er is een fout opgetreden bij het exporteren van berichten",
        type: "error"
      });
    } finally {
      setIsLoading(false);
      setProgress("");
    }
  };

  const convertMessagesToExcel = (messages: any[], filename: string) => {
    // Extract data fields but exclude the unwanted columns
    const formattedData = messages.map((msg) => {
      const sender = msg.data.entities.sender?.entity || msg.data.entities.by.entity || {};
      const receiver = msg.data.entities.receiver?.entity || msg.data.entities.for.entity || {};
      const sentDate = new Date(msg.sentAt * 1000);

      return {
        "Bericht ID": msg.id,
        "Verzender": sender.name || "Onbekend",
        "Verzender ID": sender.uid || "Onbekend",
        "Verzender Rol": sender.role || "Onbekend",
        "Bericht": msg.data.text || msg.data.action || "",
        "Verzonden Op": sentDate.toLocaleDateString("nl-NL"),
        "Verzonden Tijd": sentDate.toLocaleTimeString("nl-NL"),
        "Chat": receiver.name || receiver || "Onbekend",
        "Groep/Conversation ID": receiver.guid || msg.conversationId || "Onbekend",
        "Groep Type": receiver.type + " group" || "1 op 1 Chat",
        "Groep Leden": receiver.membersCount || 0
      };
    });

    // Create worksheet from the formatted data
    const ws = XLSX.utils.json_to_sheet(formattedData);

    // Better column widths for readability
    ws["!cols"] = [
      { wch: 15 },  // Bericht ID
      { wch: 20 },  // Verzender
      { wch: 30 },  // Verzender ID
      { wch: 15 },  // Verzender Rol
      { wch: 80 },  // Bericht
      { wch: 15 },  // Verzonden Op
      { wch: 15 },  // Verzonden Tijd
      { wch: 20 },  // Groep
      { wch: 25 },  // Groep ID
      { wch: 15 },  // Groep Type
      { wch: 15 },  // Groep Leden
    ];

    // Set row height for header
    ws["!rows"] = [{ hpt: 30 }];  // Header row height

    // Apply styles to all cells
    const range = XLSX.utils.decode_range(ws["!ref"] || "A1");

    // Make sure we have a valid range
    if (!range || typeof range !== 'object') {
      console.error("Invalid range object", range);
      return;
    }

    // Create workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Berichten");

    // Add title and metadata to the workbook
    wb.Props = {
      Title: "Berichten Export",
      Subject: "Chat Berichten Geschiedenis",
      Author: "Chat Export Tool",
      CreatedDate: new Date()
    };

    // Write to file with the provided filename (ensure it's .xlsx)
    const outputFilename = filename.endsWith(".xlsx") ? filename : filename.replace(/\.[^/.]+$/, ".xlsx");
    XLSX.writeFile(wb, outputFilename);

    return {
      rowCount: formattedData.length,
      filename: outputFilename
    };
  };

  const handleClose = () => {
    setIsOpen(false);
    setSelectedUser(null);
    setSearchInput("");
    setUserList([]);
    setFromDate(formatDate(sixMonthsAgo));
    setToDate(formatDate(today));
  };

  const Searching = () => (
    <div className="reveal-content reset-position">
      <p>Bezig met zoeken...</p>
    </div>
  );

  const NoResults = () => (
    <div className="reveal-content reset-position">
      <p>Sorry, we hebben niks voor je kunnen vinden.</p>
    </div>
  );

  const SearchResults = ({ userList }: { userList: CometChat.User[] }) => (
    <div className="reveal-content search-results reset-position">
      <List header={`Users (${userList.length})`}>
        {userList.map((user, index) => {
          const isAdmin = checkIfUserIsAdmin(user);
          const isModerator = checkIfUserIsModerator(user);
          const isFriend = friends.some(friend => friend.getUid() === user.getUid());

          return (
            <ListItem
              key={index}
              avatar={
                <Avatar
                  image={user.getAvatar() ?? defaultAvatar}
                  status={user.getStatus()}
                />
              }
              textBadge={
                <>
                  {(isAdmin || isModerator) &&
                    <AdminBadge text={isAdmin ? "Admin" : "Mod"} style={{ marginRight: "4px" }} />
                  }
                  {isFriend &&
                    <AdminBadge text="Vriend" />
                  }
                </>
              }
              primaryText={user.getName()}
              secondaryText={getGenderFromMetadata(user)}
              onClick={() => handleUserSelection(user)}
            />
          )
        })}
      </List>
    </div>
  );

  return (
    <Modal
      open={isOpen}
      actions={
        <>
          <Button flatButton onClick={handleClose} disabled={isLoading}>ANNULEER</Button>
          <Button flatButton onClick={handleDownload} disabled={isLoading || !selectedUser}>
            {isLoading ? progress || "BEZIG MET DOWNLOADEN..." : "DOWNLOAD .xlsx"}
          </Button>
        </>
      }
      onClose={handleClose}
    >
      <h2>Exporteer berichten</h2>
      <div className="search-container" ref={searchContainerRef}>
        <Input
          ref={searchInputRef}
          label="Zoek gebruiker"
          value={searchInput}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          maxLength={500}
        />
        {searchActive && (
          <>
            {searchInput.length < 3 ? null :
              isSearching ? (
                <Searching />
              ) : !userList.length ? (
                <NoResults />
              ) : (
                <SearchResults userList={userList} />
              )}
          </>
        )}
      </div>
      <br />

      {selectedUser && (
        <div className="selected-user-info">
          <div><strong>Username:</strong> {selectedUser.getName()}</div>
          <div><strong>UID:</strong> {selectedUser.getUid()}</div>
        </div>
      )}
      <br />

      <Input
        label="Vanaf"
        type="date"
        value={fromDate}
        name="fromDate"
        onChange={(e: { target: { value: SetStateAction<string> } }) => setFromDate(e.target.value)}
        required
        min={formatDate(sixMonthsAgo)}
        max={toDate}
        maxLength={50}
      />
      <br />

      <Input
        label="Tot en met"
        type="date"
        value={toDate}
        name="untilDate"
        onChange={(e: { target: { value: SetStateAction<string> } }) => setToDate(e.target.value)}
        required
        min={fromDate}
        max={formatDate(today)}
        maxLength={50}
      />
      <br />
    </Modal>
  );
};

export default ExportMessagesModal;