import Hero from '../Layout/Hero';
import './styles.css';
import Dialog from "anchor-ui/dialog";
import Button from "anchor-ui/button";
import { useState } from 'react';
import { User } from 'firebase/auth';
import { Functions, httpsCallable } from 'firebase/functions';

interface PaymentResult {
  redirectUrl: string;
  sessionId: string;
}

const subscriptionTypes = [
  {
    id: 'premium',
    name: '⭐ Premium 🥉',
    role: 'premium',
    prices: {
      '1y': 2450,
      '2y': 4475
    },
    description: [
      "✔️ Toegang tot exclusieve premium-kanalen",
      "✔️ Een opvallende 'Premium'-badge bij je naam",
      "✔️ Jouw account wordt bovenaan de online gebruikerslijst getoond",
      "✔️ Mogelijkheid om tot 2 privé groepen aan te maken",
      "✔️ Audio- en videogesprekken met iedereen, ook met niet-betalende gebruikers",
    ]
  },
  {
    id: 'vip',
    name: '🌟 VIP 🎖️',
    role: "vip",
    prices: {
      '1y': 3945,
      '2y': 7150
    },
    description: [
      "✔️ Een exclusieve 'VIP'-badge bij je naam",
      "✔️ Extra ruimte voor galerijfoto’s op je profiel",
      "✔️ Toegang tot andere lettertypes",
      "✔️ Mogelijkheid om een persoonlijke profielbanner in te stellen",
      "✔️ Creëer tot 5 privé groepen",
      "➕ Inclusief alle voordelen van Premium",
    ]
  },
  {
    id: "royal",
    name: "👑 Royal 🥇",
    role: "royal",
    prices: {
      '1y': 4975,
      '2y': 8900
    },
    description: [
      "✔️ Een prestigieuze 'Royal'-badge bij je naam",
      "✔️ Pas je berichtenstijl aan zodat deze voor iedereen opvalt*",
      "✔️ Creëer onbeperkt privé groepen",
      "✔️ Volledig advertentievrij platform!",
      "➕ Inclusief alle voordelen van VIP & Premium",
    ]
  }
];

const SubscriptionsPage = ({
  firebaseUser,
  payFunctions,
}: {
  firebaseUser: User,
  payFunctions: Functions
}) => {
  const [selectedSubscription, setSelectedSubscription] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState<'1y' | '2y' | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubscriptionSelect = (subscriptionId: string) => {
    setSelectedSubscription(subscriptionId);
    setDialogOpen(true); // Open the dialog when a subscription is selected
  };

  const getDialogHeader = () => {
    const selected = subscriptionTypes.find(sub => sub.id === selectedSubscription);
    return selected ? selected.name : "Abonnement";
  };

  const getButtonText = (duration: '1y' | '2y') => {
    if (!selectedSubscription) return { text: '', monthlyText: '' };

    const selected = subscriptionTypes.find(sub => sub.id === selectedSubscription);
    if (!selected) return { text: '', monthlyText: '' };

    const text = duration === "1y" ? "1 jaar" : "2 jaar";
    const price = selected.prices[duration];
    const monthlyPrice = (price / 100) / (duration === '1y' ? 12 : 24);

    return {
      text: `${text}: €${(price / 100).toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
      monthlyText: `€${monthlyPrice.toLocaleString('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} p/m`,
    };
  };

  const handlePayment = async (subscriptionToPay: string) => {
    // Check if the subscription exists
    if (!subscriptionTypes.find(sub => sub.id === selectedSubscription)) {
      alert('Selecteer een abonnement');
      return;
    }

    setLoading(true);
    try {
      const fUid = firebaseUser.uid; // Ensure `firebaseUser` is passed as a prop to this component
      const createPayment = httpsCallable(payFunctions, 'createPaymentStripe');
      const result = await createPayment({
        subscription: subscriptionToPay, // e.g., "premium-1y" or "vip-2y"
        returnUrl: `${window.location.origin}/payment-result`,
        fUid,
      });

      // Save sessionId in cache with a timestamp
      if (result.data && (result.data as PaymentResult).sessionId) {
        const sessionId = (result.data as PaymentResult).sessionId;
        const cacheData = {
          sessionId,
          timestamp: Date.now(), // Current time in milliseconds
        };
        localStorage.setItem(`paymentSession_${firebaseUser.uid.toLowerCase()}`, JSON.stringify(cacheData));
      }

      // Redirect to Stripe Checkout page
      if (result.data && (result.data as PaymentResult).redirectUrl) {
        window.location.href = (result.data as PaymentResult).redirectUrl;
      } else {
        throw new Error('No redirect URL received');
      }
    } catch (error) {
      console.error('Payment initiation failed:', error);
      alert('Er is een fout opgetreden bij het starten van de betaling.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        header={getDialogHeader()} // Dynamic header based on selected subscription
        hideDialog={() => setDialogOpen(false)} // Close the dialog when clicking outside
        headerStyle={{ color: 'rgb(117, 117, 117)', fontSize: '24px', fontWeight: 'bolder', textAlign: 'center' }}
        style={{ backgroundColor: 'white' }}
        iconColor={'rgba(117, 117, 117, 0.5)'}
      >
        <span className="warning-dialog-text">
          Selecteer een abonnement & <br />upgrade nu je account! 🚀
          <br /><br />
          <small>Kies voor 1 of 2 jaar met een eenmalige betaling.</small>
          <br /><br />
          <Button
            onClick={() => { handlePayment(`${selectedSubscription}-1y`) }}
            inverted
          >
            {getButtonText("1y").text}
            <br />
            <b>{getButtonText("1y").monthlyText}</b>
          </Button>
          &nbsp;&nbsp;
          <Button
            onClick={() => { handlePayment(`${selectedSubscription}-2y`) }}
          >
            {getButtonText("2y").text}
            <br />
            <b>{getButtonText("2y").monthlyText}</b>
          </Button>
        </span>
      </Dialog>

      <div className="w-100">
        <Hero
          heroText="Abonnementen"
          heroImg={"/vip-bg.avif"}
          className="chat-hero"
        />

        <div className="subscriptions-container">
          <div className="subscriptions-grid">
            {subscriptionTypes.map((subscription, index) => (
              <div key={subscription.id} className="subscription-card">
                <div className="subscription-header">
                  <h2>{subscription.name} {index === 1 && (<><br /> (meest gekozen)</>)}</h2>
                </div>
                <div className="subscription-content">
                  <ul className="subscription-features">
                    {subscription.description.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                  <div className="subscription-pricing">
                    <div className="price-options">
                      <div className="price-option">
                        <span>1 jaar</span>
                        <strong>€{((subscription.prices['1y'] / 100) / 12).toFixed(2)} p/m</strong>
                      </div>
                      <div className="price-option">
                        <span>2 jaar</span>
                        <strong>€{((subscription.prices['2y'] / 100) / 24).toFixed(2)} p/m</strong>
                      </div>
                    </div>
                    <button
                      className="subscription-button"
                      onClick={() => handleSubscriptionSelect(subscription.id)}
                    >
                      Selecteer {subscription.name}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <br />
          * Binnenkort beschikbaar
        </div>
      </div>
    </>
  );
};

export default SubscriptionsPage;