import { CometChat } from "@cometchat/chat-sdk-javascript";
import Modal from 'anchor-ui/modal';
import Button from 'anchor-ui/button';
import Input from 'anchor-ui/input';
import { SetStateAction, useState } from 'react';
import { IconClose } from 'anchor-ui/icons';
import Divider from 'anchor-ui/divider';
import { ActiveChannelInterface } from '../../../interfaces/activeChannelInterface';

const CreatePollModal = ({
  open,
  setOpen,
  currentChat,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentChat: ActiveChannelInterface;
}) => {

  const [pollQuestion, setPollQuestion] = useState('');
  const [pollOptions, setPollOptions] = useState(['', '']);

  const handleClose = () => {
    setPollQuestion('');
    setPollOptions(['', '']);
    setOpen(false);
  };

  const handleCreatePoll = async () => {
    const receiver = currentChat.receiverId || currentChat.id;
    const receiverType = currentChat.isGroup ? 'group' : 'user';

    try {
      await CometChat.callExtension('polls', 'POST', 'v2/create', {
        question: pollQuestion,
        options: pollOptions.filter(option => option.trim() !== ''),
        receiver,
        receiverType
      });
      handleClose();
    } catch (error) {
      console.error('Error creating poll:', error);
    }
  };

  const handleOptionChange = (index: number, value: string) => {
    const newOptions = [...pollOptions];
    newOptions[index] = value;
    setPollOptions(newOptions);
  };

  const handleAddOption = () => {
    if (pollOptions.length < 8) {
      setPollOptions([...pollOptions, '']);
    }
  };

  const handleRemoveOption = (index: number) => {
    const newOptions = pollOptions.filter((_, i) => i !== index);
    setPollOptions(newOptions);
  };

  return (
    <Modal
      open={open}
      header={'Maak een poll'}
      actions={
        <>
          <Button flatButton onClick={handleClose}>ANNULEER</Button>
          <Button flatButton onClick={handleCreatePoll}>VERZEND</Button>
        </>
      }
      headerStyle={{ fontSize: '16px' }}
    >
      <Input
        label="Vraag"
        placeholder="Vul hier je vraag in"
        value={pollQuestion}
        onChange={(e: { target: { value: SetStateAction<string>; }; }) => setPollQuestion(e.target.value)}
        maxLength={50}
        style={{ maxWidth: '100%' }}
      />

      <Divider className="poll-divider" />

      {pollOptions.map((option, index) => (
        <div key={index} className="poll-option-container">
          <Input
            label={`Optie ${index + 1}`}
            placeholder={`Vul hier optie ${index + 1} in`}
            value={option}
            onChange={(e: { target: { value: string; }; }) => handleOptionChange(index, e.target.value)}
            maxLength={50}
            style={{ maxWidth: '100%' }}
          />
          {pollOptions.length > 2 && (
            <Button iconButton onClick={() => handleRemoveOption(index)} className="poll-remove-button">
              <IconClose />
            </Button>
          )}
        </div>
      ))}

      <div className="poll-add-option-container">
        {pollOptions.length < 8 && (
          <Button
            inverted
            onClick={handleAddOption}
            className="poll-add-option-button"
          >
            Voeg optie toe
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default CreatePollModal;