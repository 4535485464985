import React, { useEffect, useState } from 'react';
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { IconRadio } from 'anchor-ui/icons';
import { getActiveColor } from '../../utils/activeColor';

const PollMessage = ({
  message,
  myMessage,
  setAlert,
  loggedInUser,
}: {
  message: CometChat.BaseMessage;
  myMessage: boolean;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  loggedInUser: CometChat.User;
}) => {
  const pollData = (message as any).data.customData;
  const pollId = (message as any).data.customData.id;

  const initialResults = (message as any).metadata['@injected'].extensions.polls.results.options;
  const total = (message as any).metadata['@injected'].extensions.polls.results.total;

  const [results, setResults] = useState(initialResults);
  const [checkedOption, setCheckedOption] = useState<string | null>(null);
  const [hasVoted, setHasVoted] = useState(false);

  useEffect(() => {
    setResults((message as any).metadata['@injected'].extensions.polls.results.options);
    // Check if the user has already voted
    const userHasVoted = Object.entries(initialResults).some(([key, option]: [string, any]) => {
      if (option.voters && option.voters[loggedInUser.getUid()]) {
        setCheckedOption(key);
        return true;
      }
      return false;
    });
    setHasVoted(userHasVoted);
  }, [message, initialResults, loggedInUser]);

  const handleVote = async (id: string, option: string) => {
    try {
      await CometChat.callExtension('polls', 'POST', 'v2/vote', {
        vote: option,
        id: id,
      });

      // Update the results state with the new vote count
      setResults((prevResults: { [x: string]: { count: number; voters: { [key: string]: { name: string; avatar: string } } }; }) => {
        const newResults = { ...prevResults };

        // Remove all votes from the user
        Object.entries(newResults).forEach(([key, value]) => {
          if (value.voters && value.voters[loggedInUser.getUid()]) {
            delete value.voters[loggedInUser.getUid()];
            value.count -= 1;
          }
        });

        // Add the new vote
        if (!newResults[option].voters) {
          newResults[option].voters = {};
        }
        newResults[option].count += 1;
        newResults[option].voters[loggedInUser.getUid()] = {
          name: loggedInUser.getName(),
          avatar: loggedInUser.getAvatar()
        };

        return newResults;
      });

      setCheckedOption(option);
      setHasVoted(true);
    } catch (error) {
      console.error('Error voting:', error);
      setAlert({ message: 'Er is een fout opgetreden bij het stemmen op de poll', type: 'error' });
    }
  };

  const getLabel = (key: string, value: string) => {
    if (hasVoted || myMessage) {
      if (total > 0) {
        const percentage = ((results[key].count / total) * 100).toFixed(2);
        return `${value} (${results[key].count} stemmen, ${percentage}%)`;
      }
      return `${value} (0 stemmen, 0%)`;
    }
    return value;
  };

  const getChecked = (key: string) => {
    return checkedOption === key;
  }

  return (
    <div className="poll-container">
      <p className="poll-question">{pollData.question}</p>
      <div className="poll-options">
        {Object.entries(pollData.options).map(([key, value]) => (
          <div
            key={key + pollId}
            className="poll-option"
            onClick={() => {
              handleVote(pollId, key)
            }}
          >
            <IconRadio
              color={getChecked(key) ? getActiveColor() : undefined}
              className={getChecked(key) ? 'checked' : ''}
            />
            <label htmlFor={key + pollId} className="poll-label">{getLabel(key, value as string)}</label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PollMessage;