export const premiumRoles = [
  "premium", "vip", "royal", // premium roles
  "moderator", "admin", "superadmin", // admin roles
];

// This function checks if the user has a subscription based on their role.
export const checkUserHasSubscription = (role: string) => {
  const isPremium = premiumRoles.includes(role);
  return isPremium;
}

// This function checks if the user is a VIP or Royal member based on their role.
export const checkUserIsVIPOrRoyal = (role: string) => {
  const premiumRolesWithoutPremium = premiumRoles.slice(1);
  const isVIPOrRoyal = premiumRolesWithoutPremium.includes(role);
  return isVIPOrRoyal;
}

// This function checks if the user is a Royal member or has admin/moderator privileges.
export const checkUserIsRoyal = (role: string) => {
  const royalRoles = premiumRoles.filter((r) => ["royal", "moderator", "admin", "superadmin"].includes(r));
  const isRoyal = royalRoles.includes(role);
  return isRoyal;
};