import Profile from 'anchor-ui/profile';
import defaultAvatar from "./../../assets/default_avatar.jpg";
import Button from 'anchor-ui/button';
import { Call, CometChat } from "@cometchat/chat-sdk-javascript";
import { FriendRequest } from './../../utils/class/FriendRequest';
import { getGenderFromMetadata } from './../../utils/getGenderFromMetadata';
import { getActiveColor } from './../../utils/activeColor';
import { useEffect, useState } from 'react';
import { metadataInterface } from '../../interfaces/metadataInterface';
import { Functions, httpsCallable } from 'firebase/functions';
import { CometChatCalls } from '@cometchat/calls-sdk-javascript';
import { checkIfUserCanBeCalled, generateCallToken, startCallSession } from '../../utils/CallFunctions';

const UserProfileConvComponent = ({
  user,
  friends,
  handleFriendRequest,
  incomingFriendRequests,
  handleAcceptFriendRequest,
  outgoingFriendRequests,
  functions,

  ongoingCall,
  outgoingCall,

  handleInitiateCall,
  handleTerminateCall,
}: {
  user: CometChat.User | undefined,
  friends: CometChat.User[],
  handleFriendRequest: (user: CometChat.User) => void,
  incomingFriendRequests: FriendRequest[],
  handleAcceptFriendRequest: (request: FriendRequest) => void
  outgoingFriendRequests: CometChat.User[],
  functions: Functions,

  ongoingCall: Call | null,
  outgoingCall: { call: Call, receiver: CometChat.User } | null;

  handleInitiateCall: (user: CometChat.User) => void;
  handleTerminateCall: () => void;
}) => {
  const fetchUserProfile = httpsCallable(functions, 'fetchUserProfile');
  const [userData, setUserData] = useState<metadataInterface | null>(null);
  const [canMakeCall, setCanMakeCall] = useState(false);

  const fetchUserData = async (user: CometChat.User | undefined) => {
    setUserData(null);
    if (!user) return;
    const now = new Date().getTime();

    const oldData = localStorage.getItem(user.getUid());

    // Check if data is older than 48 hours
    if (oldData && JSON.parse(oldData).lastFetched + 172800000 > now) {
      return setUserData(JSON.parse(oldData));
    }

    const userProfileResult = await fetchUserProfile({ uid: user.getUid() });
    const userProfile = userProfileResult.data as unknown as metadataInterface;
    userProfile.lastFetched = now;

    // Save data to local storage so we don't have to fetch it again for 48 hours
    localStorage.setItem(user.getUid(), JSON.stringify(userProfile));

    setUserData(userProfile);
  }

  // const handleInitiateCall = async () => {
  //   if (!user) return;

  //   try {
  //     // End any active call
  //     const activeCall = CometChat.getActiveCall();
  //     if (activeCall) {
  //       await CometChat.rejectCall(activeCall.getSessionId(), CometChat.CALL_STATUS.CANCELLED);
  //     }

  //     const call: CometChat.Call = new CometChat.Call(
  //       user.getUid(),
  //       CometChat.CALL_TYPE.AUDIO,
  //       CometChat.RECEIVER_TYPE.USER
  //     );

  //     const outGoingCall = await CometChat.initiateCall(call);
  //     setOutgoingCall({ call: outGoingCall, receiver: user });

  //     // Generate call token for this session
  //     const callToken = await generateCallToken(outGoingCall.getSessionId(), loggedInUser!);
  //     if (callToken) {
  //       startCallSession(callToken, setOngoingCall);
  //     }
  //   } catch (e) {
  //     console.error("Error while initiating call:", e);
  //   }
  // };

  // const handleTerminateCall = async () => {
  //   try {
  //     if (outgoingCall) await CometChat.rejectCall(outgoingCall?.call.getSessionId() + "", CometChat.CALL_STATUS.CANCELLED);
  //     CometChatCalls.endSession();
  //     setOutgoingCall(null);
  //     setOngoingCall(null);
  //   } catch (e) {
  //     console.error("Error while terminating call:", e);
  //   }
  // }

  useEffect(() => {
    fetchUserData(user);

    if (!user) return;

    setCanMakeCall(checkIfUserCanBeCalled(user, friends));
  }, [user]);

  const isFriend = () => {
    if (!user) return null;
    return friends.some(friend => friend.getUid() === user.getUid());
  }

  const isFriendOrRequestSent = () => {
    if (!user) return null;
    return isFriend() || outgoingFriendRequests.some(friend => friend.getUid() === user.getUid());
  }

  if (!user) {
    return null;  // or return a loading spinner, or some placeholder content
  }

  let avatar = defaultAvatar;
  if (user.getAvatar() !== undefined) {
    avatar = user.getAvatar();
  }

  const hasReceivedFriendRequest = (user: CometChat.User) => {
    return incomingFriendRequests.some(friendRequest => friendRequest.getUid() === user.getUid());
  };

  const handleAcceptBtnClick = (user: CometChat.User) => {
    const friendRequest = incomingFriendRequests.find(friendRequest => friendRequest.getUid() === user.getUid());
    if (!friendRequest) return;

    handleAcceptFriendRequest(friendRequest);
  }

  const getAgeFromBirthDate = (birthDate: string): number | string => {
    const birthDateObj = new Date(birthDate);
    const now = new Date();

    let age = now.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = now.getMonth() - birthDateObj.getMonth();
    const dayDiff = now.getDate() - birthDateObj.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return isNaN(age) ? "?" : age;
  };

  return (
    <div className='conversation-profile'>
      <Profile
        coverImage={avatar}
        avatar={avatar}
        header={user.getName()}
        secondaryText={getGenderFromMetadata(user, true)}
        status={user.getStatus()}
        coverImageStyle={{ backgroundColor: getActiveColor() }}
        button={
          <>
            {
              !isFriendOrRequestSent() &&
              <>
                {hasReceivedFriendRequest(user) ?
                  <Button onClick={() => handleAcceptBtnClick(user)}>
                    Accepteer vriendschapsverzoek
                  </Button> :
                  <Button onClick={() => handleFriendRequest(user)}>
                    Stuur Een Vriendschapsverzoek
                  </Button>
                }
              </>
            }

            {
              canMakeCall &&
              <>
                <div className="call-buttons">
                  {ongoingCall || outgoingCall ?
                    <Button onClick={handleTerminateCall}>
                      Stop audiogesprek
                    </Button> :
                    <Button onClick={() => handleInitiateCall(user)}>
                      Start audio of videogesprek
                    </Button>
                  }
                </div>
              </>
            }

            <h1 className='conv-with-heading'>Leeftijd</h1>
            {getAgeFromBirthDate(userData?.about?.birthDate ?? "")}
          </>
        }
      />
    </div>
  );
}

export default UserProfileConvComponent;