import { CometChat } from "@cometchat/chat-sdk-javascript";
import Modal from 'anchor-ui/modal';
import Button from 'anchor-ui/button';
import Input from 'anchor-ui/input';
import { useState } from 'react';
import { Functions, httpsCallable } from 'firebase/functions';
import Select from 'anchor-ui/select';
import MenuItem from 'anchor-ui/menu-item';
import { ActiveChannelInterface } from "../../interfaces/activeChannelInterface";

const WarnUserModal = ({
  userToWarn,
  setUserToWarn,
  setAlert,
  functions,
  handleKickUser,
  handleLogoutKickUser,
  currentChat,
}: {
  userToWarn: CometChat.User | null;
  setUserToWarn: (userToWarn: CometChat.User | null) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  functions: Functions;
  handleKickUser: (user: CometChat.User, group: CometChat.Group) => void;
  handleLogoutKickUser: (user: CometChat.User) => void;
  currentChat: ActiveChannelInterface;
}) => {
  const [warningReason, setWarnReason] = useState("");
  const [extraMeasures, setExtraMeasures] = useState("none");

  const handleClose = () => {
    setWarnReason("");
    setUserToWarn(null);
  };

  const handleWarn = async () => {
    if (!userToWarn || !warningReason) return;

    try {
      const sendWarning = httpsCallable(functions, 'sendWarning');
      await sendWarning({ uid: userToWarn.getUid(), warningMessage: warningReason });

      // Do extra measures if needed
      if (extraMeasures === "kick") {
        handleKickUser(userToWarn, new CometChat.Group(currentChat.id, currentChat.name));
      } else if (extraMeasures === "hard_kick") {
        handleLogoutKickUser(userToWarn);
      }

      setWarnReason("");
      setExtraMeasures("none");
      setAlert({ message: 'Waarschuwing verstuurd', type: 'success' });
      setUserToWarn(null);
    } catch (error) {
      console.error("Error sending warning:", error);
      setAlert({ message: 'Er is iets fout gegaan bij het waarschuwen van de gebruiker', type: 'error' });
    }
  };

  if (!userToWarn) return <></>;

  return (
    <Modal
      open={userToWarn !== null}
      actions={
        <>
          <Button flatButton onClick={handleClose}>ANNULEER</Button>
          <Button flatButton onClick={handleWarn}>WAARSCHUW</Button>
        </>
      }
      headerStyle={{ fontSize: '16px' }}
    >
      <Input
        name={'extraInfo'}
        label={`[${userToWarn.getName()}] Waarschuwing reden`}
        value={warningReason}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setWarnReason(event.target.value)}
        maxLength={500}
        style={{ maxWidth: '100%' }}
      />

      <Select
        label={"Extra maatregelen"}
        onChange={(event: object, value: string) => setExtraMeasures(value)}
        value={extraMeasures}
        style={{ marginTop: "12.5px" }}
      >
        <MenuItem text={"Geen"} value={"none"} />
        <MenuItem text={"Kick uit kanaal"} value={"kick"} />
        <MenuItem text={"Harde kick (log uit)"} value={"hard_kick"} />
      </Select>
    </Modal>
  );
};

export default WarnUserModal;
