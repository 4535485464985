import { CometChat } from "@cometchat/chat-sdk-javascript";
import { FriendRequest } from './../../utils/class/FriendRequest';
import { Functions, httpsCallable } from 'firebase/functions';

export const sendFriendRequest = async (functions: Functions, receiverId: string) => {
  try {
    const sendFriendRequest = httpsCallable(functions, 'friendRequest');
    await sendFriendRequest({ receiverId });
  }
  catch (err) {
    console.error("Error sending friend request:", err);
    throw err;
  }
}

export const fetchIncomingFriendRequests = async (functions: Functions, user: CometChat.User): Promise<FriendRequest[]> => {
  try {
    const getIncomingFriendRequests = httpsCallable(functions, 'fetchIncomingFriendRequests');
    const result = await getIncomingFriendRequests({ user: user });

    const data = result.data as any[];
    const friendRequests = data.map((request: any) => {
      const requestFrom = new CometChat.User(request.requestFrom);
      return new FriendRequest(requestFrom, request.messageId);
    });

    return friendRequests;
  }
  catch (err) {
    console.error("Error fetching incoming friend requests:", err);
    throw err;
  }
}

export const fetchOutgoingFriendRequests = async (functions: Functions, user: CometChat.User): Promise<FriendRequest[]> => {
  try {
    const getOutgoingFriendRequests = httpsCallable(functions, 'fetchOutgoingFriendRequests');
    const result = await getOutgoingFriendRequests({ user: user });

    const data = result.data as any[];
    const friendRequests = data.map((request: any) => {
      const requestTo = new CometChat.User(request.requestTo);
      return new FriendRequest(requestTo, request.messageId);
    });

    return friendRequests;
  }
  catch (err) {
    console.error("Error fetching outgoing friend requests:", err);
    throw err;
  }
}

export const notifyMultipleUsersReload = async (functions: Functions, users: string[], reloadReason: string, group: CometChat.Group) => {
  try {
    const notifyMultipleUsersReload = httpsCallable(functions, 'notifyMultipleUsersReload');
    await notifyMultipleUsersReload({ users, reloadReason, group });
  }
  catch (err) {
    console.error("Error notifying multiple users to reload:", err);
    throw err;
  }
}

export const removeFriend = async (functions: Functions, friend: CometChat.User) => {
  try {
    const removeFriend = httpsCallable(functions, 'removeFriend');
    await removeFriend({ requestUid: friend.getUid() });
  }
  catch (err) {
    console.error("Error removing friend:", err);
    throw err;
  }
}

export const deleteCustomMessage = async (functions: Functions, messageId: string) => {
  try {
    const deleteCustomMessage = httpsCallable(functions, 'deleteCustomMessage');
    await deleteCustomMessage({ messageId: messageId });
  }
  catch (err) {
    console.error("Error deleting custom message:", err);
    throw err;
  }
}

export const deleteFriendRequestOrigin = async (functions: Functions, requestUid: string) => {
  try {
    const deleteFriendRequestOrigin = httpsCallable(functions, 'deleteFriendRequestOrigin');
    await deleteFriendRequestOrigin({ requestUid: requestUid });
  }
  catch (err) {
    console.error("Error deleting friend request origin:", err);
    throw err;
  }
}

export const deleteIncomingFriendRequest = async (functions: Functions, request: FriendRequest, reloadReason: string) => {
  try {
    const deleteIncomingFriendRequest = httpsCallable(functions, 'deleteIncomingFriendRequest');
    await deleteIncomingFriendRequest({ messageId: request.messageId, requestFrom: request.getUid(), reloadReason: reloadReason });
  }
  catch (err) {
    console.error("Error deleting incoming friend request:", err);
    throw err;
  }
}

export const acceptFriendRequest = async (functions: Functions, request: FriendRequest) => {
  try {

    const acceptFriendRequest = httpsCallable(functions, 'acceptFriendRequest');
    await acceptFriendRequest({ messageId: request.messageId, requestFrom: request.getUid() });
  }
  catch (err) {
    console.error("Error accepting friend request:", err);
    throw err;
  }
}