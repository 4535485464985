import Tabs from 'anchor-ui/tabs';
import Tab from 'anchor-ui/tab';
import ChannelListComponent from './ChannelListComponent';
import { IconChannels, IconConversation, IconPeople } from 'anchor-ui/icons';
import FriendsListComponent from './FriendsListComponent';
import ConversationsListComponent from './ConversationListComponent';
import Badge from 'anchor-ui/badge';
import { useEffect, useState, useRef } from 'react';
import { ActiveChannelInterface } from '../../interfaces/activeChannelInterface';
import { FriendRequest } from '../../utils/class/FriendRequest';
import { NotificationInterface } from '../../interfaces/notificationInterface';
import { Functions } from 'firebase/functions';
import { UserSettingsInterface } from '../../interfaces/userSettingsInterface';
import { ConversationInterface } from '../../interfaces/conversationInterface';

const TabsComponent = ({
  channels,
  activeChannels,
  onlineMembersCount,
  unreadCount,
  handleChangeChannel,
  currentChat,
  setCurrentChat,
  handleLeaveChannel,

  conversations,
  handleConversationClick,
  handleGroupConversationClick,
  refreshConversations,
  initialConversation,

  friends,
  handleRemoveFriend,
  incomingFriendRequests,
  outgoingFriendRequests,

  handleAcceptFriendRequest,
  handleDenyFriendRequest,
  handleCancelFriendRequest,

  activeTabIndex,
  setActiveTabIndex,

  setUnreadCount,
  setNotifications,
  loggedInUser,

  startPrivateChat,
  blockedUsers,
  handleBlockUser,

  setUserToReport,
  members,
  setAlert,

  friendToDelete,
  setFriendToDelete,
  showUserProfile,
  functions,

  userSettings,

  handleRemoveConversation,

  messageToForward,
  setMessageToForward,

  handleSendMessage,

  isSideNav,
  handleInitiateCall,

  setNeedSubscriptionWarning,
}: {
  channels: CometChat.Group[];
  activeChannels: ActiveChannelInterface[];
  onlineMembersCount: {[key: string]: number} | null;
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };

  handleChangeChannel: (channel: ActiveChannelInterface) => void;
  currentChat: ActiveChannelInterface;
  setCurrentChat: (channel: ActiveChannelInterface) => void;
  handleLeaveChannel: (channel: ActiveChannelInterface) => void;

  conversations: any;
  handleConversationClick: (conversation: CometChat.Conversation) => void;
  handleGroupConversationClick: (conversation: CometChat.Group) => void;
  refreshConversations: () => void;
  initialConversation: CometChat.Conversation | null;

  friends: CometChat.User[];
  handleRemoveFriend: (friend: CometChat.User) => void;
  incomingFriendRequests: FriendRequest[];
  outgoingFriendRequests: FriendRequest[];

  handleAcceptFriendRequest: (friend: FriendRequest) => void;
  handleDenyFriendRequest: (friend: FriendRequest) => void;
  handleCancelFriendRequest: (friend: FriendRequest) => void;

  activeTabIndex: number;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;

  setUnreadCount: React.Dispatch<React.SetStateAction<{ [id: string]: { nr: number, chat: ActiveChannelInterface } }>>;
  setNotifications: React.Dispatch<React.SetStateAction<NotificationInterface[] | null>>;
  loggedInUser: CometChat.User | null;

  startPrivateChat: (user: CometChat.User) => void;
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;

  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  members: CometChat.GroupMember[];
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;

  friendToDelete: CometChat.User | null;
  setFriendToDelete: React.Dispatch<React.SetStateAction<CometChat.User | null>>;
  showUserProfile: (user: CometChat.User) => void;
  functions: Functions;

  userSettings: UserSettingsInterface;

  handleRemoveConversation: (conversation: any) => void;

  messageToForward: CometChat.BaseMessage | null;
  setMessageToForward: React.Dispatch<React.SetStateAction<CometChat.BaseMessage | null>>;

  handleSendMessage: (event: any, receiver: string, isGroup: boolean, messageText?: string, isForwarded?: boolean) => void;

  isSideNav?: boolean;
  handleInitiateCall: (callWithUser: CometChat.User) => void;

  setNeedSubscriptionWarning: React.Dispatch<React.SetStateAction<React.ReactNode | null>>;
}) => {
  const [unreadChannelCount, setUnreadChannelCount] = useState(0);
  const [unreadConversationCount, setUnreadConversationCount] = useState(0);

  const generalFontSize = userSettings.generalFontSize;
  const activeChannelsRef = useRef(activeChannels);

  useEffect(() => {
    activeChannelsRef.current = activeChannels;
  }, [activeChannels]);

  const handleTabChange = (index: number) => {
    setActiveTabIndex(index);

    if (index !== 2) return; // 2 = friends tab

    // Remove all notifications where type='friendRequest'
    setNotifications(prevNotifications => {
      const newNotifications = (prevNotifications || []).filter(notification => notification.type !== 'friendRequest');
      return newNotifications;
    });
  };

  useEffect(() => {
    let unreadChannelCount = 0;
    let unreadConversationCount = 0;

    Object.entries(unreadCount).forEach(([key, unread]) => {
      if (activeChannelsRef.current.some(channel => channel.id === key)) {
        if (!unread.nr) return;
        unreadChannelCount += unread.nr;
      }

      if (conversations.some((conversation: ConversationInterface) => (conversation.conversation as any).conversationId === key || (conversation.conversation as any).guid === key)) {
        if (unread.nr) {
          unreadConversationCount += unread.nr;
        }
      }
    });

    setUnreadChannelCount(unreadChannelCount);
    setUnreadConversationCount(unreadConversationCount);
  }, [conversations, currentChat, unreadCount]);

  return (
    <Tabs
      selectedIndex={activeTabIndex}
      style={{ backgroundColor: 'white' }}
    >
      <Tab
        label={[
          <div
            onClick={() => handleTabChange(0)}
            key={"channelTab"}
            className="tab-container"
          >
            <div className="tab-icon-container">
              <IconChannels />
              {unreadChannelCount > 0 &&
                <Badge
                  value={unreadChannelCount}
                  maxValue={99}
                  style={{ marginTop: '-26px', marginLeft: '13px', position: 'absolute' }}
                />
              }
            </div>
            <span className="tab-label">
              <span className="tab-label-inner">
                kanalen
              </span>
            </span>
          </div>
        ]}
        className="tab"
      >
        <div className="channel-list">
          <ChannelListComponent
            members={members}
            channels={channels}
            activeChannels={activeChannels}
            onlineMembersCount={onlineMembersCount}
            unreadCount={unreadCount}
            handleChangeChannel={handleChangeChannel}
            currentChat={currentChat}
            handleLeaveChannel={handleLeaveChannel}
            generalFontSize={generalFontSize}
          />
        </div>
      </Tab>

      <Tab
        label={[
          <div
            onClick={() => handleTabChange(1)}
            key={"conversationTab"}
            className="tab-container"
          >
            <div className="tab-icon-container">
              <IconConversation />
              {unreadConversationCount > 0 &&
                <Badge
                  value={unreadConversationCount}
                  maxValue={99}
                  style={{ marginTop: '-26px', marginLeft: '13px', position: 'absolute' }}
                />
              }
            </div>
            <span className="tab-label">
              <span className="tab-label-inner">
                Gesprekken
              </span>
            </span>
          </div>
        ]}
      >
        <ConversationsListComponent
          conversations={conversations}
          unreadCount={unreadCount}
          handleConversationClick={handleConversationClick}
          handleGroupConversationClick={handleGroupConversationClick}
          initialConversation={initialConversation}
          currentChat={currentChat}
          setCurrentChat={setCurrentChat}
          friends={friends}
          refreshConversations={refreshConversations}
          setUnreadCount={setUnreadCount}
          loggedInUser={loggedInUser}
          blockedUsers={blockedUsers}
          setAlert={setAlert}
          isTimeline={false}
          functions={functions}
          userSettings={userSettings}

          handleRemoveConversation={handleRemoveConversation}

          messageToForward={messageToForward}
          setMessageToForward={setMessageToForward}

          handleSendMessage={handleSendMessage}

          isSideNav={isSideNav}
          handleInitiateCall={handleInitiateCall}

          setNeedSubscriptionWarning={setNeedSubscriptionWarning}
        />
      </Tab>

      <Tab
        label={[
          <div
            onClick={() => handleTabChange(2)}
            key={"friendsTab"}
            className="tab-container"
          >
            <div className="tab-icon-container">
              <IconPeople />
              {incomingFriendRequests.length > 0 &&
                <Badge
                  value={incomingFriendRequests.length}
                  maxValue={99}
                  style={{ marginTop: '-26px', marginLeft: '13px', position: 'absolute' }}
                />
              }
            </div>
            <span className="tab-label">
              <span className="tab-label-inner">
                Vrienden
              </span>
            </span>
          </div>
        ]}
      >
        <FriendsListComponent
          friends={friends}
          handleRemoveFriend={handleRemoveFriend}
          incomingFriendRequests={incomingFriendRequests}
          outgoingFriendRequests={outgoingFriendRequests}
          handleAcceptFriendRequest={handleAcceptFriendRequest}
          handleDenyFriendRequest={handleDenyFriendRequest}
          handleCancelFriendRequest={handleCancelFriendRequest}
          startPrivateChat={startPrivateChat}
          blockedUsers={blockedUsers}
          handleBlockUser={handleBlockUser}
          setUserToReport={setUserToReport}
          friendToDelete={friendToDelete}
          setFriendToDelete={setFriendToDelete}
          showUserProfile={showUserProfile}
          generalFontSize={generalFontSize}
        />
      </Tab>
    </Tabs>
  );
};

export default TabsComponent;