import { CometChat } from "@cometchat/chat-sdk-javascript";
import Button from 'anchor-ui/button';
import Input from 'anchor-ui/input';
import { SetStateAction, useEffect, useState } from 'react';
import RadioButton from 'anchor-ui/radio-button';
import { metadataInterface } from '../../interfaces/metadataInterface';
import { getProfileFromMetadata, updateUserMetadata } from '../../utils/updateMetadata';
import { useNavigate } from 'react-router-dom';
import Card from 'anchor-ui/card';
import CardHeader from 'anchor-ui/card-header';
import './styles.css';
import ChatPlazaLogo from '../../assets/chatplaza-logo.svg';
import Modal from 'anchor-ui/modal';
import { Functions, httpsCallable } from 'firebase/functions';

const MetadataPage = ({
  setAlert,
  loggedInUser,
  handleLogout,
  loadChannels,
  functions,
}: {
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  loggedInUser: CometChat.User;
  handleLogout: () => void;
  loadChannels: () => void;
  functions: Functions;
}) => {
  const [gender, setGender] = useState<string>('Man');
  const [birthDate, setBirthDate] = useState<string>('2000-01-01');
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const today = new Date();
  const maxDate = new Date(today.setFullYear(today.getFullYear() - 16)).toISOString().split('T')[0]; // 16 years old
  const minDate = '1900-01-01';

  useEffect(() => {
    if (!loggedInUser || typeof loggedInUser.getName !== 'function') {
      navigate('/login');
      return;
    }

    const metadata = getProfileFromMetadata(loggedInUser);
    if (metadata.about.birthDate !== 'unknown') {
      navigate("/chat");
    }
  }, [loggedInUser, navigate]);

  const handleSetMetadata = async () => {
    if (!loggedInUser) return;

    try {
      const metadata: metadataInterface = {
        about: {
          gender,
          birthDate
        },
      };

      const updatedMetadata = await updateUserMetadata(functions, metadata, loggedInUser);
      if (!updatedMetadata || typeof updatedMetadata === 'boolean') return;

      const putMetadata = httpsCallable(functions, 'putUserMetadata');
      await putMetadata({ metadata });

      setAlert({ message: 'Je profiel is aangemaakt!', type: 'success' });
      if (birthDate !== '2000-01-01') {
        loadChannels(); // Reload user-specific channels (e.g. 50+ if users age is 50+)
        navigate("/chat");
      }
    } catch (error) {
      console.error('Error setting metadata:', error);
      setAlert({ message: 'Er is iets misgegaan bij het aanmaken van je profiel. Probeer het later opnieuw.', type: 'error' });
    }
  };

  const calculateAge = (birthDate: string) => {
    const birth = new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <section className="action-page">
      <Modal
        open={modalOpen}
        header="Bevestig uw gegevens"
        actions={
          <>
            <Button flatButton onClick={() => setModalOpen(false)}>Nee dit klopt niet</Button>
            <Button flatButton onClick={handleSetMetadata}>Ja dit klopt</Button>
          </>
        }
        headerStyle={{ fontSize: '16px' }}
      >
        <p>U bent:</p>
        <ul>
          <li><strong>{gender}</strong></li>
          <li><strong>{calculateAge(birthDate)} jaar oud</strong></li>
        </ul>
      </Modal>

      <Card className="action-card">
        <img src={ChatPlazaLogo} className='logo' />
        <CardHeader title="Vul uw gegevens in" style={{ alignSelf: "center" }} />
        <CardHeader title="Gender" />
        <div className="action-radio-group">
          <RadioButton
            label="Man"
            value="Man"
            checked={gender === 'Man'}
            onChange={(e: { target: { value: SetStateAction<string> } }) => setGender(e.target.value)}
          />
          <RadioButton
            label="Vrouw"
            value="Vrouw"
            checked={gender === 'Vrouw'}
            onChange={(e: { target: { value: SetStateAction<string> } }) => setGender(e.target.value)}
          />
          <RadioButton
            label="Anders"
            value="Anders"
            checked={gender === 'Anders'}
            onChange={(e: { target: { value: SetStateAction<string> } }) => setGender(e.target.value)}
          />
        </div>
        <Input
          label="Geboortedatum"
          type="date"
          value={birthDate}
          name="birthDate"
          onChange={(e: { target: { value: SetStateAction<string> } }) => setBirthDate(e.target.value)}
          required
          min={minDate}
          max={maxDate}
          maxLength={50}
        />
        <div className="action-buttons">
          <Button inverted onClick={handleLogout}>Log Uit</Button>
          <Button onClick={() => setModalOpen(true)}>
            Bewaar
          </Button>
        </div>
      </Card>
    </section>
  );

};

export default MetadataPage;
