import { useEffect } from 'react';
import Layout from '../Layout';
import FlirtingBG from '../../assets/flirting.avif';
import './styles.css';
import { Helmet } from 'react-helmet';

const FlirtenPage = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = "https://daisycon.tools/dating/app.js";
    script.type = "text/javascript";
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Layout heroText='Flirten' heroImg={FlirtingBG}>
      <Helmet>
        <title>Flirten - ChatPlaza</title>
        <meta name="description" content="Flirten en nieuwe mensen ontmoeten? Op ChatPlaza.com vind je de perfecte plek voor gezellige chats, veilig online flirten en het maken van connecties. Probeer onze privéchats, webcamopties en speciale flirtruimtes voor een unieke ervaring!"/>
      </Helmet>
      <div className="dc-tool dc-dating-tool" data-config='{"mediaId":{"daisycon":250877,"xpartners":null},"subId":{"daisycon":"","xpartners":""},"showFilters":true,"filter":{"ageId":{"value":[3]},"categoryId":{"value":null},"intentionId":{"value":null},"optionId":{"value":null},"targetId":{"value":[1]}},"locale":"nl-NL","limit":50,"useAlternateLabel":false}'></div>
    </Layout>
  );
}

export default FlirtenPage;
