export const premiumChannelIds = [
  "vip",
  "royalsonly",
  "607080_jaren",
  "boswandeling",
  "serieus",
];

export const vipChannelIds = [
  "vip",
];

export const royalChannelIds = [
  "royalsonly",
];
