import { CometChat } from "@cometchat/chat-sdk-javascript";
import Modal from 'anchor-ui/modal';
import Button from 'anchor-ui/button';
import Select from 'anchor-ui/select';
import MenuItem from 'anchor-ui/menu-item';
import Input from 'anchor-ui/input';
import { useState } from 'react';

const ReportUserModal = ({
  userToReport,
  setUserToReport,
  setAlert,
}: {
  userToReport: { user: CometChat.User, guid?: string } | null;
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>
}) => {
  const initialReason = 'Spam Of Reclame';
  const [reportReason, setReportReason] = useState(initialReason);
  const [extraInfo, setExtraInfo] = useState('');

  const handleClose = () => {
    setReportReason(initialReason);
    setExtraInfo('');
    setUserToReport(null);
  };

  const handleReport = () => {
    if (!userToReport) return;

    const payload: any = {
      uid: userToReport.user.getUid(),
      reason: reportReason + (extraInfo ? ` - ${extraInfo}` : ''),
    };

    if (userToReport.guid) {
      payload.guid = userToReport.guid;
    }

    CometChat.callExtension('report-user', 'POST', 'v1/report', payload)
      .then(response => {

        setAlert({ message: 'Gebruiker is gerapporteerd', type: 'success' });
        handleClose();
      })
      .catch(error => {
        console.error('Error reporting user:', error);
      });
  };

  if (!userToReport) return <></>;

  return (
    <Modal
      open={userToReport !== null}
      actions={
        <>
          <Button flatButton onClick={handleClose}>ANNULEER</Button>
          <Button flatButton onClick={handleReport}>RAPPORTEER</Button>
        </>
      }
      headerStyle={{ fontSize: '16px' }}
    >
      <Select
        label={'Reden'}
        onChange={(event: object, value: string) => setReportReason(value)}
        value={reportReason}
        style={{ marginBottom: '12.5px' }}
      >
        <MenuItem text={'Lastig Vallen'} value={'Lastig Vallen'} />
        <MenuItem text={'Spam Of Reclame'} value={'Spam Of Reclame'} />
      </Select>

      <Input
        name={'extraInfo'}
        label={'Opmerking (optioneel)'}
        value={extraInfo}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setExtraInfo(event.target.value)}
        maxLength={130}
        style={{ maxWidth: '100%' }}
      />
    </Modal>
  );
};

export default ReportUserModal;