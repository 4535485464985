import Modal from "anchor-ui/modal";
import Button from "anchor-ui/button";
import Input from "anchor-ui/input";
import { SetStateAction, useState } from "react";
import { Functions, httpsCallable } from "firebase/functions";
import Select from "anchor-ui/select";
import MenuItem from "anchor-ui/menu-item";

const BroadcastModal = ({
  isOpen,
  setIsOpen,
  setAlert,
  functions,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  functions: Functions;
}) => {
  const [message, setMessage] = useState("");
  const [type, setType] = useState("info");
  const [duration, setDuration] = useState(30);

  const handleClose = () => {
    setMessage("");
    setType("info");
    setIsOpen(false);
  };

  const handleBroadcast = async () => {
    if (!message) return;

    const broadcastMessage = httpsCallable(functions, 'broadcastMessage');

    try {
      await broadcastMessage({ message, type, duration });
      setAlert({ message: 'Bericht is verstuurd', type: 'success' });

      setMessage("");
      setIsOpen(false);
    } catch {
      setAlert({ message: 'Er is iets misgegaan bij het versturen van het bericht', type: 'error' });
    }
  };

  return (
    <Modal
      open={isOpen}
      actions={
        <>
          <Button flatButton onClick={handleClose}>ANNULEER</Button>
          <Button flatButton onClick={handleBroadcast}>VERSTUUR</Button>
        </>
      }
      onClose={handleClose}
    >
      <h2>Broadcast bericht</h2>
      <Input
        label="Bericht"
        value={message}
        onChange={(e: { target: { value: SetStateAction<string>; }; }) => setMessage(e.target.value)}
        maxLength={500}
      />
      <br />
      <Select
        label="Type"
        value={type}
        onChange={(event: object, value: string) => setType(value)}
      >
        <MenuItem text={"Informatie"} value={"info"} />
        <MenuItem text={"Waarschuwing"} value={"warning"} />
      </Select>
      <br />
      <Input
        label="Duur (in seconden)"
        value={duration}
        type="number"
        onChange={(e: { target: { value: string; }; }) => setDuration(parseInt(e.target.value))}
      />
    </Modal>
  );
};

export default BroadcastModal;