import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';
import defaultAvatar from "./../../../assets/default_avatar.jpg";
import { getActiveColor } from './../../../utils/activeColor';
import Avatar from 'anchor-ui/avatar';
import { CometChat } from "@cometchat/chat-sdk-javascript";
import RemoveFriendModal from './RemoveFriendModal';
import { getGenderFromMetadata } from './../../../utils/getGenderFromMetadata';
import FriendButtons from './FriendButtons';
import { checkIfUserIsBlocked } from '../../../utils/checkIfUserIsBlocked';
import { FriendRequest } from '../../../utils/class/FriendRequest';
import { getFontSizeStyle } from '../../../utils/getFontSizeStyle';

const FriendsListComponent = ({
  friends,
  handleRemoveFriend,
  incomingFriendRequests,
  outgoingFriendRequests,
  handleAcceptFriendRequest,
  handleDenyFriendRequest,
  handleCancelFriendRequest,
  startPrivateChat,
  blockedUsers,
  handleBlockUser,
  setUserToReport,
  friendToDelete,
  setFriendToDelete,
  showUserProfile,
  generalFontSize,
}: {
  friends: CometChat.User[];
  handleRemoveFriend: (friend: CometChat.User) => void;
  incomingFriendRequests: FriendRequest[];
  outgoingFriendRequests: FriendRequest[];
  handleAcceptFriendRequest: (friend: FriendRequest) => void;
  handleDenyFriendRequest: (friend: FriendRequest) => void;
  handleCancelFriendRequest: (friend: FriendRequest) => void;
  startPrivateChat: (user: CometChat.User) => void;
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  friendToDelete: CometChat.User | null;
  setFriendToDelete: (friend: CometChat.User | null) => void;
  showUserProfile: (user: CometChat.User) => void;
  generalFontSize: string;
}) => {

  const getOnlineFriendCount = (friends: CometChat.User[]) => {
    return friends.filter(friend => friend.getStatus() === 'online').length;
  }

  const checkBlocked = (friend: CometChat.User) => {
    return checkIfUserIsBlocked(blockedUsers, friend.getUid());
  }

  return (
    <>
      <RemoveFriendModal
        friendToDelete={friendToDelete}
        setFriendToDelete={setFriendToDelete}
        handleRemoveFriend={handleRemoveFriend}
      />

      <div className="channel-list">
        {incomingFriendRequests.length > 0 &&
          <List
            header="Ontvangen Vriendschapsverzoeken"
            headerStyle={getFontSizeStyle(generalFontSize)}
          >
            {incomingFriendRequests.map((friendReq, index) => (
              <ListItem
                key={index}
                primaryText={friendReq.getName()}
                primaryTextStyle={getFontSizeStyle(generalFontSize)}
                secondaryText={getGenderFromMetadata(friendReq)}
                avatar={
                  <Avatar
                    image={friendReq.getAvatar() ?? defaultAvatar}
                    style={{ backgroundColor: getActiveColor() }}
                  />
                }
                blocked={checkBlocked(friendReq)}
                rightButton={
                  <FriendButtons
                    friend={friendReq}
                    showUserProfile={showUserProfile}
                    startPrivateChat={startPrivateChat}
                    setUserToReport={setUserToReport}
                    setFriendToDelete={setFriendToDelete}
                    blockedUsers={blockedUsers}
                    handleBlockUser={handleBlockUser}
                    handleAcceptFriendRequest={handleAcceptFriendRequest}
                    handleDenyFriendRequest={handleDenyFriendRequest}
                    isIncomingRequest={true}
                  />
                }
              />
            ))}
          </List>
        }

        {outgoingFriendRequests.length > 0 &&
          <List
            header="Verstuurde Vriendschapsverzoeken"
            headerStyle={getFontSizeStyle(generalFontSize)}
          >
            {outgoingFriendRequests.map((friendReq, index) => (
              <ListItem
                key={index}
                primaryText={friendReq.getName()}
                primaryTextStyle={getFontSizeStyle(generalFontSize)}
                secondaryText={getGenderFromMetadata(friendReq)}
                avatar={
                  <Avatar
                    image={friendReq.getAvatar() ?? defaultAvatar}
                    style={{ backgroundColor: getActiveColor() }}
                  />
                }
                blocked={checkBlocked(friendReq)}
                rightButton={
                  <FriendButtons
                    friend={friendReq}
                    showUserProfile={showUserProfile}
                    startPrivateChat={startPrivateChat}
                    setUserToReport={setUserToReport}
                    setFriendToDelete={setFriendToDelete}
                    blockedUsers={blockedUsers}
                    handleBlockUser={handleBlockUser}
                    handleCancelFriendRequest={handleCancelFriendRequest}
                    isOutgoingRequest={true}
                  />
                }
              />
            ))}
          </List>
        }

        <List
          header={`Vrienden (${getOnlineFriendCount(friends)}/${friends.length})`}
          headerStyle={getFontSizeStyle(generalFontSize)}
        >
          {friends.map((friend, index) => (
            <ListItem
              key={index}
              primaryText={friend.getName()}
              secondaryText={getGenderFromMetadata(friend)}
              primaryTextStyle={getFontSizeStyle(generalFontSize)}
              avatar={
                <Avatar
                  image={friend.getAvatar() ?? defaultAvatar}
                  style={{ backgroundColor: getActiveColor() }}
                  status={checkBlocked(friend) ? null : friend.getStatus()}
                />
              }
              blocked={checkBlocked(friend)}
              rightButton={
                <FriendButtons
                  friend={friend}
                  showUserProfile={showUserProfile}
                  startPrivateChat={startPrivateChat}
                  setUserToReport={setUserToReport}
                  setFriendToDelete={setFriendToDelete}
                  blockedUsers={blockedUsers}
                  handleBlockUser={handleBlockUser}
                />
              }
            />
          ))}
        </List>
      </div>
    </>
  );
};

export default FriendsListComponent;
