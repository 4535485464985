import { IconCheckbox } from "anchor-ui/icons";

const CheckConversationButton = ({
    conversation,
    handleClick,
    conversationsToDelete,
    conversationsToForwardTo
}: {
    conversation: any;
    handleClick: () => void;
    conversationsToDelete: any;
    conversationsToForwardTo: any;
}) => {
    return (
        <div data-radium="true" style={{ position: "relative" }}>
            <div>
                <button
                    type="button"
                    data-radium="true"
                    className="li-button"
                    onClick={handleClick}
                >
                    {conversationsToDelete.includes(conversation) ||
                        conversationsToForwardTo.includes(conversation) ? <IconCheckbox color={"white"} /> : <IconCheckbox />}
                </button>
            </div>
        </div>
    );
};

export default CheckConversationButton;