import ChannelHeader from 'anchor-ui/channel-header';
import { CometChat } from "@cometchat/chat-sdk-javascript";
import MenuItem from 'anchor-ui/menu-item';
import IconMenu from 'anchor-ui/icon-menu';
import {
  IconClose,
  IconMore,
  IconPerson,
  IconReport,
  IconBlock,
  IconAddFriend,
  IconPeople,
} from 'anchor-ui/icons';
import React, { useEffect, useState } from 'react';
import { ActiveChannelInterface } from './../../interfaces/activeChannelInterface';
import Button from 'anchor-ui/button';
import ToggleLeftMenuButton from '../ToggleLeftMenuButton';
import { checkIfUserIsBlocked } from '../../utils/checkIfUserIsBlocked';
import { getProfileFromMetadata } from '../../utils/updateMetadata';
import { metadataInterface } from '../../interfaces/metadataInterface';

import LexaLogoWideSvg from '../../assets/lexa-logo-wide.svg';
import PikantCamsLogoWideSvg from '../../assets/pikantcams-logo-wide.svg';
import { getFontSizeStyle } from '../../utils/getFontSizeStyle';
import { UserSettingsInterface } from '../../interfaces/userSettingsInterface';
import { checkIfUserCanBeCalled } from '../../utils/CallFunctions';

const ChannelHeaderComponent = ({
  blockedUsers,
  handleBlockUser,
  currentChat,
  setCurrentChat,
  friends,
  hasSentFriendRequest,
  hasReceivedFriendRequest,
  handleAddOrRemoveFriend,
  setUserToReport,
  leftMenuOpen,
  setLeftMenuOpen,
  rightMenuOpen,
  setRightMenuOpen,
  unreadCount,
  userSettings,
  handleRemoveConversation,
  loggedInUserIsAdmin,

  outgoingCall,
  ongoingCall,

  handleInitiateCall,
  handleTerminateCall,
}: {
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;
  currentChat: ActiveChannelInterface;
  setCurrentChat: (chat: any) => void;
  friends: CometChat.User[];
  hasSentFriendRequest: (user: CometChat.User) => boolean;
  hasReceivedFriendRequest: (user: CometChat.User) => boolean;
  handleAddOrRemoveFriend: (friend: CometChat.User) => void;
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  leftMenuOpen: boolean;
  setLeftMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  rightMenuOpen: boolean;
  setRightMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  userSettings: UserSettingsInterface;
  handleRemoveConversation: (conversation: CometChat.Conversation | CometChat.Group) => void;
  loggedInUserIsAdmin: boolean;

  outgoingCall: { call: CometChat.Call, receiver: CometChat.User } | null;
  ongoingCall: CometChat.Call | null;

  handleInitiateCall: (receiver: CometChat.User) => void;
  handleTerminateCall: () => void;
}) => {

  const [conversationWith, setConversationWith] = useState<CometChat.User | null>(null);
  const [userProfile, setUserProfile] = useState<metadataInterface | null>(null);

  const [currentRotation, setCurrentRotation] = useState(0);
  const [fadeClass, setFadeClass] = useState(''); // Tracks animation classes
  const [canMakeCall, setCanMakeCall] = useState(false);

  // const rotations = [
  //   { content: <p>{currentChat.name}</p>, href: '' },
  //   { content: <a href="https://ds1.nl/c/?si=51&li=1646487&wi=250877&ws=" target="_blank"><img src={LexaLogoWideSvg} /></a> },
  //   { content: <a href="https://ds1.nl/c/?si=51&li=1646487&wi=250877&ws=" target="_blank"><p>Singles in jouw regio</p></a> },
  //   { content: <a href="https://www.pikantcams.nl/nl?pi=chatplaza_v2" target="_blank"><img src={PikantCamsLogoWideSvg} /></a> },
  //   { content: <a href="https://www.pikantcams.nl/nl?pi=chatplaza_v2" target="_blank"><p>Start een pikante camchat!</p></a> },
  // ];

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setFadeClass('fade-out'); // Start fade-out animation
  //     setTimeout(() => {
  //       setCurrentRotation((prev) => (prev + 1) % rotations.length); // Move to next rotation
  //       setFadeClass('fade-in'); // Start fade-in animation
  //     }, 500); // Match fade-out animation duration
  //   }, 10000); // Total time per rotation

  //   return () => clearInterval(interval);
  // }, []);

  const showUserProfile = (user: CometChat.User) => {
    setCurrentChat((prev: any) => ({ ...prev, userProfile: user }));
  }

  // const handleViewPinnedMessages = () => {
  //   setCurrentChat((prev: any) => ({ ...prev, showPinnedMessages: true }));
  // }

  useEffect(() => {
    if (!currentChat.isGroup) {

      // Get the cometchatuser based on currentChat.receiverId
      CometChat.getUser(currentChat.receiverId).then(
        user => {
          const metadata = getProfileFromMetadata(user as CometChat.User);
          setUserProfile(metadata);

          setCanMakeCall(checkIfUserCanBeCalled(user, friends));

          // Prevent infinite loop by checking if the user is already set
          if (conversationWith && conversationWith.getUid() === currentChat.receiverId) return;
          setConversationWith(user as CometChat.User);
        }, error => {
          console.error("User details fetching failed with error:", error);
        }
      );
    }
  }, [currentChat, friends, conversationWith]);

  // const handleInitiateCall = async () => {
  //   if (!conversationWith) return;

  //   try {
  //     // End any active call
  //     const activeCall = CometChat.getActiveCall();
  //     if (activeCall) {
  //       await CometChat.rejectCall(activeCall.getSessionId(), CometChat.CALL_STATUS.CANCELLED);
  //     }

  //     const call: CometChat.Call = new CometChat.Call(
  //       conversationWith.getUid(),
  //       CometChat.CALL_TYPE.AUDIO,
  //       CometChat.RECEIVER_TYPE.USER
  //     );

  //     const outGoingCall = await CometChat.initiateCall(call);
  //     setOutgoingCall({ call: outGoingCall, receiver: conversationWith });

  //     // Generate call token for this session
  //     const callToken = await generateCallToken(outGoingCall.getSessionId(), loggedInUser!);
  //     if (callToken) {
  //       startCallSession(callToken, setOngoingCall);
  //     }
  //   } catch (e) {
  //     console.error("Error while initiating call:", e);
  //   }
  // };

  return (
    <ChannelHeader
      // name={currentChat.name + (currentChat.showPinnedMessages ? " - Vastgezette berichten" : '')}
      // name={currentChat.name}
      name={
        // <div className={`header-title rotation-content ${fadeClass}`}>
        //   {rotations[currentRotation].content}
        // </div>
        <div className={`header-title`}>
          {currentChat.name}
        </div>
      }
      textStyle={getFontSizeStyle(userSettings.generalFontSize)}
      leftButton={
        <ToggleLeftMenuButton
          leftMenuOpen={leftMenuOpen}
          setLeftMenuOpen={setLeftMenuOpen}
          unreadCount={unreadCount}
        />
      }
      rightButton={
        currentChat.isGroup ?
          <>
            {currentChat.isOpen ?
              <Button
                iconButton
                onClick={() => setRightMenuOpen(!rightMenuOpen)}
                className="button-toggle-menu button-toggle-right"
              >
                <IconPeople />
              </Button> :
              <IconMenu
                icon={<IconMore />}
                className="button-toggle-menu button-toggle-right"
              >
                <MenuItem
                  text="Gebruikers"
                  icon={<IconPeople />}
                  onClick={() => setRightMenuOpen(!rightMenuOpen)}
                  className="button-toggle-menu button-toggle-right"
                />
                {/* {
              currentChat.showPinnedMessages ?
                <MenuItem
                  text="Terug naar kanaal"
                  icon={<IconConversation />}
                  onClick={() => setCurrentChat((prev: any) => ({ ...prev, showPinnedMessages: false }))}
                /> :
                <MenuItem
                  text="Vastgezette berichten"
                  icon={<IconChannel />}
                  onClick={handleViewPinnedMessages}
                />
            } */}
              </IconMenu>
            }
          </> :
          <IconMenu icon={<IconMore />}>
            <MenuItem
              text="Sluit Privé Kanaal"
              icon={<IconClose />}
              onClick={() => handleRemoveConversation(currentChat.conversation!)}
            />

            {/* {
              currentChat.showPinnedMessages ?
                <MenuItem
                  text="Terug naar gesprek"
                  icon={<IconConversation />}
                  onClick={() => setCurrentChat((prev: any) => ({ ...prev, showPinnedMessages: false }))}
                /> :
                <MenuItem
                  text="Vastgezette berichten"
                  icon={<IconChannel />}
                  onClick={handleViewPinnedMessages}
                />
            } */}

            <MenuItem
              text={checkIfUserIsBlocked(blockedUsers, currentChat.receiverId as string) ? "Deblokkeer gebruiker" : "Blokkeer gebruiker"}
              icon={<IconBlock />}
              onClick={() => handleBlockUser(currentChat.receiverId as string)}
            />

            {conversationWith &&
              <>
                <MenuItem
                  text="Rapporteer gebruiker"
                  icon={<IconReport />}
                  onClick={() => setUserToReport({ user: conversationWith })}
                />

                {(userProfile && !userProfile.settings?.profileIsPrivate || userProfile && loggedInUserIsAdmin) && (
                  <MenuItem
                    text="Bekijk profiel"
                    icon={<IconPerson />}
                    onClick={() => showUserProfile(conversationWith)}
                  />
                )}

                {!hasSentFriendRequest(conversationWith) &&
                  <MenuItem
                    text={
                      friends.some(friend => friend.getUid() === currentChat.receiverId) ?
                        "Verwijder Vriend" :
                        hasReceivedFriendRequest(conversationWith) ?
                          "Accepteer vriendschapsverzoek" :
                          "Stuur een vriendschapsverzoek"
                    }
                    icon={<IconAddFriend />}
                    onClick={() => handleAddOrRemoveFriend(conversationWith)}
                  />
                }

                {
                  canMakeCall &&
                  <>
                    {ongoingCall || outgoingCall ?
                      <MenuItem
                        text="Stop audiogesprek"
                        icon={<IconPerson />}
                        onClick={handleTerminateCall}
                      /> :
                      <MenuItem
                        text={"Start audio of videogesprek"}
                        icon={<IconReport />}
                        onClick={() => handleInitiateCall(conversationWith)}
                      />
                    }
                  </>
                }
              </>
            }
          </IconMenu>
      }
    />
  )
};

export default ChannelHeaderComponent;