import React from 'react';
import { useParams } from 'react-router-dom';
import Layout from "../../Layout";
import blogImg from '../../../assets/blog.avif';
import './../styles.css';

// Assuming the blog data is in the same folder
import blogData from './articles.json';
import { blogDataInterface } from '../../../interfaces/blogDataInterface';

const BlogLayout = () => {
  const { url } = useParams<{ url: string }>();

  const blog = blogData.find(blog => blog.url === url) as blogDataInterface;

  if (!blog) {
    return (
      <Layout heroText="Blog Not Found" heroImg={blogImg}>
        <div className="page-container">
          <div className='container-content'>
            <h1>Blog Not Found</h1>
            <p>The blog you are looking for does not exist.</p>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout heroText={blog.title} heroImg={blog.img} isBlog={true}>
      <div className="page-container">
        <div className='container-content'>
          <article className="blog-article">
            <header>
              <h1 className="text-blue">{blog.title}</h1>
            </header>

            <main>
              <article>
                <p><strong>{blog.content.intro}</strong></p>
                {blog.content.sections.map((section, index) => {
                  // Replace all occurrences of ** with <b> and </b>
                  let isBold = true;
                  const formattedContent = section.content.replace(/\*\*/g, () => {
                    const tag = isBold ? '<b>' : '</b>';
                    isBold = !isBold;
                    return tag;
                  });

                  return (
                    <div key={index} className='blog-section'>
                      <h3>{section.title}</h3>
                      <p dangerouslySetInnerHTML={{ __html: formattedContent }}></p>
                    </div>
                  );
                })}
              </article>

              <footer>
                <p><strong>Gepubliceerd op:</strong> {blog.publicationDate}</p>
              </footer>
            </main>
          </article>
        </div>
      </div>
    </Layout>
  );
}

export default BlogLayout;