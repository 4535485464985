import { IconSuccess } from 'anchor-ui/icons';

const ThemePage = ({
  activeColorState,
  handleSetActiveColor
}: {
  activeColorState: string,
  handleSetActiveColor: (color: string) => void,
}) => {

  return (
    <>
      <h2>Kies een thema</h2>

      <div className="theme-selection">
        <button
          className={`blue-button ${activeColorState === '#177FBF' ? 'active' : ''}`}
          onClick={() => handleSetActiveColor('#177FBF')}
        >
          ChatPlaza
          {activeColorState === '#177FBF' && <IconSuccess color={'white'} style={{ width: '100%' }} />}
        </button>
        <button
          className={`orange-button ${activeColorState === '#f2922c' ? 'active' : ''}`}
          onClick={() => handleSetActiveColor('#f2922c')}
        >
          Oranje
          {activeColorState === '#f2922c' && <IconSuccess color={'white'} style={{ width: '100%' }} />}
        </button>
        <button
          className={`yellow-button ${activeColorState === '#fbc02d' ? 'active' : ''}`}
          onClick={() => handleSetActiveColor('#fbc02d')}
        >
          Geel
          {activeColorState === '#fbc02d' && <IconSuccess color={'white'} style={{ width: '100%' }} />}
        </button>
        <button
          className={`green-button ${activeColorState === '#22ac55' ? 'active' : ''}`}
          onClick={() => handleSetActiveColor('#22ac55')}
        >
          Groen
          {activeColorState === '#22ac55' && <IconSuccess color={'white'} style={{ width: '100%' }} />}
        </button>
        <button
          className={`purple-button ${activeColorState === '#5d3c85' ? 'active' : ''}`}
          onClick={() => handleSetActiveColor('#5d3c85')}
        >
          Paars
          {activeColorState === '#5d3c85' && <IconSuccess color={'white'} style={{ width: '100%' }} />}
        </button>
        <button
          className={`red-button ${activeColorState === '#d32f2f' ? 'active' : ''}`}
          onClick={() => handleSetActiveColor('#d32f2f')}
        >
          Rood
          {activeColorState === '#d32f2f' && <IconSuccess color={'white'} style={{ width: '100%' }} />}
        </button>
        <button
          className={`pink-button ${activeColorState === '#e91e63' ? 'active' : ''}`}
          onClick={() => handleSetActiveColor('#e91e63')}
        >
          Roze
          {activeColorState === '#e91e63' && <IconSuccess color={'white'} style={{ width: '100%' }} />}
        </button>
        <button
          className={`black-button ${activeColorState === '#212121' ? 'active' : ''}`}
          onClick={() => handleSetActiveColor('#212121')}
        >
          Zwart (donkere modus binnenkort beschikbaar)
          {activeColorState === '#212121' && <IconSuccess color={'white'} style={{ width: '100%' }} />}
        </button>
      </div>
    </>
  )
}

export default ThemePage;