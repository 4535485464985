import { useEffect, useState } from 'react';
import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';
import { IconClose } from 'anchor-ui/icons';
import { getActiveColor } from '../../utils/activeColor';
import Avatar from 'anchor-ui/avatar';
import { ActiveChannelInterface } from '../../interfaces/activeChannelInterface';
import { getFontSizeStyle } from '../../utils/getFontSizeStyle';
import { getAvatar } from '../../utils/getAvatar';

const ChannelListComponent = ({
  members,
  channels,
  activeChannels,
  onlineMembersCount,
  unreadCount,
  handleChangeChannel,
  currentChat,
  handleLeaveChannel,
  generalFontSize,
}: {
  members: CometChat.GroupMember[];
  channels: CometChat.Group[];
  activeChannels: ActiveChannelInterface[];
  onlineMembersCount: { [key: string]: number } | null;
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  handleChangeChannel: (channel: ActiveChannelInterface) => void;
  currentChat: ActiveChannelInterface;
  handleLeaveChannel: (channel: ActiveChannelInterface) => void;
  generalFontSize: string;
}) => {
  // Premium channel ids
  const premiumChannelIds = ["607080_jaren", "boswandeling", "serieus", "royalsonly", "vip"];

  const activeChannelsWithoutCurrent = activeChannels.filter(channel => channel.id !== currentChat.id);
  const onlineMembers = members.filter(member => member.getStatus() === 'online');

  const [currentChatIsPublic, setCurrentChatIsPublic] = useState(false);

  const LeaveChannelButton = ({ channel }: { channel: ActiveChannelInterface }) => {
    return (
      <button
        type="button"
        className='li-button text-white'
        onClick={(event) => {
          event.stopPropagation();
          handleLeaveChannel(channel);
        }}
      >
        <IconClose color={'white'} />
      </button>
    );
  };

  const getOnlineMembersCount = (channel: { guid: string, onlineMembersCount?: number }) => {
    const count = onlineMembersCount?.[channel.guid] ?? channel.onlineMembersCount ?? 0;
    return `${count} online`;
  };

  useEffect(() => {
    const isActiveAndPublic = activeChannels.some(channel => channel.id === currentChat.id);
    setCurrentChatIsPublic(isActiveAndPublic);
  }, [currentChat, activeChannels]);

  // Filter channels to only include premium channels
  const allPremiumChannels = channels.filter(channel => {
    const channelId = channel.getGuid();
    return premiumChannelIds.includes(channelId) && channelId !== currentChat.id;
  });

  // Filter out premium channels from the main channels list
  const filteredChannels = channels.filter(channel => {
    const channelId = channel.getGuid();
    return !premiumChannelIds.includes(channelId) && channelId !== currentChat.id;
  });

  return (
    <div>
      <div className="active-channels">
        {currentChatIsPublic &&
          <List
            header="Huidig Kanaal"
            headerStyle={getFontSizeStyle(generalFontSize)}
          >
            <ListItem
              primaryText={currentChat.name}
              primaryTextStyle={getFontSizeStyle(generalFontSize)}
              secondaryText={
                onlineMembers.length ? onlineMembers.length + " online" :
                  getOnlineMembersCount({ guid: currentChat.id, onlineMembersCount: currentChat.onlineMembersCount })}
              avatar={
                <Avatar
                  image={getAvatar(currentChat.name, true)}
                  style={{ backgroundColor: getActiveColor() }}
                />
              }
              rightButton={<LeaveChannelButton channel={currentChat} />}
              style={{ backgroundColor: getActiveColor() }}
              activeChannel={true}
            />
          </List>
        }

        {activeChannelsWithoutCurrent.length > 0 &&
          <List
            header="Actieve Premium Kanalen" // Updated to Premium
            headerStyle={getFontSizeStyle(generalFontSize)}
          >
            {activeChannelsWithoutCurrent.map((channel, index) => {
              const channelUnreadCount = unreadCount[channel.id] ? unreadCount[channel.id].nr : 0;

              return (
                <ListItem
                  key={index}
                  secondaryText={getOnlineMembersCount({ guid: channel.id, onlineMembersCount: (channel as any).onlineMembersCount })}
                  primaryText={channel.name}
                  primaryTextStyle={getFontSizeStyle(generalFontSize)}
                  badge={channelUnreadCount > 0 && <div className='badge-unread'>{channelUnreadCount}</div>}
                  avatar={
                    <Avatar
                      image={getAvatar(channel.name, true)}
                      style={{ backgroundColor: getActiveColor() }}
                    />
                  }
                  onClick={() => handleChangeChannel(channel)}
                  className={`channel-item active`}
                  rightButton={<LeaveChannelButton channel={channel} />}
                  style={{ backgroundColor: getActiveColor() }}
                  activeChannel={true}
                />
              );
            })}
          </List>
        }
      </div>

      {/* All non-active channels are now displayed as premium */}
      <List
        header="Premium Kanalen"
        headerStyle={getFontSizeStyle(generalFontSize)}
      >
        {allPremiumChannels.map((channel, index) => (
          <div key={index}>
            <ListItem
              primaryText={channel.getName()}
              primaryTextStyle={getFontSizeStyle(generalFontSize)}
              secondaryText={getOnlineMembersCount({ guid: channel.getGuid(), onlineMembersCount: (channel as any).onlineMembersCount })}
              avatar={
                <Avatar
                  image={getAvatar(channel.getName())}
                  style={{ backgroundColor: getActiveColor() }}
                />
              }
              onClick={() => handleChangeChannel({
                id: channel.getGuid(),
                name: channel.getName(),
                icon: channel.getIcon(),
                onlineMembersCount: (channel as any).onlineMembersCount ?? 404,
                joinedAt: 0,
                isGroup: true
              })}
            />
          </div>
        ))}
      </List>

      {/* Free public channels  */}
      <List
        header="Gratis Kanalen"
        headerStyle={getFontSizeStyle(generalFontSize)}
      >
        {filteredChannels.map((channel, index) => {
          return (
            <>
              <ListItem
                key={index}
                primaryText={channel.getName()}
                primaryTextStyle={getFontSizeStyle(generalFontSize)}
                secondaryText={getOnlineMembersCount({ guid: channel.getGuid(), onlineMembersCount: (channel as any).onlineMembersCount })}
                avatar={
                  <Avatar
                    image={getAvatar(channel.getName())}
                    style={{ backgroundColor: getActiveColor() }}
                  />
                }
                onClick={() => handleChangeChannel({
                  id: channel.getGuid(),
                  name: channel.getName(),
                  icon: channel.getIcon(),
                  onlineMembersCount: (channel as any).onlineMembersCount ?? 404,
                  joinedAt: 0,
                  isGroup: true
                })}
              />
            </>

          );
        })}
      </List>
    </div>
  );
};

export default ChannelListComponent;