import { useEffect, useRef } from 'react';
import { Call, CometChat } from "@cometchat/chat-sdk-javascript";
import './styles.css';
import { ActiveChannelInterface } from './../../interfaces/activeChannelInterface';
import GroupListComponent from './GroupListComponent';
import UserProfileConvComponent from './UserProfileConvComponent';
import { FriendRequest } from './../../utils/class/FriendRequest';
import { Functions } from 'firebase/functions';
import { UserSettingsInterface } from '../../interfaces/userSettingsInterface';

interface GroupMembersListProps {
  currentChat: ActiveChannelInterface;
  loggedInUser: CometChat.User | null;
  friends: CometChat.User[];
  handleRemoveFriend: (friend: CometChat.User) => void;
  handleChangeConversation: (conversation: CometChat.Conversation) => void;
  incomingFriendRequests: FriendRequest[];
  handleAcceptFriendRequest: (request: FriendRequest) => void;
  outgoingFriendRequests: CometChat.User[];
  loadOutgoingFriendRequests: () => void;
  initiateNewConversation: ({ userId, user }: { userId?: string; user?: CometChat.User }) => Promise<void>;
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;
  handleFriendRequest: (user: CometChat.User) => void;
  hasSentFriendRequest: (user: CometChat.User) => boolean;
  hasReceivedFriendRequest: (user: CometChat.User) => boolean;
  handleAddOrRemoveFriend: (friend: CometChat.User) => void;

  handleMuteUser: (user: CometChat.User) => void;
  handleKickUser: (user: CometChat.User, group: CometChat.Group) => void;
  handleLogoutKickUser: (user: CometChat.User) => void;
  handleBanUser: (user: CometChat.User) => void;
  handleMakeUserAdmin: (user: CometChat.User) => void;

  setCurrentChat: (chat: ActiveChannelInterface) => void;
  startPrivateChat: (user: CometChat.User) => Promise<void>;

  members: CometChat.GroupMember[];
  setMembers: (members: CometChat.GroupMember[]) => void;
  chatWithUser: CometChat.User | undefined;
  setChatWithUser: (user: CometChat.User | undefined) => void;
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  setUserToWarn: (user: CometChat.User) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  isSideNav: boolean | undefined;
  functions: Functions;

  fetchGroupMembers: (currentChat: ActiveChannelInterface) => void;
  userSettings: UserSettingsInterface;
  refreshConversations: () => void;

  ongoingCall: Call | null;
  setOngoingCall: React.Dispatch<React.SetStateAction<Call | null>>;
  outgoingCall: {call: Call, receiver: CometChat.User} | null;
  setOutgoingCall: React.Dispatch<React.SetStateAction<{call: Call, receiver: CometChat.User} | null>>;

  handleInitiateCall: (user: CometChat.User) => void;
  handleTerminateCall: () => void;
}

const GroupMembersList = ({
  currentChat,
  loggedInUser,
  friends,
  incomingFriendRequests,
  handleAcceptFriendRequest,
  outgoingFriendRequests,
  blockedUsers,
  handleBlockUser,
  handleFriendRequest,
  hasSentFriendRequest,
  hasReceivedFriendRequest,
  handleAddOrRemoveFriend,

  handleMuteUser,
  handleKickUser,
  handleLogoutKickUser,
  handleBanUser,

  setCurrentChat,
  startPrivateChat,

  members,
  setMembers,
  chatWithUser,
  setChatWithUser,
  setUserToReport,
  setUserToWarn,
  setAlert,

  isSideNav,
  functions,

  fetchGroupMembers,
  userSettings,
  refreshConversations,

  ongoingCall,
  setOngoingCall,
  outgoingCall,
  setOutgoingCall,

  handleInitiateCall,
  handleTerminateCall,
}: GroupMembersListProps) => {

  const fetchGroupMembersRef = useRef(fetchGroupMembers);
  const setChatWithUserRef = useRef(setChatWithUser);

  useEffect(() => {
    fetchGroupMembersRef.current = fetchGroupMembers;
    setChatWithUserRef.current = setChatWithUser;
  }, [fetchGroupMembers, setChatWithUser]);

  useEffect(() => {
    if (currentChat.id && !currentChat.isGroup) {
      // Fetch user details for private chat
      const chatWithUserId = currentChat.receiverId!;
      const limit = 1;
      const userRequest = new CometChat.UsersRequestBuilder()
        .setLimit(limit)
        .setUIDs([chatWithUserId])
        .withTags(true)
        .build();

      userRequest.fetchNext().then(
        userList => {
          setChatWithUserRef.current(userList[0]);
        },
        error => {
          console.error('User fetching failed with error:', error);
        }
      );
    } else if (currentChat.id && currentChat.isGroup) {
      fetchGroupMembersRef.current(currentChat);
    }
  }, [currentChat]);

  return (
    <div className={`group-members-list ${!isSideNav ? 'widescreen-sidebar' : 'narrowscreen-sidebar'}`}>
      {
        (currentChat.id && !currentChat.isGroup) ? (
          <UserProfileConvComponent
            user={chatWithUser}
            friends={friends}
            handleFriendRequest={handleFriendRequest}
            incomingFriendRequests={incomingFriendRequests}
            handleAcceptFriendRequest={handleAcceptFriendRequest}
            outgoingFriendRequests={outgoingFriendRequests}
            functions={functions}

            ongoingCall={ongoingCall}
            outgoingCall={outgoingCall}

            handleInitiateCall={handleInitiateCall}
            handleTerminateCall={handleTerminateCall}
          />
        ) : (
          <GroupListComponent
            members={members}
            setMembers={setMembers}
            loggedInUser={loggedInUser}
            friends={friends}
            blockedUsers={blockedUsers}
            handleBlockUser={handleBlockUser}
            hasSentFriendRequest={hasSentFriendRequest}
            hasReceivedFriendRequest={hasReceivedFriendRequest}
            handleAddOrRemoveFriend={handleAddOrRemoveFriend}

            handleMuteUser={handleMuteUser}
            handleKickUser={handleKickUser}
            handleLogoutKickUser={handleLogoutKickUser}
            handleBanUser={handleBanUser}
            currentChat={currentChat}
            setCurrentChat={setCurrentChat}
            startPrivateChat={startPrivateChat}
            setUserToReport={setUserToReport}
            setUserToWarn={setUserToWarn}
            setAlert={setAlert}
            functions={functions}
            userSettings={userSettings}
            refreshConversations={refreshConversations}

            ongoingCall={ongoingCall}
            outgoingCall={outgoingCall}

            handleInitiateCall={handleInitiateCall}
            handleTerminateCall={handleTerminateCall}
          />
        )
      }
    </div>
  );
}

export default GroupMembersList;