import Layout from "../Layout";
import termsImg from '../../assets/terms.jpg';
import './styles.css';
import { Helmet } from "react-helmet";

const TermsAndServicesPage = () => {
  return (
    <Layout heroText="Algemene Voorwaarden" heroImg={termsImg}>
      <Helmet>
        <title>Algemene Voorwaarden - ChatPlaza</title>
        <meta name="description" content="Lees de algemene voorwaarden van Chatten B.V. en ontdek onze regels, privacybeleid en verantwoordelijkheden. Veilig chatten met respect voor wet- en regelgeving. Bezoek onze pagina voor volledige details over gebruiksvoorwaarden en privacybescherming." />
      </Helmet>
      <div className="page-container">
        <div className='container-content'>
          <article className="terms-page">
            <section>
              <h1>Algemene Voorwaarden Chatten B.V.</h1>

              <p><strong>Nood- en spoedverzoeken Politie/Handhaving:</strong> In spoedgevallen of wanneer autoriteiten (zoals de Politie of Handhaving) urgente informatie opvragen, kan contact worden opgenomen via: <a href="mailto:datarequests@chatplaza.com">datarequests@chatplaza.com</a>.</p>

              <p>Chatten B.V. is een aanbieder van chatdiensten en biedt (onder meer) de volgende diensten aan:</p>
              <ul>
                <li><a href="https://www.chatten.nl" target="_blank" rel="noopener">www.chatten.nl</a></li>
                <li><a href="https://www.chatplaza.com" target="_blank" rel="noopener">www.chatplaza.com</a></li>
                <li><a href="https://www.chatpoint.com" target="_blank" rel="noopener">www.chatpoint.com</a></li>
                <li><a href="https://www.chatcity.nl" target="_blank" rel="noopener">www.chatcity.nl</a></li>
                <li><a href="https://www.chatcafe.nl" target="_blank" rel="noopener">www.chatcafe.nl</a></li>
              </ul>

              <p>Aan het gebruik van deze diensten, alsmede eventuele applicaties (‘apps’), zijn onderstaande voorwaarden verbonden. Door van deze diensten gebruik te maken, stemt u in met deze algemene voorwaarden, zoals deze zijn bijgewerkt in 2024 conform de toepasselijke Nederlandse wet- en regelgeving, de AVG (Algemene Verordening Gegevensbescherming) en de relevante Europese regelgeving waaronder de Digital Services Act (DSA).</p>

              <h2>Artikel 1. Gebruik van de dienst</h2>
              <p><strong>1.1</strong> Chatten B.V. levert chatdiensten en stelt gebruikers in staat om online met elkaar te communiceren. De dienst wordt verleend onder de voorwaarden zoals hierna beschreven.</p>

              <h2>Artikel 2. Gebruiks- en gedragsregels</h2>
              <p><strong>2.1</strong> Het is verboden de diensten te gebruiken voor handelingen die in strijd zijn met de Nederlandse of andere toepasselijke wet- en regelgeving, waaronder de DSA. Hieronder valt onder meer het opslaan, verspreiden of toegankelijk maken via de dienst van informatie die smadelijk, lasterlijk, haatzaaiend, discriminerend, racistisch, seksueel intimiderend of anderszins onrechtmatig is.</p>

              <p><strong>2.2</strong> Chatten B.V. kan per dienst specifieke gedragsregels opstellen. Overtreding van dergelijke gedragsregels wordt beschouwd als een overtreding van het in artikel 2.1 genoemde verbod.</p>

              <p><strong>2.3</strong> Meer in het bijzonder is het verboden:</p>
              <ul>
                <li>Onfatsoenlijk taalgebruik te hanteren;</li>
                <li>Minderjarigen (onder de 18 jaar) te benaderen voor seksueel contact indien u zelf 18 jaar of ouder bent (‘grooming’);</li>
                <li>U voor te doen als een ander persoon dan u feitelijk bent, in het bijzonder met het oog op contact met minderjarigen;</li>
                <li>Pornografische, erotisch getinte of anderszins aanstootgevende informatie te verspreiden, ook indien deze op zich legaal is;</li>
                <li>Informatie te verspreiden die inbreuk maakt op (intellectuele eigendoms-)rechten van derden, of hyperlinks naar dergelijke informatie te plaatsen;</li>
                <li>Anderen te helpen bij het schenden van rechten van derden, bijvoorbeeld door te linken naar hacktools of uitleg over computercriminaliteit, gericht op het plegen van strafbare feiten;</li>
                <li>De persoonlijke levenssfeer van derden te schenden, bijvoorbeeld door zonder toestemming persoonsgegevens te verspreiden of door herhaaldelijk ongewenste communicatie te verzenden;</li>
                <li>De dienst te gebruiken voor commerciële, ideële of charitatieve reclame zonder toestemming van Chatten B.V.;</li>
                <li>De dienst te gebruiken voor escortservices of erotische dating;</li>
                <li>En verder al datgene te doen wat in strijd is met algemeen aanvaarde normen van online fatsoen (‘netiquette’).</li>
              </ul>

              <p><strong>2.4</strong> Indien wordt geconstateerd dat u bovengenoemde voorwaarden overtreedt of indien een klacht hierover wordt ontvangen, mag Chatten B.V. ingrijpen om de overtreding te beëindigen. Dit kan onder meer inhouden: (tijdelijke) blokkering van uw toegang, verwijdering of aanpassing van door u geplaatste informatie of het definitief uitsluiten van uw account.</p>

              <p><strong>2.5</strong> Indien naar het oordeel van Chatten B.V. hinder, schade of ander gevaar ontstaat voor het functioneren van de computersystemen of netwerken van Chatten B.V. of derden en/of voor de dienstverlening via internet (bijvoorbeeld door overmatig verzenden van chatberichten, datalekken of het verspreiden van virussen, Trojans en vergelijkbare software), is Chatten B.V. gerechtigd alle maatregelen te nemen die zij redelijkerwijs nodig acht om dit gevaar af te wenden dan wel te voorkomen. Chatten B.V. is daarbij in het bijzonder gerechtigd om bijdragen naar eigen inzicht aan te passen, te verwijderen of uw toegang te beperken.</p>

              <p><strong>2.6</strong> Chatten B.V. is te allen tijde gerechtigd om aangifte te doen van geconstateerde strafbare feiten. Voorts is Chatten B.V. gerechtigd om uw IP-adres en andere identificerende gegevens af te geven aan een derde die klaagt dat u inbreuk maakt op diens rechten of deze voorwaarden, mits de juistheid van die klacht in redelijkheid voldoende aannemelijk is en de derde een gerechtvaardigd belang heeft bij afgifte van de gegevens.</p>

              <p><strong>2.7</strong> U vrijwaart Chatten B.V. voor alle aanspraken van derden in verband met door u geplaatste informatie.</p>

              <p><strong>2.8</strong> Het is ten strengste verboden commerciële, ideële of charitatieve reclame te maken zonder uitdrukkelijke toestemming van Chatten B.V. Tevens kan Chatten B.V. uw berichten bij de Autoriteit Consument & Markt (ACM) melden als spam en een handhavingsverzoek indienen. De ACM kan op grond van geldende regelgeving (2024) u beboeten tot € 450.000 per overtreding.</p>

              <p><strong>2.9 Nood- en spoedverzoeken door Politie of Handhaving:</strong> In spoedgevallen, of indien wetshandhavingsinstanties direct informatie nodig hebben, kunnen zij contact opnemen via <a href="mailto:datarequests@chatplaza.com">datarequests@chatplaza.com</a>. Chatten B.V. zal dergelijke verzoeken in overeenstemming met de toepasselijke wet- en regelgeving en interne procedures behandelen.</p>
            </section>
            <section>
              <h2>Artikel 3. Beschikbaarheid en onderhoud</h2>
              <p><strong>3.1</strong> Chatten B.V. doet geen enkele toezegging omtrent de beschikbaarheid van de dienst.</p>

              <p><strong>3.2</strong> Chatten B.V. onderhoudt haar diensten actief. Onderhoud kan op elk moment plaatsvinden, ook als dit tot een tijdelijke beperking van de beschikbaarheid leidt. Waar mogelijk zal Chatten B.V. onderhoud vooraf aankondigen.</p>

              <p><strong>3.3</strong> Chatten B.V. mag de functionaliteit van haar diensten aanpassen om te voldoen aan actuele (wettelijke) vereisten, technische ontwikkelingen en gebruikersbehoeften.</p>

              <h2>Artikel 4. Intellectueel eigendom</h2>
              <p><strong>4.1</strong> De diensten, de bijbehorende software, alsmede alle informatie en afbeeldingen op de websites zijn het intellectueel eigendom van Chatten B.V. Deze mogen op geen enkele wijze worden gekopieerd of gebruikt zonder voorafgaande schriftelijke toestemming van Chatten B.V., behalve indien wettelijk toegestaan.</p>

              <p><strong>4.2</strong> Informatie die u publiceert of opslaat via de diensten blijft uw eigendom (of dat van uw toeleveranciers). Chatten B.V. heeft echter een onbeperkt gebruiksrecht op deze informatie, inclusief het recht van sublicentie of hergebruik voor andere diensten van Chatten B.V., voor zover dit niet in strijd is met de privacywetgeving (AVG) of de DSA.</p>

              <p><strong>4.3</strong> Het gebruiksrecht uit het vorige lid wordt voor onbepaalde tijd verstrekt en kan niet worden ingetrokken. Het gebruiksrecht blijft ook na beëindiging van de overeenkomst bestaan. In uitzonderlijke gevallen, waarin een zwaarwegend belang tegen voortgezet gebruik van de informatie bestaat, kan Chatten B.V. besluiten tot aanpassing of verwijdering.</p>

              <p><strong>4.4</strong> Het is na plaatsing niet mogelijk om geplaatste informatie aan te passen of te verwijderen, tenzij Chatten B.V. hierin vrijwillig toestemt of hiertoe wettelijk verplicht is.</p>

              <p><strong>4.5</strong> Chatten B.V. zal geen kennis nemen van privégegevens die u opslaat en/of verspreidt via haar diensten, tenzij dit noodzakelijk is voor een goede dienstverlening of Chatten B.V. daartoe wettelijk verplicht is (bijvoorbeeld door een gerechtelijk bevel of op grond van de DSA). In dat geval zal Chatten B.V. de kennisname van de gegevens beperken tot het strikt noodzakelijke.</p>

              <p><strong>4.6</strong> Indien Chatten B.V. constateert dat uw bijdragen zijn overgenomen door sites van derden in combinatie met bijdragen van anderen, is Chatten B.V. gemachtigd om hiertegen in rechte op te treden, desgewenst namens u. U dient daaraan medewerking te verlenen indien noodzakelijk. Deze bepaling beperkt u niet om uw eigen bijdrage elders te publiceren, maar is uitsluitend gericht op het kunnen optreden tegen onrechtmatige overname van (substantieel) alle bijdragen.</p>

              <h2>Artikel 5. Vergoeding voor de dienst</h2>
              <p><strong>5.1</strong> Om de diensten aan te kunnen bieden, maakt Chatten B.V. gebruik van advertenties.</p>

              <p><strong>5.2</strong> Chatten B.V. behoudt zich het recht voor de toegang tot een dienst te blokkeren indien advertenties door de gebruiker worden geblokkeerd (zoals via adblockers), behoudens voor zover dit in strijd is met toepasselijke wetgeving.</p>

              <h2>Artikel 6. Aansprakelijkheid</h2>
              <p><strong>6.1</strong> Behoudens in geval van opzet of grove nalatigheid aan de zijde van Chatten B.V. is Chatten B.V. niet aansprakelijk voor enige schade voortvloeiende uit het gebruik van de dienst.</p>

              <p><strong>6.2</strong> Chatten B.V. sluit meer in het bijzonder iedere aansprakelijkheid uit voor indirecte schade, gevolgschade, gederfde winst, gemiste besparingen en schade door bedrijfsstagnatie.</p>

              <h2>Artikel 7. Privacy (AVG)</h2>
              <p>Chatten B.V. verwerkt persoonsgegevens conform de AVG en andere relevante (Europese) regelgeving, zoals de DSA. De bijgewerkte privacyverklaring (2024) kunt u <a href="#" target="_blank" rel="noopener">hier downloaden</a>.</p>

              <h2>Artikel 8. Overige bepalingen</h2>
              <p><strong>8.1</strong> Op alle rechtsverhoudingen tussen u en Chatten B.V. is Nederlands recht van toepassing, met inachtneming van dwingendrechtelijke Europese regelgeving.</p>

              <p><strong>8.2</strong> Alle geschillen die voortvloeien uit of samenhangen met deze overeenkomst worden voorgelegd aan de bevoegde Nederlandse rechter in het arrondissement waarin Chatten B.V. gevestigd is, tenzij dwingendrechtelijke regels anders voorschrijven.</p>

              <p><strong>8.3</strong> Indien een bepaling in deze algemene voorwaarden vereist dat een mededeling ‘schriftelijk’ dient te geschieden, voldoet een mededeling per e-mail of via de dienst hieraan, mits in voldoende mate vaststaat dat het bericht van de vermeende afzender afkomstig is en de integriteit van het bericht niet is aangetast.</p>

              <p><strong>8.4</strong> De door Chatten B.V. opgeslagen versie van enige communicatie of informatie geldt als authentiek, behoudens tegenbewijs.</p>

              <p><strong>8.5</strong> Indien een bepaling in deze algemene voorwaarden nietig blijkt te zijn, tast dit niet de geldigheid van de gehele voorwaarden aan. Partijen zullen in dat geval ter vervanging nieuwe bepalingen vaststellen die rechtens toelaatbaar zijn en die zoveel mogelijk aansluiten bij de strekking van de oorspronkelijke bepaling.</p>

              <p><strong>8.6</strong> Chatten B.V. is gerechtigd haar rechten en verplichtingen uit deze overeenkomst over te dragen aan een derde die de desbetreffende bedrijfsactiviteiten overneemt.</p>
            </section>
          </article>
        </div>
      </div>
    </Layout>
  );
}

export default TermsAndServicesPage;
