import React from 'react';
import ChannelHeader from 'anchor-ui/channel-header';
import Card from 'anchor-ui/card';
import Profile from 'anchor-ui/profile';
import Button from 'anchor-ui/button';
import CardHeader from 'anchor-ui/card-header';
import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';
import Avatar from 'anchor-ui/avatar';
import defaultAvatar from "./../../assets/default_avatar.jpg";
import { getGenderFromMetadata } from './../../utils/getGenderFromMetadata';
import FriendButtons from './../../components/LeftSideBarMenu/FriendsListComponent/FriendButtons';
import ConversationsListComponent from './../../components/LeftSideBarMenu/ConversationListComponent';
import { ActiveChannelInterface } from './../../interfaces/activeChannelInterface';
import ToggleLeftMenuButton from '../ToggleLeftMenuButton';
import './styles.css';
import { checkIfUserIsBlocked } from '../../utils/checkIfUserIsBlocked';
import GoogleAdSense from '../GoogleAdSense';
import { Functions } from 'firebase/functions';
import { UserSettingsInterface } from '../../interfaces/userSettingsInterface';
import { ConversationInterface } from '../../interfaces/conversationInterface';
import blogData from "./../BlogPage/Blogs/articles.json";
import AudioChannelHeader from '../AudioChannelHeader';
import { Call } from '@cometchat/chat-sdk-javascript';
import { checkUserIsRoyal } from '../../utils/checkSubscription';

const Timeline = ({
  loggedInUser,
  functions,
  friends,
  showUserProfile,
  startPrivateChat,
  setUserToReport,
  setFriendToDelete,
  blockedUsers,
  handleBlockUser,
  conversations,
  unreadCount,
  handleConversationClick,
  handleGroupConversationClick,
  initialConversation,
  currentChat,
  setCurrentChat,
  refreshConversations,
  setUnreadCount,
  setAlert,
  leftMenuOpen,
  setLeftMenuOpen,
  userSettings,
  handleRemoveConversation,

  ongoingCall,
  setOngoingCall,
  outgoingCall,
  setOutgoingCall,

  handleInitiateCall,

  setNeedSubscriptionWarning,
}: {
  loggedInUser: CometChat.User;
  functions: Functions;
  friends: CometChat.User[];
  showUserProfile: (user: CometChat.User) => void;
  startPrivateChat: (user: CometChat.User) => void;
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  setFriendToDelete: (friend: CometChat.User | null) => void;
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;
  conversations: ConversationInterface[];
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  handleConversationClick: (conversation: CometChat.Conversation) => void;
  handleGroupConversationClick: (group: CometChat.Group) => Promise<void>;
  initialConversation: CometChat.Conversation | null;
  currentChat: ActiveChannelInterface;
  setCurrentChat: React.Dispatch<React.SetStateAction<ActiveChannelInterface>>;
  refreshConversations: () => void;
  setUnreadCount: React.Dispatch<React.SetStateAction<{
    [id: string]: { nr: number; chat: ActiveChannelInterface; };
  }>>;
  setAlert: (alert: { message: string, type: string } | null) => void;
  leftMenuOpen: boolean;
  setLeftMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userSettings: UserSettingsInterface;
  handleRemoveConversation: (conversation: CometChat.Conversation | CometChat.Group) => void;

  ongoingCall: Call | null;
  setOngoingCall: React.Dispatch<React.SetStateAction<Call | null>>;
  outgoingCall: { call: Call, receiver: CometChat.User } | null;
  setOutgoingCall: React.Dispatch<React.SetStateAction<{ call: Call, receiver: CometChat.User } | null>>;
  handleInitiateCall: (user: CometChat.User) => void;

  setNeedSubscriptionWarning: React.Dispatch<React.SetStateAction<React.ReactNode | null>>;
}) => {

  const onlineFriends = friends.filter(friend => friend.getStatus() === 'online');
  return (
    <section className='timeline-body'>
      <div className='timeline-container'>

        <AudioChannelHeader
          ongoingCall={ongoingCall}
          setOngoingCall={setOngoingCall}
          outgoingCall={outgoingCall}
          setOutgoingCall={setOutgoingCall}
        />

        <ChannelHeader
          name="Tijdlijn"
          style={{ lineHeight: '18px' }}
          leftButton={
            <ToggleLeftMenuButton
              leftMenuOpen={leftMenuOpen}
              setLeftMenuOpen={setLeftMenuOpen}
              unreadCount={unreadCount}
            />
          }
        />

        <div className="timeline-top-mobile">
          <Card>
            <Profile
              header={loggedInUser.getName()}
              button={
                <Button onClick={() => setCurrentChat((prev: any) => ({ ...prev, userProfile: loggedInUser }))}>
                  Bekijk Profiel
                </Button>
              }
              avatar={loggedInUser.getAvatar() ?? defaultAvatar}
              coverImage={loggedInUser.getAvatar() ?? defaultAvatar}
              coverImageStyle={{ backgroundColor: userSettings.themeColor }}
              style={{ paddingBottom: '16px' }}
            />
          </Card>
        </div>

        <div className='timeline-top'>


          <Card style={{ height: '100%', width: '100%', backgroundColor: 'white' }}>
            <GoogleAdSense
              adSlot="8285598752"
              style={{ display: 'inline-block', width: "984px", height: "121px" }}
              isRoyal={checkUserIsRoyal(loggedInUser?.getRole() ?? "")}
            />
          </Card>
        </div>

        <div className='timeline-content'>
          <div className='timeline-side-card'>
            <Card style={{ height: '100%', backgroundColor: 'white' }}>
              <GoogleAdSense
                adSlot="9999904289"
                style={{ display: 'inline-block', width: '200px', height: '750px' }}
                isRoyal={checkUserIsRoyal(loggedInUser?.getRole() ?? "")}
              />
            </Card>
          </div>

          <section className='timeline-section'>
            <div className='timeline-card timeline-card-left'>
              <Card>
                <Profile
                  header={loggedInUser.getName()}
                  button={
                    <Button onClick={() => setCurrentChat((prev: any) => ({ ...prev, userProfile: loggedInUser }))}>
                      Bekijk Profiel
                    </Button>
                  }
                  avatar={loggedInUser.getAvatar() ?? defaultAvatar}
                  coverImage={loggedInUser.getAvatar() ?? defaultAvatar}
                  coverImageStyle={{ backgroundColor: userSettings.themeColor }}
                  style={{ paddingBottom: '16px' }}
                />
              </Card>

              <div className='timeline-conversations'>
                <Card style={{ height: '100%', backgroundColor: 'white', marginTop: '16px' }}>
                  <ConversationsListComponent
                    conversations={conversations}
                    unreadCount={unreadCount}
                    handleConversationClick={handleConversationClick}
                    handleGroupConversationClick={handleGroupConversationClick}
                    initialConversation={initialConversation}
                    currentChat={currentChat}
                    setCurrentChat={setCurrentChat}
                    friends={friends}
                    refreshConversations={refreshConversations}
                    setUnreadCount={setUnreadCount}
                    loggedInUser={loggedInUser}
                    blockedUsers={blockedUsers}
                    setAlert={setAlert}
                    isTimeline
                    functions={functions}
                    userSettings={userSettings}
                    handleRemoveConversation={handleRemoveConversation}

                    handleInitiateCall={handleInitiateCall}
                    setNeedSubscriptionWarning={setNeedSubscriptionWarning}
                  />
                </Card>
              </div>
            </div>

            <div className='timeline-card timeline-middle-card'>
              <Card style={{ height: '100%', backgroundColor: 'white' }}>
                <GoogleAdSense
                  adSlot="7324535835"
                  style={{ display: 'inline-block', width: '352px', height: '278px' }}
                  isRoyal={checkUserIsRoyal(loggedInUser?.getRole() ?? "")}
                />
              </Card>

              <Card style={{ height: '450px', backgroundColor: 'white', marginTop: "16px" }}>
                <CardHeader title="Laatste nieuws" />
                <a className="blog-card timeline" href={`/blog/${blogData[0].url}`} target="_blank">
                  <img src={blogData[0].img} alt="Blog" />
                  <div className="blog-card-content">
                    <h3>{blogData[0].title}</h3>
                    <p>
                      {blogData[0].content.intro.length > 100 ? blogData[0].content.intro.substring(0, 100) + '...' : blogData[0].content.intro}
                    </p>
                    <small>{blogData[0].publicationDate}</small>
                  </div>
                </a>
              </Card>
            </div>

            <div className='timeline-card timeline-friends'>
              <Card style={{ height: '100%', backgroundColor: 'white' }}>
                <CardHeader title={`Online vrienden (${onlineFriends.length})`} />
                <List>
                  {onlineFriends.map((friend, index) => (
                    <ListItem
                      key={index}
                      primaryText={friend.getName()}
                      secondaryText={getGenderFromMetadata(friend)}
                      avatar={
                        <Avatar
                          image={friend.getAvatar() ?? defaultAvatar}
                          style={{ backgroundColor: userSettings.themeColor }}
                          status={checkIfUserIsBlocked(blockedUsers, friend.getUid()) ? null : friend.getStatus()}
                        />
                      }
                      blocked={checkIfUserIsBlocked(blockedUsers, friend.getUid())}
                      rightButton={
                        <FriendButtons
                          friend={friend}
                          showUserProfile={showUserProfile}
                          startPrivateChat={startPrivateChat}
                          setUserToReport={setUserToReport}
                          setFriendToDelete={setFriendToDelete}
                          blockedUsers={blockedUsers}
                          handleBlockUser={handleBlockUser}
                        />
                      }
                    />
                  ))}
                </List>
              </Card>

              <div className='timeline-card timeline-bottom-right'>
                <Card style={{ height: '100%', backgroundColor: 'white' }}>
                  <GoogleAdSense
                    adSlot="4284543179"
                    style={{ display: 'inline-block', width: '300px', height: '240px' }}
                    isRoyal={checkUserIsRoyal(loggedInUser?.getRole() ?? "")}
                  />
                </Card>
              </div>
            </div>
          </section>

          <div className='timeline-side-card'>
            <Card style={{ height: '100%', backgroundColor: 'white' }}>
              <GoogleAdSense
                adSlot="6426387134"
                style={{ display: 'inline-block', width: '200px', height: '750px' }}
                isRoyal={checkUserIsRoyal(loggedInUser?.getRole() ?? "")}
              />
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Timeline;