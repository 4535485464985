import ChannelHeader from 'anchor-ui/channel-header';
import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';
import { IconSettings } from 'anchor-ui/icons';

const LeftSettingsMenu = ({
  activeColorState,
  activePage,
  setActivePage,
  isSideNav,
  handleClose,
}: {
  activeColorState: string;
  activePage: string;
  setActivePage: (page: string) => void;
  isSideNav?: boolean;
  handleClose?: () => void;
}) => {

  const handleChangePage = (page: string) => {
    setActivePage(page);
    if (handleClose) handleClose();
  }

  return (
    <div className={`sidebar ${!isSideNav ? 'widescreen-sidebar' : 'narrowscreen-sidebar'}`}>
      <ChannelHeader
        name="Instellingen"
        textStyle={{ color: activeColorState, float: 'left' }}
        leftButton={
          <IconSettings
            color={activeColorState}
            style={{ marginTop: '8px' }}
          />
        }
        style={{ paddingLeft: '40px' }}
      />

      <List>
        <ListItem
          primaryText='Account'
          active={activePage === 'account'}
          onClick={() => handleChangePage('account')}
        />
        <ListItem
          primaryText='Privacy'
          active={activePage === 'privacy'}
          onClick={() => handleChangePage('privacy')}
        />
        <ListItem
          primaryText="Thema's"
          active={activePage === 'themes'}
          onClick={() => handleChangePage('themes')}
        />
        <ListItem
          primaryText="Uiterlijk"
          active={activePage === 'visuals'}
          onClick={() => handleChangePage('visuals')}
        />
        <ListItem
          primaryText="Geblokkeerde gebruikers"
          active={activePage === 'blocked-users'}
          onClick={() => setActivePage('blocked-users')}
        />
      </List>
    </div>
  )
};

export default LeftSettingsMenu;