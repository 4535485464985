import React, { useEffect, useRef } from "react";

declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

interface AdSenseProps {
  adSlot: string; // The AdSense ad slot ID
  style?: React.CSSProperties; // Optional custom styles
  className?: string; // Optional custom className
  isRoyal?: boolean; // Optional prop to determine if the ad should show
}

const GoogleAdSense: React.FC<AdSenseProps> = ({ 
  adSlot, 
  style, 
  className,
  isRoyal,
}) => {
  const adRef = useRef<HTMLDivElement>(null);

  // Check if the current URL contains /login
  if (location.pathname.includes('/login') || isRoyal) {
    return null;
  }

  useEffect(() => {
    // Dynamically add the AdSense script if not already loaded
    const existingScript = document.querySelector('script[src*="pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8634209433386972'; 
      script.async = true;
      script.crossOrigin = 'anonymous';
      document.body.appendChild(script);
    }

    // Function to push ads
    const pushAds = () => {
      try {
        if (window) {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
      } catch (e) {
        console.error('AdSense error:', e);
      }
    };

    // Push ads when the component is rendered
    pushAds();

    // Set interval to refresh ads every 2 minutes
    const intervalId = setInterval(() => {
      if (adRef.current) {
        adRef.current.innerHTML = ''; // Clear the ad container
        const adElement = document.createElement('ins');
        adElement.className = `adsbygoogle ${className ?? ""}`;
        adElement.style.cssText = style ? Object.entries(style).map(([key, value]) => `${key}:${value}`).join(';') : '';
        adElement.setAttribute('data-ad-client', 'ca-pub-8634209433386972');
        adElement.setAttribute('data-ad-slot', adSlot);
        adElement.setAttribute('data-full-width-responsive', 'true');
        adRef.current.appendChild(adElement);
        pushAds();
      }
    }, 120000); // 120000 ms = 2 minute

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [adSlot, className, style]); // Re-run if adSlot, className, or style changes

  return (
    <div ref={adRef} className="adsense-container">
      <ins
        className={`adsbygoogle ${className ?? ""}`}
        style={style}
        data-ad-client="ca-pub-8634209433386972"
        data-ad-slot={adSlot}
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default GoogleAdSense;