import { useEffect, useRef } from 'react';
import { CometChat } from "@cometchat/chat-sdk-javascript";
import './styles.css';
import { ActiveChannelInterface } from './../../interfaces/activeChannelInterface';
import TabsComponent from './TabsComponent';
import { FriendRequest } from './../../utils/class/FriendRequest';
import { NotificationInterface } from './../../interfaces/notificationInterface';
import { Functions } from 'firebase/functions';
import { UserSettingsInterface } from '../../interfaces/userSettingsInterface';
import { ConversationInterface } from '../../interfaces/conversationInterface';
import { getProfileFromMetadata } from '../../utils/updateMetadata';

interface LeftSideBarMenuProps {
  activeChannels: ActiveChannelInterface[];
  onlineMembersCount: { [key: string]: number } | null;
  currentChat: ActiveChannelInterface;
  setCurrentChat: (channel: ActiveChannelInterface) => void;
  handleLeaveChannel: (channel: ActiveChannelInterface) => void;

  conversations: ConversationInterface[];
  setConversations: (conversations: ConversationInterface[]) => void;
  initialConversation: CometChat.Conversation | null;
  refreshConversations: () => void;
  handleGroupConversationClick: (conversation: CometChat.Group) => void;

  friends: CometChat.User[];
  loadFriends: () => void;
  setFriends: React.Dispatch<React.SetStateAction<CometChat.User[]>>;
  handleRemoveFriend: (friend: CometChat.User) => void;
  incomingFriendRequests: FriendRequest[];
  loadIncomingFriendRequests: () => void;
  outgoingFriendRequests: FriendRequest[];
  loadOutgoingFriendRequests: () => void;

  handleAcceptFriendRequest: (friend: FriendRequest) => void;
  handleDenyFriendRequest: (friend: FriendRequest) => void;
  handleCancelFriendRequest: (friend: FriendRequest) => void;

  handleConversationClick: (conversation: CometChat.Conversation) => void;
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  setUnreadCount: React.Dispatch<React.SetStateAction<{ [id: string]: { nr: number, chat: ActiveChannelInterface } }>>;

  activeTabIndex: number;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;

  setNotifications: React.Dispatch<React.SetStateAction<NotificationInterface[] | null>>;
  loggedInUser: CometChat.User | null;

  channels: CometChat.Group[];

  startPrivateChat: (user: CometChat.User) => void;
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;

  userToReport: { user: CometChat.User, guid?: string } | null;
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  members: CometChat.GroupMember[];
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;

  friendToDelete: CometChat.User | null;
  setFriendToDelete: React.Dispatch<React.SetStateAction<CometChat.User | null>>;
  showUserProfile: (user: CometChat.User) => void;
  isSideNav: boolean | undefined;

  handleLogout: () => void;

  handleChangeChannel: (channel: ActiveChannelInterface) => void;
  functions: Functions;

  userSettings: UserSettingsInterface;

  handleRemoveConversation: (conversation: ConversationInterface) => void;

  setWarningMessage: (message: string) => void;
  messageToForward: CometChat.BaseMessage | null;
  setMessageToForward: React.Dispatch<React.SetStateAction<CometChat.BaseMessage | null>>;

  handleSendMessage: (event: any, receiver: string, isGroup: boolean, messageText?: string, isForwarded?: boolean) => void;
  handleInitiateCall: (receiver: CometChat.User) => void;

  setNeedSubscriptionWarning: React.Dispatch<React.SetStateAction<React.ReactNode | null>>;
}

const LeftSidebarMenu: React.FC<LeftSideBarMenuProps> = ({
  activeChannels,
  onlineMembersCount,
  currentChat,
  setCurrentChat,
  handleLeaveChannel,
  conversations,
  setConversations,
  initialConversation,
  refreshConversations,
  handleGroupConversationClick,
  friends,
  loadFriends,
  handleRemoveFriend,
  incomingFriendRequests,
  loadIncomingFriendRequests,
  outgoingFriendRequests,
  loadOutgoingFriendRequests,
  handleAcceptFriendRequest,
  handleDenyFriendRequest,
  handleCancelFriendRequest,
  handleConversationClick,
  unreadCount,
  setUnreadCount,
  activeTabIndex,
  setActiveTabIndex,
  setNotifications,
  loggedInUser,

  channels,
  startPrivateChat,
  blockedUsers,
  handleBlockUser,

  setUserToReport,
  members,
  setAlert,

  friendToDelete,
  setFriendToDelete,
  showUserProfile,

  isSideNav,

  handleLogout,

  handleChangeChannel,
  functions,

  userSettings,

  handleRemoveConversation,

  setWarningMessage,

  messageToForward,
  setMessageToForward,

  handleSendMessage,
  handleInitiateCall,

  setNeedSubscriptionWarning,
}) => {

  // Make use of useRef to store the function references so that they 
  // can be used in the useEffect hook without causing an infinite loop
  const loadFriendsRef = useRef(loadFriends);
  const loadIncomingFriendRequestsRef = useRef(loadIncomingFriendRequests);
  const loadOutgoingFriendRequestsRef = useRef(loadOutgoingFriendRequests);
  const refreshConversationsRef = useRef(refreshConversations);
  const setConversationsRef = useRef(setConversations);
  const setCurrentChatRef = useRef(setCurrentChat);
  const setNotificationsRef = useRef(setNotifications);
  const setUnreadCountRef = useRef(setUnreadCount);

  // Update the function references when the functions change
  useEffect(() => {
    loadFriendsRef.current = loadFriends;
    loadIncomingFriendRequestsRef.current = loadIncomingFriendRequests;
    loadOutgoingFriendRequestsRef.current = loadOutgoingFriendRequests;
    refreshConversationsRef.current = refreshConversations;
    setConversationsRef.current = setConversations;
    setCurrentChatRef.current = setCurrentChat;
    setNotificationsRef.current = setNotifications;
    setUnreadCountRef.current = setUnreadCount;
  }, [loadFriends,
    loadIncomingFriendRequests,
    loadOutgoingFriendRequests,
    refreshConversations,
    setConversations,
    setCurrentChat,
    setNotifications,
    setUnreadCount]
  );

  useEffect(() => {
    if (!loggedInUser) return;
    const listenerId = `UNREAD_MESSAGE_LISTENER_${Date.now()}`;

    CometChat.addMessageListener(
      listenerId,
      new CometChat.MessageListener({
        onTextMessageReceived: async (message: any) => {
          // Custom messages are used for friend requests and other notifications
          if (message.getCategory() === 'custom') {
            const tags = message.getTags();

            if (tags.includes('friend_request')) {
              loadIncomingFriendRequestsRef.current();
            } else if (tags.includes('reload_requests')) {
              const reloadReason = message.data.customData.reloadReason;

              switch (reloadReason) {
                case 'friend_request_accepted':
                  loadFriendsRef.current();
                  loadOutgoingFriendRequestsRef.current();
                  break;
                case 'friend_request_denied':
                  loadOutgoingFriendRequestsRef.current();
                  break;
                case 'friend_request_canceled':
                  loadIncomingFriendRequestsRef.current();
                  break;
                case 'friend_removed':
                  loadFriendsRef.current();
                  break;
                case 'group_created':
                  setNotificationsRef.current(prev => [...(prev || []), { type: 'groupCreated', payload: message }]);
                  refreshConversationsRef.current();
                  break;
                case 'group_deleted':
                  // Remove group from active channels
                  const group = message.customData.group;
                  if (currentChat.id === group.guid) {
                    setCurrentChat({ id: '', icon: '', name: '', joinedAt: 0, isGroup: false });
                    setAlert({ message: `Groep ${group.name} is verwijderd`, type: 'info' });
                  }

                  // Remove notification of group created
                  setNotificationsRef.current(prev => (prev || []).filter(notification => {
                    if (notification.type === 'groupCreated') {
                      if ('customData' in notification.payload && (notification.payload as any).customData.group.guid === group.guid) {
                        return false;
                      }
                    }
                    return true;
                  }));

                  // Remove unreadmessages from deleted group
                  setUnreadCountRef.current(prev => {
                    const updatedUnreadCount = { ...prev };
                    delete updatedUnreadCount[group.guid];
                    return updatedUnreadCount;
                  });
                  refreshConversationsRef.current();
                  break;
                case "user_banned":
                  // Show an alert that the user has been banned
                  setAlert({ message: 'Je bent verbannen.', type: 'error' });

                  // Logout the user
                  handleLogout();
                  break;
                case 'user_kicked':
                  // Get groupname, guid an isGroup from the message
                  const { groupName, groupUid, isGroup } = message.customData.group;

                  // Check if the groupUid is in groupconversations
                  const groupConversationExists = conversations.some((conversation: ConversationInterface) => (conversation.conversation as any).guid === groupUid);
                  if (groupConversationExists) {
                    // Remove from conversations state
                    refreshConversations();
                    if (currentChat.id === groupUid) setCurrentChat({ id: '', icon: '', name: '', joinedAt: 0, isGroup: false });
                  }
                  else {
                    // Remove from activeChannels state
                    handleLeaveChannel({ id: groupUid, icon: '', name: groupName, joinedAt: 0, isGroup: isGroup });
                  }

                  // Show an alert that the user has been kicked
                  setAlert({ message: `Je bent gekicked uit ${groupName}.`, type: 'error' });
                  break;
                case "user_logout_kicked":
                  handleLogout();
                  // Show an alert that the user has been kicked
                  setAlert({ message: 'Je bent gekicked.', type: 'error' });
                  break;
                case "user_muted":
                  // Change the user's metadata to show that they are muted
                  const metadata = getProfileFromMetadata(loggedInUser);
                  metadata.muted = true;

                  loggedInUser.setMetadata(metadata);
                  await CometChat.updateCurrentUserDetails(loggedInUser);
                  break;
                case "user_unmuted":
                  // Change the user's metadata to show that they are unmuted
                  const unmuteMetadata = getProfileFromMetadata(loggedInUser);
                  // Delete the muted field from the metadata
                  delete unmuteMetadata.muted;

                  loggedInUser.setMetadata(unmuteMetadata);
                  await CometChat.updateCurrentUserDetails(loggedInUser);
                  break;
                case "user_warned":
                  const messageText = message.data.customData.message;
                  // Show an alert that the user has been warned
                  setWarningMessage(messageText);
                  break;
                default:
                  break;
              }
            }
            return;
          }

          // Determine if the message is part of an active conversation
          const conversationIsActive = activeChannels.some(channel => channel.id === message.getReceiverId()) ||
            message.getReceiverId() === currentChat.id ||
            message.getConversationId() === currentChat.id;

          const conversationExists = conversations.some(
            (conversation: ConversationInterface) => (conversation.conversation as any).conversationId === message.getConversationId()
          );

          if (conversationIsActive) {
            const conversation = await CometChat.CometChatHelper.getConversationFromMessage(message);

            try {
              await CometChat.markAsRead(conversation.getLastMessage());

              const updatedConversations: ConversationInterface[] = [];

              // Reorder the conversations list so that the current conversation is at the top
              conversations.forEach((conversation) => {
                const id = (conversation.conversation as any).conversationId ?? (conversation.conversation as any).guid;
                const isCurrentConversation = id === message.getConversationId() || id === message.getReceiverId();

                // Add the current conversation to the top of the list and update the last message
                if (isCurrentConversation) {
                  const newConversation = {
                    ...conversation,
                    lastMsg: message.text,
                    isMyMsg: false
                  }
                  updatedConversations.unshift(newConversation);

                  return; // Skip the rest of the loop to avoid duplicates
                }

                // Only add the conversation if it is not the current conversation
                updatedConversations.push(conversation);
              });

              // Move the current conversation to the top of the list
              setConversationsRef.current(updatedConversations);
            } catch (error) {
              console.error(error);
            }
          } else if (message.getConversationId() && conversationExists) { // Conversation is not a group

            let updatedConversations: ConversationInterface[] = [];

            conversations.forEach((conversation: ConversationInterface) => {
              const id = (conversation.conversation as any).conversationId;

              if (id === message.getConversationId()) {

                updatedConversations.unshift({
                  ...conversation,
                  lastMsg: message.text,
                  isMyMsg: false
                });
              } else {
                updatedConversations.push(conversation);
              }
            });

            const currentConversation: ConversationInterface = conversations.find((conversation: ConversationInterface) => {
              const id = (conversation.conversation as any).conversationId ?? (conversation.conversation as any).guid;
              return id === message.getConversationId();
            }) as ConversationInterface;

            setConversationsRef.current(updatedConversations);

            setUnreadCountRef.current(prev => {
              const id = message.getConversationId();
              const prevCount = (prev[id]?.nr ?? 0) + 1;
              const chatId = (currentConversation.conversation as any).conversationId;
              const chatIcon = (currentConversation.conversation as any).conversationWith.avatar;
              const chatName = (currentConversation.conversation as any).conversationWith.name;

              return {
                ...prev,
                [id]: {
                  nr: prevCount,
                  chat: {
                    id: chatId,
                    icon: chatIcon,
                    name: chatName,
                    isGroup: false
                  }
                }
              };
            });
          } else {
            // Get the conversation of the message
            const conversation = await CometChat.CometChatHelper.getConversationFromMessage(message);
            const isGroup = "guid" in conversation || conversation.getConversationType() === 'group';

            // Ignore messages from the current (initial) conversation
            if (!isGroup && (conversation.getConversationWith() as CometChat.User).getUid() === currentChat.id) return;

            const id = isGroup ? (conversation.getConversationWith() as CometChat.Group).getGuid() : conversation.getConversationId();

            const newUnreadCount = {
              ...unreadCount,
              [id]: {
                nr: ((unreadCount[id] as { nr: number } || { nr: 0 }).nr || 0) + 1,
                chat: {
                  id: id,
                  icon: isGroup ? '' : (conversation.getConversationWith() as CometChat.User).getAvatar(),
                  name: conversation.getConversationWith().getName(),
                  joinedAt: Date.now(),
                  isGroup: isGroup
                }
              }
            };

            // Update the unread count state
            setUnreadCountRef.current(newUnreadCount);

            // Conversation of incoming message does not exist, refresh the conversations list
            refreshConversationsRef.current();
          }

          // Handle group messages that are not part of existing conversations
          if (message.getReceiverType() === "group" && !conversationExists) {
            // Ignore messages from the current channel
            if (currentChat.id === message.getReceiverId()) return;

            // Ignore messages from inactive channels
            if (!conversationIsActive) return;

            setUnreadCountRef.current(prev => ({
              ...prev,
              [message.getReceiverId()]: {
                nr: ((prev[message.getReceiverId()] as { nr: number } || { nr: 0 }).nr || 0) + 1,
                chat: {
                  id: message.getReceiverId(),
                  icon: message.getReceiver().getIcon(),
                  name: message.getReceiver().getName(),
                  isGroup: true
                }
              }
            }));
          }
        },
        onMediaMessageReceived: async (message: any) => {
          // Check if the message is part of an active conversation
          const conversationIsActive = activeChannels.some(channel => channel.id === message.getReceiverId()) || message.getReceiverId() === currentChat.id || message.getConversationId() === currentChat.id;

          // Mark as read if the message is part of an active conversation
          if (conversationIsActive) {
            await CometChat.markAsRead(message);
          } else {
            // Add to unread count if the message is not part of an active conversation
            const isGroup = message.getReceiverType() === 'group';
            const id = isGroup ? message.getReceiver().getGuid() : message.getConversationId();

            const newUnreadCount = {
              ...unreadCount,
              [id]: {
                nr: ((unreadCount[id] as { nr: number } || { nr: 0 }).nr || 0) + 1,
                chat: {
                  id: id,
                  icon: isGroup ? '' : (message.getSender() as CometChat.User).getAvatar(),
                  name: isGroup ? message.getReceiver().getName() : (message.getSender() as CometChat.User).getName(),
                  isGroup: isGroup
                }
              }
            };

            // Update the unread count state
            setUnreadCountRef.current(newUnreadCount);
          }

          // Reorder the conversations list so that the current conversation is at the top
          const updatedConversations = conversations.filter((conversation: ConversationInterface) => {
            const id = (conversation.conversation as any).conversationId ?? (conversation.conversation as any).guid;

            return id !== message.getConversationId();
          });

          const currentConversation = conversations.find((conversation: ConversationInterface) => {
            const id = (conversation.conversation as any).conversationId ?? (conversation.conversation as any).guid;

            return id === message.getConversationId();
          });

          if (currentConversation) updatedConversations.unshift(currentConversation);

          setConversationsRef.current(updatedConversations);
        }
      })
    );

    return () => {
      // Remove the message listener when the component unmounts
      CometChat.removeMessageListener(listenerId);
    };
  }, [activeChannels, conversations, currentChat.id, unreadCount]);

  return (
    <div className={`sidebar ${!isSideNav ? 'widescreen-sidebar' : 'narrowscreen-sidebar'}`}>
      <TabsComponent
        channels={channels}
        activeChannels={activeChannels}
        onlineMembersCount={onlineMembersCount}
        unreadCount={unreadCount}
        handleChangeChannel={handleChangeChannel}
        currentChat={currentChat}
        setCurrentChat={setCurrentChat}
        handleLeaveChannel={handleLeaveChannel}

        conversations={conversations}
        handleConversationClick={handleConversationClick}
        handleGroupConversationClick={handleGroupConversationClick}
        refreshConversations={refreshConversations}
        initialConversation={initialConversation}

        friends={friends}
        handleRemoveFriend={handleRemoveFriend}
        incomingFriendRequests={incomingFriendRequests}
        outgoingFriendRequests={outgoingFriendRequests}

        handleAcceptFriendRequest={handleAcceptFriendRequest}
        handleDenyFriendRequest={handleDenyFriendRequest}
        handleCancelFriendRequest={handleCancelFriendRequest}

        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}

        setUnreadCount={setUnreadCount}
        setNotifications={setNotifications}
        loggedInUser={loggedInUser}

        startPrivateChat={startPrivateChat}
        blockedUsers={blockedUsers}
        handleBlockUser={handleBlockUser}

        setUserToReport={setUserToReport}
        members={members}
        setAlert={setAlert}

        friendToDelete={friendToDelete}
        setFriendToDelete={setFriendToDelete}
        showUserProfile={showUserProfile}
        functions={functions}

        userSettings={userSettings}

        handleRemoveConversation={handleRemoveConversation}

        messageToForward={messageToForward}
        setMessageToForward={setMessageToForward}

        handleSendMessage={handleSendMessage}

        isSideNav={isSideNav}
        handleInitiateCall={handleInitiateCall}

        setNeedSubscriptionWarning={setNeedSubscriptionWarning}
      />
    </div>
  );
}

export default LeftSidebarMenu;