const checkIfUserIsAdmin = (user: CometChat.User) => {
  if (user.getRole() === undefined) return false;
  return (user.getRole().includes("admin"));
}

const checkIfUserIsModerator = (user: CometChat.User) => {
  if (user.getRole() === undefined) return false;
  return (user.getRole().includes("moderator"));
}

const checkIfUserIsSuperAdmin = (user: CometChat.User) => {
  if (user.getRole() === undefined) return false;
  return (user.getRole().includes("superadmin"));
}

const checkIfUserHasPermissions = (user: CometChat.User) => {
  if (user.getRole() === undefined) return false;
  return checkIfUserIsAdmin(user) || checkIfUserIsModerator(user) || checkIfUserIsSuperAdmin(user);
}

export { checkIfUserIsAdmin, checkIfUserIsModerator, checkIfUserIsSuperAdmin, checkIfUserHasPermissions };