import Select from "anchor-ui/select";
import MenuItem from "anchor-ui/menu-item";
import Switch from "anchor-ui/switch";
import { UserSettingsInterface } from "../../interfaces/userSettingsInterface";

const VisualsPage = ({
  userSettings,
  setUserSettings
}: {
  userSettings: UserSettingsInterface;
  setUserSettings: (userSettings: UserSettingsInterface) => void;
}) => {

  return (
    <div>
      <h2>Uiterlijk</h2>

      <section className="account-input">

        <section className="setting-select">
          <Select
            label="Berichttekstgrootte"
            value={userSettings.messageFontSize}
            onChange={(event: object, value: string) => setUserSettings({ ...userSettings, messageFontSize: value })}
          >
            <MenuItem text="Normaal" value="font-normal" />
            <MenuItem text="Medium" value="font-medium" />
            <MenuItem text="Groot" value="font-large" />
          </Select>
        </section>

        <section className="setting-select">
          <Select
            label="Algemene tekstgrootte"
            value={userSettings.generalFontSize}
            onChange={(event: object, value: string) => setUserSettings({ ...userSettings, generalFontSize: value })}
          >
            <MenuItem text="Normaal" value="font-normal" />
            <MenuItem text="Medium" value="font-medium" />
            <MenuItem text="Groot" value="font-large" />
          </Select>
        </section>

        <section className="setting-select">
          <Switch
            label='Verberg afbeeldingen, .gif bestanden & youtube embeds in chat'
            active={userSettings.mediaDisabled}
            toggleSwitch={() => setUserSettings({ ...userSettings, mediaDisabled: !userSettings.mediaDisabled })}
          />
        </section>

        <section className="setting-select">
          <Select
            label="Mijn berichten"
            value={userSettings.myMsgAlignment}
            onChange={(event: object, value: string) => setUserSettings({ ...userSettings, myMsgAlignment: value })}
          >
            <MenuItem text="Rechts uitlijnen" value="right" />
            <MenuItem text="Links uitlijnen" value="left" />
          </Select>
        </section>

        <section className="setting-select">
          <Select
            label="Andermans berichten"
            value={userSettings.otherMsgAlignment}
            onChange={(event: object, value: string) => setUserSettings({ ...userSettings, otherMsgAlignment: value })}
          >
            <MenuItem text="Rechts uitlijnen" value="right" />
            <MenuItem text="Links uitlijnen" value="left" />
          </Select>
        </section>

        <br /><br />

        <h2>VIP</h2>
        <section className="setting-select">
          <Select
            label="Website font"
            value={"Standaard"}
            onChange={(event: object, value: string) => setUserSettings({ ...userSettings, font: value })}
          >
            <MenuItem text="Standaard" value="default" />
            <MenuItem text="Roboto" value="roboto" />
            <MenuItem text="Quicksand" value="quicksand" />
            <MenuItem text="Caveat" value="caveat" />
          </Select>
        </section>
      </section>
    </div>
  );
}

export default VisualsPage;