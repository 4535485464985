import { CometChat } from "@cometchat/chat-sdk-javascript";
import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';
import defaultAvatar from "./../../assets/default_avatar.jpg"
import Button from 'anchor-ui/button';
import { IconClose } from 'anchor-ui/icons';
import Divider from 'anchor-ui/divider';

const BlockedUsersPage = ({
  blockedUsers,
  handleBlockUser
}: {
  blockedUsers: CometChat.User[] | null,
  handleBlockUser: (userId: string) => void;
}) => {

  return (
    <div>
      <h2>Geblokkeerde gebruikers</h2>
      <p>Hier zie je de lijst van {blockedUsers && blockedUsers.length} gebruiker{blockedUsers ? (blockedUsers.length > 1 || blockedUsers.length === 0) && 's' : 's'} die je hebt geblokkeerd.</p>

      <div className='blocked-users-list'>
        <List>
          {blockedUsers ?
            blockedUsers.map((user, key) => {
              return (
                <div key={key}>
                  <ListItem
                    primaryText={user.getName()}
                    avatar={user.getAvatar() ?? defaultAvatar}
                    rightButton={
                      <Button iconButton onClick={() => handleBlockUser(user.getUid())}>
                        <IconClose />
                      </Button>
                    }
                  />
                  <Divider />
                </div>
              );
            }) : <p>No users blocked.</p>
          }
        </List>
      </div>
    </div>
  );
};

export default BlockedUsersPage;