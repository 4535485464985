import Profile from 'anchor-ui/profile';
import defaultAvatar from "./../../assets/default_avatar.jpg";
import Button from 'anchor-ui/button';
import Card from 'anchor-ui/card';
import CardHeader from 'anchor-ui/card-header';
import CardContent from 'anchor-ui/card-content';
import Divider from 'anchor-ui/divider';
import {
  IconConversation,
  IconAddFriend,
  IconRules,
  IconPerson,
  IconHeart,
  IconClose,
  IconBriefcase,
  IconHammer,
  IconHand,
  IconExit,
  IconAdmin,
  IconMute,
  IconVerified,
  IconReport,
} from 'anchor-ui/icons';
import { getActiveColor } from './../../utils/activeColor';
import './userProfile.css';
import { metadataInterface } from './../../interfaces/metadataInterface';
import ProfileDetail from './ProfileDetail';
import ToggleLeftMenuButton from '../ToggleLeftMenuButton';
import { ActiveChannelInterface } from '../../interfaces/activeChannelInterface';
import { checkIfUserHasPermissions } from '../../utils/checkIfUserIsAdmin';
import { useEffect, useState } from 'react';
import { fetchProfile } from '../../utils/updateMetadata';
import { Functions, httpsCallable } from 'firebase/functions';
import { getGenderFromMetadata } from '../../utils/getGenderFromMetadata';
import { getFullDateFromTimestamp } from '../getDateFromTimestamp';
import IconMenu from 'anchor-ui/icon-menu';
import MenuItem from 'anchor-ui/menu-item';
import { getAvatar } from '../../utils/getAvatar';
import ListItem from 'anchor-ui/list-item';
import Avatar from 'anchor-ui/avatar';
import { checkIfUserIsBlocked } from '../../utils/checkIfUserIsBlocked';
import { CometChat } from "@cometchat/chat-sdk-javascript";
import AdminBadge from 'anchor-ui/admin-badge';
import ImageDialog from '../ChatComponent/ImageDialog';
import { checkIfUserCanBeCalled } from '../../utils/CallFunctions';
import { checkUserHasSubscription } from '../../utils/checkSubscription';

const UserProfile = ({
  user,
  setCurrentChat,
  startPrivateChat,
  friends,
  hasSentFriendRequest,
  hasReceivedFriendRequest,
  handleAddOrRemoveFriend,

  leftMenuOpen,
  setLeftMenuOpen,
  unreadCount,

  handleBanUser,
  loggedInUserIsAdmin,
  setUserToWarn,

  handleLogoutKickUser,
  functions,
  setAlert,

  handleMuteUser,
  blockedUsers,
  handleInitiateCall,
}: {
  user: CometChat.User;
  setCurrentChat: (chat: any) => void;
  startPrivateChat: (user: CometChat.User) => void;
  friends: CometChat.User[];
  hasSentFriendRequest: (user: CometChat.User) => boolean;
  hasReceivedFriendRequest: (user: CometChat.User) => boolean;
  handleAddOrRemoveFriend: (friend: CometChat.User) => void;

  leftMenuOpen: boolean;
  setLeftMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  handleBanUser: (user: CometChat.User) => void;
  loggedInUserIsAdmin: boolean;
  setUserToWarn: (user: CometChat.User) => void;

  handleLogoutKickUser: (user: CometChat.User) => void;
  functions: Functions;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;

  handleMuteUser: (user: CometChat.User, isMuted: boolean) => void;
  blockedUsers: CometChat.User[];
  handleInitiateCall: (user: CometChat.User) => void;
}) => {
  const fetchBannerImgAsync = httpsCallable(functions, 'fetchProfileBannerImg');

  const [userProfile, setUserProfile] = useState<metadataInterface | null>(null);
  const [profileFriends, setProfileFriends] = useState<CometChat.User[]>([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [backgroundImg, setBackgroundImg] = useState<string | null>(null);
  const [isMuted, setIsMuted] = useState(false);

  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [selectedGalleryImage, setSelectedGalleryImage] = useState<string>("");

  const getProfileFromMetadata = (user: CometChat.User) => {
    const metadata = user.getMetadata() as unknown as metadataInterface;

    if (!metadata || typeof metadata === 'string') {
      return {
        about: {
          gender: 'unknown',
          birthDate: '01-01-1970',
        }
      }
    }

    setIsMuted(metadata.muted ?? false);

    return metadata;
  }

  const fetchBannerImg = async () => {
    const now = new Date().getTime();

    const oldData = localStorage.getItem("banner-img-" + user.getUid());

    // Check if data is older than 48 hours
    if (oldData && JSON.parse(oldData).lastFetched + 172800000 > now) {
      setBackgroundImg(JSON.parse(oldData).imageUrl);
      return;
    }

    const result = await fetchBannerImgAsync({ onBehalfOf: user.getUid() });

    const data = result.data as { imageUrl?: string };
    if (data.imageUrl) {
      setBackgroundImg(data.imageUrl as string);
      // Save data to local storage so we don't have to fetch it again for 48 hours
      localStorage.setItem(
        "banner-img-" + user.getUid(),
        JSON.stringify({ imageUrl: data.imageUrl, lastFetched: now })
      );
      return;
    }
  };

  const getProfileFriends = (friendData: any) => {
    let friendArray: CometChat.User[] = [];

    // make a unique user object for every user
    friendData.forEach((user: any) => {
      let userObj = new CometChat.User(user.uid);
      userObj.setName(user.name);
      userObj.setRole(user.role);
      userObj.setAvatar(user.avatar);
      userObj.setMetadata(user.metadata);
      friendArray.push(userObj);
    });

    // Sort the friends so that names are alphabetically ordered
    friendArray.sort((a, b) => a.getName().localeCompare(b.getName()));

    // Order friends so that mutual friends are at the top
    friendArray = friendArray.sort((a, b) => {
      if (friends.some(friend => friend.getUid() === a.getUid()) && friends.some(friend => friend.getUid() === b.getUid())) {
        return 0;
      } else if (friends.some(friend => friend.getUid() === a.getUid())) {
        return -1;
      } else {
        return 1;
      }
    });

    return friendArray;
  }

  // This function can only be used by admins
  const getUserData = async () => {
    try {
      const metadata = await fetchProfile(functions, user.getUid());

      const profile = getProfileFromMetadata(user);
      if (profile?.about.gender && metadata && metadata.about) {
        metadata.about.gender = profile.about.gender;
      }

      setUserProfile(metadata);
      setProfileFriends(getProfileFriends(metadata!.friends));

      if (!metadata) return;
      setIsPrivate(metadata.settings?.profileIsPrivate ?? false);
    } catch (error) {
      // setAlert({ message: 'Er is iets misgegaan bij het ophalen van de gebruikersgegevens', type: 'error' });
      console.error("Error fetching user data:", error);
    }
  }

  useEffect(() => {
    // Only fetch user data from firestore if the logged in user is an admin
    if (loggedInUserIsAdmin) {
      getUserData();
      return;
    }

    const metadata = getProfileFromMetadata(user);
    setUserProfile(metadata);
    setIsPrivate(metadata.settings?.profileIsPrivate ?? false);

    // Fetch banner image if profile is not private
    if (!metadata.settings?.profileIsPrivate) {
      fetchBannerImg();
    }
  }, [user]);

  const closeProfile = () => {
    setCurrentChat((prevState: any) => {
      return {
        ...prevState,
        userProfile: null,
      }
    });
  };

  const showUserProfile = (user: CometChat.User) => {
    setCurrentChat((prev: any) => ({ ...prev, userProfile: user }));
  }

  const checkIfMutualFriend = (friend: CometChat.User) => {
    return friends.some(f => f.getUid() === friend.getUid());
  }

  const handleSelectImage = (imageUrl: string) => {
    setSelectedGalleryImage(imageUrl);
    setImageDialogOpen(true);
  };

  const userIsFriend = friends.some(friend => friend.getUid() === user.getUid());
  const userIsAdmin = checkIfUserHasPermissions(user);

  return (
    <>
      <ImageDialog
        isOpen={imageDialogOpen}
        setIsOpen={setImageDialogOpen}
        imageUrl={selectedGalleryImage}
      />

      <Profile
        header={user.getName() + `${loggedInUserIsAdmin && isPrivate ? " [privé]" : ""}`}
        avatar={user.getAvatar() ?? defaultAvatar}
        coverImage={backgroundImg ?? user.getAvatar() ?? defaultAvatar}
        coverImageStyle={{ backgroundColor: getActiveColor() }}
        status={user.getStatus()}
        style={{
          width: "100%",
          backgroundColor: "rgb(246, 246, 246)",
          height: "280px"
        }}
        avatarStyle={{
          width: '156px',
          height: '156px',
          left: '102px',
        }}
        headerStyle={{
          width: 'auto',
          position: 'absolute',
          textAlign: 'left',
          left: '196px',
          top: '200px'
        }}
        secondaryTextStyle={{
          width: 'auto',
          position: 'absolute',
          textAlign: 'left',
          left: '196px',
          top: '254px'
        }}
        button={
          <>
            <ToggleLeftMenuButton
              leftMenuOpen={leftMenuOpen}
              setLeftMenuOpen={setLeftMenuOpen}
              unreadCount={unreadCount}
              isProfile
            />

            <Button
              iconButton
              style={{
                fontSize: '16px',
                position: 'absolute',
                top: '8px',
                right: '8px',
                backgroundColor: 'rgba(21, 21, 21, 0.2)'
              }}
              onClick={closeProfile}
            >
              <IconClose color='white' />
            </Button>

            {(!isPrivate || userIsFriend || loggedInUserIsAdmin) &&
              <div className="profile-buttons">
                {(loggedInUserIsAdmin && !userIsAdmin) && (
                  <>
                    <IconMenu icon={<IconAdmin />}>
                      <>
                        {
                          <>
                            <MenuItem
                              text="Stuur waarschuwing"
                              icon={<IconHand color={getActiveColor()} />}
                              onClick={() => setUserToWarn(user)}
                            />
                            <MenuItem
                              text={`${isMuted ? "Unm" : "M"}ute gebruiker`}
                              icon={<IconMute color={getActiveColor()} />}
                              onClick={() => handleMuteUser(user, isMuted)}
                            />
                            <MenuItem
                              text="Harde kick"
                              icon={<IconExit color={getActiveColor()} />}
                              onClick={() => handleLogoutKickUser(user)}
                            />
                            <MenuItem
                              text="Ban gebruiker"
                              icon={<IconHammer color={getActiveColor()} />}
                              onClick={() => handleBanUser(user)}
                            />
                          </>
                        }
                      </>
                    </IconMenu>
                  </>
                )}
                <Button
                  flatButton
                  style={{
                    color: getActiveColor(),
                    fontSize: '16px',
                    fontWeight: 'bolder',
                    display: 'flex',
                    alignItems: 'center',
                    textTransform: 'none',
                  }}
                  onClick={() => startPrivateChat(user)}
                >
                  <IconConversation color={getActiveColor()} />
                  <span style={{ marginLeft: '8px' }}>Open gesprek</span>
                </Button>

                {(!hasSentFriendRequest(user) && !hasReceivedFriendRequest(user)) &&
                  <Button
                    flatButton
                    style={{
                      color: 'rgb(196, 196, 196)',
                      fontSize: '16px',
                      fontWeight: 'bolder',
                      display: 'flex',
                      alignItems: 'center',
                      textTransform: 'none'
                    }}
                    onClick={() => handleAddOrRemoveFriend(user)}
                  >
                    <IconAddFriend color={getActiveColor()} />
                    <span style={{ marginLeft: '8px', color: getActiveColor(), }}>
                      {userIsFriend ? 'Vriend verwijderen' : 'Vriend toevoegen'}
                    </span>
                  </Button>
                }

                {
                  checkIfUserCanBeCalled(user, true) ? (
                    <Button
                      flatButton
                      style={{
                        color: getActiveColor(),
                        fontSize: '16px',
                        fontWeight: 'bolder',
                        display: 'flex',
                        alignItems: 'center',
                        textTransform: 'none',
                      }}
                      onClick={() => handleInitiateCall(user)}
                    >
                      <IconReport color={getActiveColor()} />
                      <span style={{ marginLeft: '8px' }}>Start audio of videogesprek</span>
                    </Button>
                  ) : <></>
                }
              </div>
            }
          </>
        }
      />

      <div className={`profile-flex ${userProfile?.gallery && userProfile.gallery.length > 0 ? 'profile-gallery-container' : ''}`}>
        {userProfile && (
          <div className="profile-card-container profile-card-section">

            {loggedInUserIsAdmin && userProfile.adminInfo && (
              <Card style={{ marginTop: '8px', marginBottom: '8px', backgroundColor: 'white' }}>
                <CardHeader
                  title={
                    <div className="profile-card-header">
                      <span
                        className="profile-card-icon"
                        style={{
                          backgroundColor: getActiveColor()
                        }}
                      >
                        <IconAdmin color="white" />
                      </span>
                      <span className="profile-card-title">Account informatie</span>
                    </div>
                  }
                  style={{ paddingBottom: 0 }}
                />
                <CardContent style={{ paddingTop: '8px' }} className="profile-text">
                  <ProfileDetail label="Account aangemaakt" value={getFullDateFromTimestamp(userProfile.adminInfo.createdAt)} />
                  <ProfileDetail label="Laatst online" value={getFullDateFromTimestamp(userProfile.adminInfo.lastActiveAt)} />
                  <ProfileDetail label="Laatst geupdate" value={getFullDateFromTimestamp(userProfile.adminInfo.updatedAt)} />
                  <ProfileDetail label="IP" value={userProfile.ip} />
                  <ProfileDetail label="Email" value={userProfile.adminInfo.email} />
                </CardContent>
              </Card>
            )}

            <Card style={{ backgroundColor: 'white' }}>
              {(isPrivate && !loggedInUserIsAdmin) ? <CardHeader title={'Dit profiel is privé.'} /> :
                <>
                  <CardHeader
                    title={
                      <div className="profile-card-header">
                        <span
                          className="profile-card-icon"
                          style={{
                            backgroundColor: getActiveColor()
                          }}
                        >
                          <IconRules color="white" />
                        </span>
                        <span className="profile-card-title">Over</span>
                      </div>
                    }
                    style={{ paddingBottom: 0 }}
                  />

                  <CardContent style={{ paddingTop: '8px' }}>
                    {userProfile.about.aboutText &&
                      <>
                        <span className="profile-text">
                          {userProfile.about.aboutText}
                        </span>
                        <Divider style={{ marginTop: '12px', marginBottom: '8px' }} />
                      </>
                    }

                    <div className="profile-detail">
                      {getGenderFromMetadata(user)}
                      <p style={{ margin: 0, marginLeft: '8px' }}>{userProfile.about.gender}</p>
                    </div>

                    {userProfile.about.relationshipStatus &&
                      <div className="profile-detail">
                        <IconHeart />
                        <p style={{ margin: 0, marginLeft: '8px' }}>{userProfile.about.relationshipStatus}</p>
                      </div>
                    }

                    {
                      userProfile.about.function &&
                      <div className="profile-detail">
                        <IconBriefcase />
                        <p style={{ margin: 0, marginLeft: '8px' }}>{userProfile.about.function}</p>
                      </div>
                    }
                  </CardContent>
                </>
              }
            </Card>

            {((userProfile.appearance && !isPrivate) || (userProfile.appearance && loggedInUserIsAdmin)) &&
              <Card style={{ marginTop: '8px', backgroundColor: 'white' }}>
                <CardHeader
                  title={
                    <div className="profile-card-header">
                      <span
                        className="profile-card-icon"
                        style={{
                          backgroundColor: getActiveColor()
                        }}
                      >
                        <IconPerson color="white" />
                      </span>
                      <span className="profile-card-title">Uiterlijk</span>
                    </div>
                  }
                  style={{ paddingBottom: 0 }}
                />
                <CardContent style={{ paddingTop: '8px' }}>
                  <ProfileDetail label="Bouw" value={userProfile.appearance.build} />
                  <ProfileDetail label="Kleur haar" value={userProfile.appearance.hairColor} />
                  <ProfileDetail label="Kleur ogen" value={userProfile.appearance.eyeColor} />
                  <ProfileDetail label="Piercings" value={userProfile.appearance.piercings} />
                  <ProfileDetail label="Tattoos" value={userProfile.appearance.tattoos} />
                </CardContent>
              </Card>
            }

            {(userProfile.favoriteChannels && userProfile.favoriteChannels.length > 0 && !isPrivate) &&
              <Card style={{ marginTop: '8px', backgroundColor: 'white' }}>
                <CardHeader
                  title={
                    <div className="profile-card-header">
                      <span
                        className="profile-card-icon"
                        style={{
                          backgroundColor: getActiveColor()
                        }}
                      >
                        <IconPerson color="white" />
                      </span>
                      <span className="profile-card-title">Favoriete kanalen</span>
                    </div>
                  }
                  style={{ paddingBottom: 0 }}
                />
                <CardContent style={{ paddingTop: '8px' }}>
                  {userProfile.favoriteChannels.map((channel, index) => (
                    <ListItem
                      primaryText={channel}
                      avatar={
                        <Avatar
                          image={getAvatar(channel)}
                          style={{ backgroundColor: getActiveColor() }}
                        />
                      }
                    />
                  ))}
                </CardContent>
              </Card>
            }
          </div>
        )}

        <div className="profile-card-container profile-card-section profile-friends">
          {(profileFriends && profileFriends.length > 0 && !isPrivate) &&
            <Card style={{ marginTop: '8px', backgroundColor: 'white' }}>
              <CardHeader
                title={
                  <div className="profile-card-header">
                    <span
                      className="profile-card-icon"
                      style={{
                        backgroundColor: getActiveColor()
                      }}
                    >
                      <IconPerson color="white" />
                    </span>
                    <span className="profile-card-title">Vrienden ({profileFriends.length})</span>
                  </div>
                }
                style={{ paddingBottom: 0 }}
              />
              <CardContent style={{ paddingTop: '8px' }}>
                {profileFriends.map((friend: CometChat.User, index) => (
                  <ListItem
                    key={index}
                    primaryText={friend.getName()}
                    secondaryText={getGenderFromMetadata(friend)}
                    avatar={
                      <Avatar
                        image={friend.getAvatar() ?? defaultAvatar}
                        style={{ backgroundColor: getActiveColor() }}
                      />
                    }
                    textBadge={
                      <>
                        {(friend.getRole().includes("admin") || friend.getRole().includes("moderator")) &&
                          <AdminBadge text={friend.getRole().includes("admin") ? "Admin" : "Mod"} style={{ marginRight: "4px" }} />
                        }
                        {checkIfMutualFriend(friend) &&
                          <AdminBadge text="Vriend" />
                        }
                      </>
                    }
                    blocked={checkIfUserIsBlocked(blockedUsers, friend.getUid())}
                    onClick={() => showUserProfile(friend)}
                  />
                ))}
              </CardContent>
            </Card>
          }
        </div>

        <div className="profile-card-container profile-card-section profile-gallery">
          {(userProfile &&
            checkUserHasSubscription(user.getRole() ?? "") &&
            userProfile.gallery &&
            userProfile.gallery.length > 0 &&
            !isPrivate) &&
            <Card style={{ marginTop: '8px', backgroundColor: 'white' }}>
              <CardHeader
                title={
                  <div className="profile-card-header">
                    <span
                      className="profile-card-icon"
                      style={{
                        backgroundColor: getActiveColor()
                      }}
                    >
                      <IconVerified color="white" />
                    </span>
                    <span className="profile-card-title" style={{ color: getActiveColor() }}>Galerij</span>
                  </div>
                }
                style={{ paddingBottom: 0 }}
              />
              <CardContent style={{ paddingTop: '8px' }}>
                <div className="profile-gallery">
                  {userProfile.gallery.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`gallery-image-${index}`}
                      onClick={() => handleSelectImage(image)}
                    />
                  ))}
                </div>
              </CardContent>
            </Card>
          }
        </div>
      </div>
    </>
  );
}

export default UserProfile;