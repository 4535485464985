import Avatar from 'anchor-ui/avatar';
import { checkIfUserIsBlocked } from '../../../utils/checkIfUserIsBlocked';
import CheckConversationButton from './CheckConversationButton';
import RemoveConversationButton from './RemoveConversationButton';
import { IconBlock } from "anchor-ui/icons";

const ConversationListItem = ({
	getPrimaryText,
	formatDate,
	isGroup,
	conv,
	getConversationWith,
	getLastMessageText,
	getIcon,
	getUserAvatar,
	userSettings,
	handleClick,
	conversation,
	deleteMultipleConversationsMode,
	messageToForward,

	conversationsToDelete,
	conversationsToForwardTo,

	unreadMessages,
	blockedUsers,

	handleRemoveConversation,

	isSideNav,
	handleInitiateCall,
}: {
	getPrimaryText: (name: string) => string;
	formatDate: (timestamp: number) => string;
	isGroup: boolean;
	conv: any;
	getConversationWith: (conv: any) => any;
	getLastMessageText: (conversation: any) => string;
	getIcon: (conv: any) => string;
	getUserAvatar: (conv: any) => string;
	userSettings: any;
	handleClick: (conv: any, isGroup: boolean) => void;
	conversation: any;
	deleteMultipleConversationsMode: boolean;
	messageToForward: any;

	conversationsToDelete: any;
	conversationsToForwardTo: any;

	unreadMessages: number | undefined;
	blockedUsers: any;

	handleRemoveConversation: (conv: any) => void;

	isSideNav?: boolean;
	handleInitiateCall: (conv: any) => void;
}) => {
	const primaryText = getPrimaryText(isGroup ? conv.name : getConversationWith(conv).getName());
	const secondaryText = getLastMessageText(conversation);
	const avatar = isGroup ? getIcon(conv) : getUserAvatar(conv);
	const unreadBadge = unreadMessages ? <div className="badge-unread">{unreadMessages}</div> : null;
	const blocked = !isGroup && checkIfUserIsBlocked(blockedUsers, getConversationWith(conv).getUid());

	const rightButton = deleteMultipleConversationsMode || messageToForward ? (
		<CheckConversationButton
			conversation={conv}
			handleClick={() => handleClick(conv, isGroup)}
			conversationsToDelete={conversationsToDelete}
			conversationsToForwardTo={conversationsToForwardTo}
		/>
	) : (
		<RemoveConversationButton
			conversation={conv}
			handleRemoveConversation={handleRemoveConversation}
			conversationName={primaryText}
			handleInitiateCall={handleInitiateCall}
		/>
	);

	if (blocked) return null;

	return (
		<section
			onClick={() => handleClick(conv, isGroup)}
			className="conversationlistitem"
			style={conversationsToDelete.includes(conv) || (messageToForward && conversationsToForwardTo.includes(conv)) ? { backgroundColor: userSettings.themeColor, color: "white" } : {}}
		>
			<div className="conversationlistitem-avatar">
				<Avatar
					image={blocked ? <IconBlock /> : avatar}
					style={{ backgroundColor: userSettings.themeColor }}
					status={!isGroup && getConversationWith(conv).getStatus()}
				/>
				{unreadBadge}
			</div>
			<div className="conversationlistitem-text">
				<h1 className="conversationlistitem-primary-text">{primaryText}</h1>
				<h2
					className="conversationlistitem-secondary-text"
					style={conversationsToDelete.includes(conv) || (messageToForward && conversationsToForwardTo.includes(conv)) ? { color: "white" } : {}}
				>
					{secondaryText}
				</h2>
			</div>

			<span className="conversationlistitem-date">
				{formatDate(conversation.lastMsgTimestamp)}
			</span>
			<div className={`conversationlistitem-right-button ${isSideNav || conversationsToDelete.includes(conv) || (messageToForward && conversationsToForwardTo.includes(conv)) ? "mobile" : ""}`}>
				{rightButton}
			</div>
		</section>
	);
};

export default ConversationListItem;
