
import Dialog from 'anchor-ui/dialog';
import Button from 'anchor-ui/button';

const NeedSubscriptionDialog = ({
  isOpen,
  handleClose,
  message,
  setCurrentChat,
}: {
  isOpen: boolean;
  handleClose: () => void;
  message: React.ReactNode;
  setCurrentChat: (chat: any) => void;
}) => {

  const handleViewSubscriptions = () => {
    setCurrentChat({ id: '', name: '', icon: '', isGroup: false, showSubscriptionPage: true });
    handleClose();
  }

  return (
    <Dialog
      open={isOpen}
      header={
        <>
          Geen toegang 🔒<br />
          <hr />
          <small>🎖️ Upgrade en haal het maximale uit ChatPlaza 🔓</small>
        </>
      }
      hideDialog={handleClose}
      headerStyle={{ color: 'rgb(117, 117, 117)', fontSize: '24px', fontWeight: 'bolder', textAlign: 'center' }}
      style={{ backgroundColor: 'white', maxWidth: "400px" }}
      iconColor={'rgba(117, 117, 117, 0.5)'}
    >
      <section className='logout-dialog'>
        <p>
          {message}
        </p>
        <br />
        <div>
          <Button
            onClick={handleClose}
            style={{ width: 'auto' }}
            inverted
          >
            Sluit
          </Button>
        &nbsp;&nbsp;
          <Button
            onClick={handleViewSubscriptions}
            style={{ width: 'auto' }}
          >
            Bekijk Abonnementen ➡️
          </Button>
        </div>
      </section>
    </Dialog>
  );
};

export default NeedSubscriptionDialog;