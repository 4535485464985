
export const getFontSizeStyle = (fontSize: string) => {
  switch (fontSize) {
    case 'font-medium':
      return {
        fontSize: '1.25em',
        lineHeight: '1.5em',
      };
    case 'font-large':
      return {
        fontSize: '1.5rem',
        lineHeight: '1.15em',
      };
    default:
      return {
        fontSize: '1rem',
      };
  }
}