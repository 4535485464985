import { Functions, httpsCallable } from "firebase/functions";
import { metadataInterface } from "../interfaces/metadataInterface";
import { CometChat } from "@cometchat/chat-sdk-javascript";

export const fetchProfile = async (functions: Functions, uid: string): Promise<metadataInterface | null> => {
  const fetchUserProfile = httpsCallable(functions, 'fetchUserProfile');
  const userProfileResult = await fetchUserProfile({ uid });
  const userProfile = userProfileResult.data as unknown as metadataInterface;

  return userProfile;
}

export const getProfileFromMetadata = (user: CometChat.User) => {
  const metadata = user.getMetadata() as unknown as metadataInterface;

  if (metadata === undefined) {
    return {
      about: {
        gender: 'unknown',
        birthDate: 'unknown',
      }
    }
  };

  if (!metadata || typeof metadata === 'string') {
    const jsonParsedMetadata = JSON.parse(metadata as string) as metadataInterface;
    return jsonParsedMetadata;
  }

  return metadata;
}

export const updateUserMetadata = async (functions: Functions, metadata: metadataInterface, user: CometChat.User): Promise<metadataInterface | boolean> => {
  try {
    const putMetadata = httpsCallable(functions, 'putUserMetadata');
    await putMetadata({ metadata });
    user.setMetadata(metadata);

    // Delete the ip
    delete metadata.ip;
    // Delete the birthdate
    delete metadata.about.birthDate;
    await CometChat.updateCurrentUserDetails(user);

    return metadata;
  } catch (error) {
    console.error("error", error);
    return false;
  }
};

export const updateGallery = async (user: CometChat.User, galleryImages: string[]) => {
  const metadata = getProfileFromMetadata(user);
  metadata.gallery = galleryImages;
  // Delete the ip
  delete metadata.ip;
  user.setMetadata(metadata);
  await CometChat.updateCurrentUserDetails(user);
};

export const toggleUserPrivacy = async (functions: Functions, user: CometChat.User, profileIsPrivate: boolean): Promise<boolean> => {
  try {
    const togglePrivacy = httpsCallable(functions, 'togglePrivacy');
    await togglePrivacy({ profileIsPrivate });

    const metadata = getProfileFromMetadata(user);
    metadata.settings = {
      profileIsPrivate: profileIsPrivate,
    }

    // Remove private fields from metadata if profile is private
    if (metadata.settings?.profileIsPrivate) {
      delete metadata.about.aboutText;
      delete metadata.about.birthDate;
      delete metadata.about.function;
      delete metadata.about.relationshipStatus;
      delete metadata.appearance;
    }
    // Delete the ip
    delete metadata.ip;
    user.setMetadata(metadata);
    await CometChat.updateCurrentUserDetails(user);

    return true;
  } catch (error) {
    console.error("error", error);
    return false;
  }
}
