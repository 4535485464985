import { CometChat } from "@cometchat/chat-sdk-javascript";
import { useState } from "react";
import "./styles.css";

import Modal from "anchor-ui/modal";
import Button from "anchor-ui/button";
import Input from "anchor-ui/input";
import Select from "anchor-ui/select";
import MenuItem from "anchor-ui/menu-item";

interface BugReportModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string } | null>>;
}

const BugReportModal = ({ isOpen, setIsOpen, setAlert }: BugReportModalProps) => {
  const [bugLocation, setBugLocation] = useState<string>("Publiek kanaal");
  const [bugDescription, setBugDescription] = useState<string>("");
  const [stepsToReproduce, setStepsToReproduce] = useState<string>("");
  const [device, setDevice] = useState<string>("");
  const [browserAndVersion, setBrowserAndVersion] = useState<string>("");
  const [screenshot, setScreenshot] = useState<File | null>(null);

  const handleClose = (): void => {
    setBugLocation("Publiek kanaal");
    setBugDescription("");
    setStepsToReproduce("");
    setDevice("");
    setBrowserAndVersion("");
    setScreenshot(null);
    setIsOpen(false);
  };

  const sendMessage = (message: CometChat.TextMessage | CometChat.MediaMessage): void => {
    const messageType = message instanceof CometChat.MediaMessage ? "Afbeelding" : "Tekstbericht";
    CometChat.sendMessage(message).then(
      () => {
        setAlert({ message: "Melding verstuurd", type: "success" });
        handleClose();
      },
      (error: unknown) => {
        console.error(`${messageType} sending failed with error:`, error);
        setAlert({ message: `${messageType} kon niet worden verzonden`, type: "error" });
      }
    );
  };

  const handleSendBugReport = (): void => {
    if (!bugDescription || !stepsToReproduce || !device || !browserAndVersion) {
      setAlert({ message: "Vul alle velden in", type: "error" });
      return;
    }

    const metadata = {
      bugLocation,
      bugDescription,
      stepsToReproduce,
      device,
      browserAndVersion
    };

    const receiverId = "bcntjoo2noxijfvu10akwpckxx42"; // Receiver ID (user: ProbleemRapportage)

    if (screenshot) {
      const mediaMessage = new CometChat.MediaMessage(
        receiverId,
        screenshot,
        CometChat.MESSAGE_TYPE.IMAGE,
        CometChat.RECEIVER_TYPE.USER
      );
      mediaMessage.setMetadata({ bugReport: metadata });
      sendMessage(mediaMessage);
    } else {
      const textMessage = new CometChat.TextMessage(receiverId, "bugreport", CometChat.RECEIVER_TYPE.USER);
      textMessage.setMetadata({ bugReport: metadata });
      sendMessage(textMessage);
    }
  };

  return (
    <Modal
      className="bug-report-modal"
      open={isOpen}
      actions={
        <>
          <Button flatButton onClick={handleClose}>ANNULEER</Button>
          <Button flatButton onClick={handleSendBugReport}>VERSTUUR</Button>
        </>
      }
      headerStyle={{ fontSize: "16px" }}
    >
      <Select
        label={"Waar trad het probleem op?"}
        onChange={(event: object, value: string) => setBugLocation(value)}
        value={bugLocation}
        style={{ marginBottom: "12.5px" }}
      >
        <MenuItem text={"Publiek kanaal"} value={"Publiek kanaal"} />
        <MenuItem text={"Privé kanaal"} value={"Privé kanaal"} />
        <MenuItem text={"Privé groepsgesprek"} value={"Privé groepsgesprek"} />
        <MenuItem text={"Anders"} value={"Anders"} />
      </Select>

      <Input
        name={"bugDescription"}
        label={"Beschrijving van het probleem in detail"}
        value={bugDescription}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setBugDescription(event.target.value)}
        maxLength={500}
        style={{ maxWidth: "100%", marginBottom: "12.5px" }}
        multiLine={true}
        maxRows={8}
      />

      <Input
        name={"stepsToReproduce"}
        label={"Hoe gebeurde dit? (Wees specifiek)"}
        value={stepsToReproduce}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setStepsToReproduce(event.target.value)}
        maxLength={500}
        style={{ maxWidth: "100%", marginBottom: "12.5px" }}
        multiLine={true}
        maxRows={8}
      />

      <Input
        name={"device"}
        label={"Apparaat"}
        value={device}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDevice(event.target.value)}
        maxLength={500}
        style={{ maxWidth: "100%", marginBottom: "12.5px" }}
      />

      <Input
        name={"browserAndVersion"}
        label={"Browser en versie"}
        value={browserAndVersion}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setBrowserAndVersion(event.target.value)}
        maxLength={500}
        style={{ maxWidth: "100%", marginBottom: "12.5px" }}
      />

      <Input
        type="file"
        label={"Screenshot van het probleem (optioneel)"}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setScreenshot(event.target.files ? event.target.files[0] : null)}
        style={{ marginBottom: "12.5px" }}
      />
    </Modal>
  );
};

export default BugReportModal;
