import { CometChat } from "@cometchat/chat-sdk-javascript";
import Modal from 'anchor-ui/modal';
import Button from 'anchor-ui/button';
import { getAuth } from 'firebase/auth';
import { Functions, httpsCallable } from 'firebase/functions';

const DeleteAccountModal = ({
  isOpen,
  setIsOpen,
  setAlert,
  functions,
  handleLogout,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  functions: Functions;
  handleLogout: () => void;
}) => {

  const auth = getAuth();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDeleteAccount = async () => {
    const deleteAccount = httpsCallable(functions, 'deleteAccount');

    try {
      await deleteAccount();
      setAlert({ message: 'Je account is succesvol verwijderd.', type: 'success' });
      handleLogout();
    } catch (error) {
      console.error('Error deleting account:', error);
      setAlert({ message: 'Er is iets misgegaan bij het verwijderen van je account. Probeer het later opnieuw.', type: 'error' });
    } finally {
      handleClose();
    }
  };

  return (
    <Modal
      header='Verwijder account'
      open={isOpen}
      actions={
        <>
          <Button flatButton onClick={handleClose}>ANNULEER</Button>
          <Button flatButton onClick={handleDeleteAccount}>VERWIJDER ACCOUNT</Button>
        </>
      }
      headerStyle={{ fontSize: '16px' }}
    >
      Weet je zeker dat je je account wil verwijderen? Dit is permanent en kan niet worden teruggedraaid.
    </Modal>
  );
};

export default DeleteAccountModal;