import Modal from "anchor-ui/modal";
import Button from "anchor-ui/button";
import Input from "anchor-ui/input";
import { useState } from "react";
import { Functions, httpsCallable } from "firebase/functions";
import Select from "anchor-ui/select";
import MenuItem from "anchor-ui/menu-item";
import { checkIfUserIsAdmin } from "../../utils/checkIfUserIsAdmin";

const BanUserModal = ({
  userToBan,
  setUserToBan,
  setAlert,
  functions,
  loggedInUser,
}: {
  userToBan: CometChat.User | null;
  setUserToBan: (userToBan: CometChat.User | null) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  functions: Functions;
  loggedInUser: CometChat.User;
}) => {
  const [banReason, setBanReason] = useState("");
  const [banDuration, setBanDuration] = useState("24h");
  const [banType, setBanType] = useState("account");

  const userIsAdmin = checkIfUserIsAdmin(loggedInUser);

  const handleClose = () => {
    setBanReason("");
    setBanDuration("24h");
    setBanType("account");
    setUserToBan(null);
  };

  const handleBan = async () => {
    if (!userToBan || !banReason) return;

    // Ban reason should be at least 10 characters and max 500 characters
    if (banReason.length < 10) {
      setAlert({ message: "Ban reden moet minimaal 10 karakters bevatten", type: "error" });
      return;
    }

    const banUser = httpsCallable(functions, 'banUser');

    try {
      await banUser({ uid: userToBan.getUid(), banReason, banDuration, banType });
      setAlert({ message: `Gebruiker ${userToBan.getName()} is verbannen`, type: 'success' });

      handleClose();
    } catch {
      setAlert({ message: `Er is iets misgegaan bij het verbannen van ${userToBan.getName()}`, type: 'error' });
    }
  };

  const handleChangeDuration = (event: object, value: string) => {
    if (value === "permanent" || value === "1m") setBanType("account");
    setBanDuration(value);
  };

  if (!userToBan) return <></>;

  return (
    <Modal
      open={userToBan !== null}
      actions={
        <>
          <Button flatButton onClick={handleClose}>ANNULEER</Button>
          <Button flatButton onClick={handleBan}>BAN</Button>
        </>
      }
      headerStyle={{ fontSize: "16px" }}
    >
      <Input
        name={"extraInfo"}
        label={`[${userToBan.getName()}] Ban reden`}
        value={banReason}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setBanReason(event.target.value)}
        maxLength={500}
        style={{ maxWidth: "100%", marginBottom: "12.5px" }}
      />

      <Select
        label={"Duratie"}
        onChange={(event: object, value: string) => handleChangeDuration(event, value)}
        value={banDuration}
        style={{ marginBottom: "12.5px" }}
      >
        <MenuItem text={"24 uur"} value={"24h"} />
        <MenuItem text={"48 uur"} value={"48h"} />

        {userIsAdmin && <MenuItem text={"1 week"} value={"1w"} />}
        {userIsAdmin && <MenuItem text={"1 maand"} value={"1m"} />}
        {userIsAdmin && <MenuItem text={"Permanent"} value={"permanent"} />}
      </Select>

      {
        (banDuration !== "permanent" && banDuration !== "1m") ? (
          <Select
            label={"Type ban"}
            onChange={(event: object, value: string) => setBanType(value)}
            value={banType}
            style={{ marginBottom: "12.5px" }}
          >
            <MenuItem text={"Account ban"} value={"account"} />
            <MenuItem text={"IP ban"} value={"ip"} />
          </Select>
        ) : <small><i>Ban te lang voor IP-ban</i></small>
      }
    </Modal>
  );
};

export default BanUserModal;
