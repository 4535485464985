import Button from 'anchor-ui/button';
import Input from 'anchor-ui/input';
import { SetStateAction, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from 'anchor-ui/card';
import CardHeader from 'anchor-ui/card-header';
import ChatPlazaLogo from '../../assets/chatplaza-logo.svg';
import { User, Auth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';

const TwoFactorAuthPage = ({
  setAlert,
  loggedInUser,
  setLoggedInUser,
  handleCometChatLogin,
  loadChannels,
  userHas2FA,
  auth,
}: {
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  loggedInUser: {
    firebaseUser: User | null;
    cometChatUser: CometChat.User | null;
  } | null;
  setLoggedInUser: React.Dispatch<React.SetStateAction<{
    firebaseUser: User | null;
    cometChatUser: CometChat.User | null;
  } | null>>;
  handleCometChatLogin: () => Promise<CometChat.User>;
  loadChannels: () => void;
  userHas2FA: boolean;
  auth: Auth;
}) => {
  const navigate = useNavigate();
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>("");

  // Ref for the reCAPTCHA container
  const recaptchaRef = useRef<HTMLDivElement>(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(null);

  useEffect(() => {
    if ((!loggedInUser?.firebaseUser || typeof loggedInUser.cometChatUser?.getName !== "function") && !userHas2FA) {
      navigate("/login");
      return;
    }

    if (loggedInUser?.cometChatUser) {
      loadChannels();
      navigate("/chat");
      return;
    }

    if (recaptchaRef.current && !recaptchaVerifier) {
      const verifier = new RecaptchaVerifier(auth, recaptchaRef.current, {
        size: "invisible", // Visible reCAPTCHA
        'expired-callback': () => {
        },
        'error-callback': () => {
        },
      });

      verifier.render().then(() => {
        setRecaptchaVerifier(verifier); // Set the verifier after rendering
      }).catch((error) => {
        console.error("Error rendering reCAPTCHA widget:", error);
      });
    }
  }, [loggedInUser, navigate, auth, recaptchaVerifier]);

  // This function sends the verification code to the user's phone number
  const handleSendCode = async () => {
    try {
      const phoneNumber = loggedInUser?.firebaseUser?.phoneNumber;

      if (!phoneNumber) {
        setAlert({ message: "Geen telefoonnummer gevonden", type: "error" });
        return;
      }

      if (!recaptchaVerifier) {
        setAlert({ message: "reCAPTCHA verifier is not initialized", type: "error" });
        return;
      }

      await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
        .then((confirmationResult) => {
          (window as any).confirmationResult = confirmationResult;
          setCodeSent(true);
        });
    } catch (error) {
      console.error("Error sending code:", error);

      if ((error as any).code === "auth/too-many-requests") {
        setAlert({ message: "Te veel verzoeken. Probeer het later opnieuw.", type: "error" });
      } else {
        setAlert({ message: (error as any).message, type: "error" });
      }
    }
  };

  // This function verifies the code entered by the user
  const handleVerifyCode = async () => {
    try {
      const confirmationResult = (window as any).confirmationResult;

      if (!confirmationResult) {
        console.error("No confirmation result found");
        setAlert({ message: "Geen bevestigingsresultaat gevonden", type: "error" });
        return;
      }

      await confirmationResult.confirm(verificationCode);

      const cometChatUser = await handleCometChatLogin();
      if (cometChatUser.getUid() === loggedInUser?.firebaseUser?.uid.toLowerCase()) {
        setLoggedInUser({ ...loggedInUser, cometChatUser });
      }

    } catch (error) {
      console.error("Error verifying code:", error);
      setAlert({ message: (error as any).message, type: "error" });
    }
  };

  // This function triggers the reCAPTCHA verification
  const handleStartVerification = async () => {
    if (!recaptchaVerifier) {
      console.error("reCAPTCHA verifier is not initialized");
      setAlert({ message: "reCAPTCHA verifier is not initialized", type: "error" });
      return;
    }

    try {
      // Get the reCAPTCHA response
      const recaptchaResponse = await recaptchaVerifier.verify();

      // If reCAPTCHA is solved, send the code
      if (recaptchaResponse) {
        handleSendCode();
      }
    } catch (error) {
      console.error("Error triggering reCAPTCHA verification:", error);
      setAlert({ message: (error as any).message, type: "error" });
    }
  };

  // This function logs out the user from Firebase
  const handleFirebaseLogout = () => {
    auth.signOut().then(() => {
      setLoggedInUser(null);
      navigate("/login");
    });
  }

  return (
    <section className="action-page">
      <Card className="action-card">
        <img src={ChatPlazaLogo} className='logo' />

        {!codeSent ? (
          <>
            <CardHeader title="Code verzenden..." style={{ alignSelf: "center" }} />
            <div className="action-buttons">
              <Button inverted onClick={handleFirebaseLogout}>Log Uit</Button>
              <Button onClick={handleStartVerification}>Start Verification</Button>
            </div>
          </>
        ) : (
          <>
            <CardHeader title="Er is een code verstuurd" style={{ alignSelf: "center" }} />

            <Input
              label="Verificatie code"
              type="text"
              name="verificationCode"
              onChange={(e: { target: { value: SetStateAction<string> } }) => setVerificationCode(e.target.value)}
              required
              maxLength={6}
            />
            <div className="action-buttons">
              <Button inverted onClick={handleFirebaseLogout}>Log Uit</Button>
              <Button onClick={handleVerifyCode}>
                Bewaar
              </Button>
            </div>
          </>
        )}
        {/* Visible reCAPTCHA container */}
        <div ref={recaptchaRef}></div>
      </Card>
    </section>
  );
};

export default TwoFactorAuthPage;
