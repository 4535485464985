import Dialog from 'anchor-ui/dialog';
import Button from 'anchor-ui/button';

const LogoutDialog = ({
  isOpen,
  setIsOpen,
  handleLogout,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleLogout: () => void;
}) => {

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      header='Log Uit'
      hideDialog={handleClose}
      headerStyle={{ color: 'rgb(117, 117, 117)', fontSize: '24px', fontWeight: 'bolder', textAlign: 'center' }}
      style={{ backgroundColor: 'white' }}
      iconColor={'rgba(117, 117, 117, 0.5)'}
    >
      <section className='logout-dialog'>
        <p>
          Weet je het zeker?
        </p>
        <br />
        <div>
          <Button
            onClick={handleLogout}
            style={{ width: 'auto' }}
          >
            Log uit
          </Button>
        </div>
      </section>
    </Dialog>
  );
};

export default LogoutDialog;