import Layout from "../Layout";
import blogImg from "../../assets/blog.avif";
import "./styles.css";
import blogData from "./Blogs/articles.json";
import { Helmet } from "react-helmet";

const BlogPage = () => {
  return (
    <Layout heroText="Blog" heroImg={blogImg}>
      <Helmet>
        <title>Blog - ChatPlaza</title>
        <meta name="description" content="Ontdek alles over gezellig en veilig chatten op Chatplaza.com! Van leuke chatboxen en privéchats tot webcam chatten en thema chatboxen. Maak online connecties, ontmoet nieuwe mensen en geniet van mobiel chatten zonder registratie. Voor iedereen die op zoek is naar een leuke chat community!"/>
      </Helmet>
      <div className="page-container">
        <div className='container-content'>
          <div className="blog-picker">

            {blogData.map((blog, index) => (
              <a className="blog-card" key={index} href={`/blog/${blog.url}`}>
                <img src={blog.img} alt="Blog" />
                <div className="blog-card-content">
                  <h3>{blog.title}</h3>
                  <p>
                    {blog.content.intro.length > 100 ? blog.content.intro.substring(0, 100) + '...' : blog.content.intro}
                  </p>
                  <small>{blog.publicationDate}</small>
                </div>
              </a>
            ))}

          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BlogPage;