import { ReactNode } from "react";
import Footer from "../Footer";
import Navigation from "../Navigation";
import './styles.css';
import Hero from "./Hero";

const Layout = (
  {
    heroText,
    heroImg,
    noFooter,
    isBlog,
    children
  }: {
    heroText?: string,
    heroImg?: string,
    noFooter?: boolean,
    isBlog?: boolean,
    children: ReactNode
  }) => {
  return (
    <div>
      <Navigation />

      {heroText && (
        <Hero
          heroImg={heroImg}
          heroText={heroText}
          isBlog={isBlog}
        />
      )}

      <div className="layout-height">
        {children}
      </div>

      {!noFooter && <Footer />}
    </div>
  );
};

export default Layout;