import Switch from 'anchor-ui/switch';
import { metadataInterface } from '../../interfaces/metadataInterface';
import Select from 'anchor-ui/select';
import MenuItem from 'anchor-ui/menu-item';

const PrivacyPage = ({
  isPrivate,
  changeVisiblity,
  userProfile,
  handleUpdateUserMetadata,
}: {
  isPrivate: boolean;
  changeVisiblity: (bool: boolean) => void;
  userProfile: metadataInterface;
  handleUpdateUserMetadata: (newMetadata: metadataInterface) => void;
}) => {

  const handleChange = (event: object, value: string) => {
    const newMetadata = { ...userProfile };
    newMetadata.callStatus = value;
    handleUpdateUserMetadata(newMetadata);
  };

  return (
    <div>
      <h2>Privacy</h2>

      <Switch
        label='Publiek profiel'
        active={isPrivate === false}
        toggleSwitch={() => changeVisiblity(!isPrivate)}
      />
      <p>Andere gebruikers kunnen jouw profiel {isPrivate && 'niet '}bekijken op www.chatplaza.com.</p>

      <br />
      <section className="account-input">
        <section className="setting-select">
          <Select
            label="Wie mag je bellen?"
            value={userProfile.callStatus ?? "Iedereen"}
            onChange={handleChange}
          >
            <MenuItem text="Iedereen" value="" />
            <MenuItem text="Alleen vrienden" value="call_only_friends" />
            <MenuItem text="Niemand" value="dnd" />
          </Select>
        </section>
      </section>
    </div>
  );
}

export default PrivacyPage;