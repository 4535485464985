import { useState, useEffect } from 'react';
import Dialog from 'anchor-ui/dialog';

const ImageDialog = ({
  isOpen,
  setIsOpen,
  imageUrl,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  imageUrl: string;
}) => {
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number } | null>(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  // Set image dimensions when image is loaded
  useEffect(() => {
    if (isOpen) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        const maxHeight = window.innerHeight * 0.8;
        const maxWidth = window.innerWidth * 0.8;
        let width = maxWidth;
        let height = maxWidth / aspectRatio;

        if (height > maxHeight) {
          height = maxHeight;
          width = maxHeight * aspectRatio;
        }

        setImageDimensions({ width, height });
      };
    }
  }, [isOpen, imageUrl]);

  return (
    <Dialog
      open={isOpen}
      hideDialog={handleClose}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        width: imageDimensions ? `${imageDimensions.width}px` : 'auto',
        height: imageDimensions ? `${imageDimensions.height}px` : '80vh',
        maxWidth: '80vw',
        maxHeight: '80vh',
      }}
      iconColor={'rgba(117, 117, 117, 0.5)'}
    >
    </Dialog>
  );
};

export default ImageDialog;