import { useRef, useState } from 'react';
import { Functions, httpsCallable } from 'firebase/functions';
import Button from 'anchor-ui/button';
import { getActiveColor } from '../../../utils/activeColor';
import { IconVerified } from 'anchor-ui/icons';
import { updateGallery } from '../../../utils/updateMetadata';
import { checkUserIsVIPOrRoyal } from '../../../utils/checkSubscription';

interface GalleryProps {
  galleryImages: string[];
  setGalleryImages: React.Dispatch<React.SetStateAction<string[]>>;
  functions: Functions;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  loggedInUser: CometChat.User;

  setNeedSubscriptionWarning: React.Dispatch<React.SetStateAction<React.ReactNode | null>>;
}

const GallerySection: React.FC<GalleryProps> = (
  {
    galleryImages,
    setGalleryImages,
    functions, setAlert,
    loggedInUser,

    setNeedSubscriptionWarning
  }) => {
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Handle file selection
  const handleFileSelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) return;

    const file = event.target.files[0];

    // Check file size (max 2MB)
    if (file.size > 2 * 1024 * 1024) {
      setAlert({ message: 'Afbeelding mag maximaal 2MB zijn', type: 'error' });
      return;
    }

    // Reset file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    try {
      setIsLoading(true);

      // Convert file to base64
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64Data = reader.result as string;

        try {
          // Call the Firebase function to upload the image
          const uploadGalleryImage = httpsCallable(functions, 'uploadGalleryImage');
          const result = await uploadGalleryImage({
            imageData: {
              base64Data,
              filename: file.name,
              contentType: file.type
            }
          });

          const data = result.data as { success: boolean, gallery: string[] };

          if (data.success) {
            const gallery = data.gallery;
            // Update the gallery images directly with the new URL
            setGalleryImages(data.gallery);
            updateGallery(loggedInUser, gallery);
            setAlert({ message: 'Afbeelding is toegevoegd aan je galerij', type: 'success' });
          }
        } catch (error) {
          console.error('Error in upload operation:', error);
          setAlert({ message: 'Er is iets misgegaan bij het uploaden van de afbeelding', type: 'error' });
        } finally {
          setIsLoading(false);
        }
      };

      reader.onerror = () => {
        setAlert({ message: 'Er is iets misgegaan bij het verwerken van de afbeelding', type: 'error' });
        setIsLoading(false);
      };
    } catch (error) {
      console.error('Error uploading gallery image:', error);
      setAlert({ message: 'Er is iets misgegaan bij het uploaden van de afbeelding', type: 'error' });
      setIsLoading(false);
    }
  };

  // Handle remove image
  const handleRemoveImage = async (imageUrl: string) => {
    try {
      setIsLoading(true);
      const removeGalleryImage = httpsCallable(functions, 'removeGalleryImage');
      const result = await removeGalleryImage({ imageUrl });

      const data = result.data as { success: boolean, galleryCount: number };

      if (data.success) {
        // Update gallery images directly by filtering out the removed image
        setGalleryImages(prev => prev.filter(img => img !== imageUrl));
        setAlert({ message: 'Afbeelding is verwijderd uit je galerij', type: 'success' });
      }
    } catch (error) {
      console.error('Error removing gallery image:', error);
      setAlert({ message: 'Er is iets misgegaan bij het verwijderen van de afbeelding', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  // Trigger file input click
  const handleAddImageClick = () => {
    if (!checkUserIsVIPOrRoyal(loggedInUser.getRole())) {
      setNeedSubscriptionWarning(
        <>
          <b>Galerij is alleen beschikbaar voor VIP leden.</b> Upgrade je account en krijg toegang tot speciale content en functies. Klik op 'Bekijk abonnementen' en ontdek welke optie het beste bij je past!
        </>);
      return;
    }

    if (galleryImages.length >= 5) {
      setAlert({ message: 'Je kunt maximaal 5 afbeeldingen in je galerij plaatsen', type: 'error' });
      return;
    }

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="gallery-section">
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleFileSelection}
      />

      <div className="gallery-header">
        <div className="profile-card-header">
          <span
            className="profile-card-icon"
            style={{
              backgroundColor: getActiveColor()
            }}
          >
            <IconVerified color="white" />
          </span>&nbsp;&nbsp;
          <h1 className="account-details-title m-0" style={{ color: getActiveColor() }}>
            Galerij ({galleryImages.length}/5) {!checkUserIsVIPOrRoyal(loggedInUser.getRole()) && <small>(VIP functie)</small>}
          </h1>
        </div>
      </div>

      {isLoading ? (
        <div className="gallery-loading">Laden...</div>
      ) : (
        <div className="gallery-grid">
          {galleryImages.map((imageUrl, index) => (
            <div key={index} className="gallery-item">
              <img src={imageUrl} alt={`Gallery image ${index + 1}`} />
              <Button
                iconButton
                className="remove-image-btn"
                onClick={() => handleRemoveImage(imageUrl)}
              >
                X
              </Button>
            </div>
          ))}

          {galleryImages.length < 5 && (
            <div className="add-image-container" onClick={handleAddImageClick}>
              +
              <span>Afbeelding toevoegen</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GallerySection;