import Layout from "../Layout";
import authBg from "../../assets/relatieplanet-background.jpg";
import './styles.css';
import FirebaseLogin from "../Login";
import { Auth } from "firebase/auth";
import { Functions } from "firebase/functions";
import { User } from "firebase/auth";
import LexaLogo from '../../assets/lexa-logo-wide.svg';

interface LoginRegisterPageProps {
  auth: Auth;
  functions: Functions;
  loggedInUser: { firebaseUser: User | null, cometChatUser: CometChat.User | null } | null;
  monitorAuthState: () => void;
  isLoading: boolean;
  isLoggingIn: React.MutableRefObject<boolean>;
  handleUserLogin: (user: User, do2FA: boolean) => void;
  userHas2FA: boolean;

  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string } | null>>;
}

const LoginRegisterPage: React.FC<LoginRegisterPageProps> = (props) => {
  return (
    <Layout noFooter>
      <section className="login-register-page">
        <a href="https://ds1.nl/c/?si=51&li=1619882&wi=250877&ws=" target="_blank">

          <div className="login-register-bg">
            <img src={authBg} alt="achtergrond" className="background-image" />
            <div className="gradient-overlay"></div>
          </div>
        </a>

        <FirebaseLogin {...props}>
          <h2>Vind Jouw Nieuwe Gesprekspartner</h2>
          <p>Bij ChatPlaza draait alles om verbinding. Ontmoet gelijkgestemden, deel je interesses, en geniet van gezellige gesprekken in een veilige omgeving. Of je nu op zoek bent naar een diepgaand gesprek of gewoon wat ontspanning, onze chatboxen bieden altijd de juiste plek!</p>
        </FirebaseLogin>

        <div className="auth-logo-container">
          <img
            src={LexaLogo}
            alt="Lexa logo"
            className="login-ad-logo"
          />
          <span className="auth-logo-text">
            lexa.nl | als het om daten gaat
          </span>
        </div>

      </section>
    </Layout>
  );
}

export default LoginRegisterPage;