import { CometChatCalls } from "@cometchat/calls-sdk-javascript";
import { Call } from "@cometchat/chat-sdk-javascript";
import { metadataInterface } from "../interfaces/metadataInterface";

export const generateCallToken = async (sessionId: string, loggedInUser: CometChat.User) => {
  if (loggedInUser) {
    const authToken = loggedInUser.getAuthToken();

    try {
      const response = await CometChatCalls.generateToken(sessionId, authToken);
      return response.token;
    } catch (err) {
      console.error("Generating call token failed with error: ", err);
      return null;
    }
  }
  return null;
};

const endCall = () => {
  CometChatCalls.endSession();
}

export const startCallSession = (
  callToken: string,
  setOngoingCall: React.Dispatch<React.SetStateAction<Call | null>>
) => {
  // Get the DOM element where you want to render the call UI
  const callContainer = document.getElementById('call-container');

  if (!callContainer) {
    console.error("Call container element not found");
    return;
  }

  // Create call settings using the builder with correct methods
  const callSettings = new CometChatCalls.CallSettingsBuilder()
    .enableDefaultLayout(true)
    .setIsAudioOnlyCall(true) // Make it an audio-only call
    .showEndCallButton(true)
    .showMuteAudioButton(true)
    .startWithAudioMuted(false) // Start with audio enabled
    .setCallListener({
      onUserJoined: (user) => {
        console.log("User joined:", user);
      },
      onUserLeft: (user) => {
        console.log("User left:", user);
        endCall();

        setOngoingCall(null);
      },
      onCallEnded: () => {
        endCall();
      },
      onError: (error) => {
        endCall();
      },
      onMediaDeviceListUpdated: (deviceList) => {
        console.log("Media device list updated:", deviceList);
      },
      onUserMuted: (event) => {
        console.log("User muted:", event);
      }
    })
    .build();

  // Start the call session
  CometChatCalls.startSession(
    callToken,
    callSettings,
    callContainer as HTMLElement
  );
};

const isFriend = (friends: CometChat.User[], callWithUser: CometChat.User) => {
  return friends.some((friend) => friend.getUid() === callWithUser.getUid());
}

export const checkIfUserCanBeCalled = (
  user: CometChat.User,
  friends: CometChat.User[] | boolean,
) => {
  const metadata = user.getMetadata() as metadataInterface;
  const callStatus = metadata?.callStatus ?? undefined;

  switch (callStatus) {
    case undefined:
      return true;
    case "call_only_friends":
      if (Array.isArray(friends)) {
        return isFriend(friends, user);
      }
      return friends;
    case "dnd":
      return false;
    default:
      return true;
  }
};
