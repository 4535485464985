import React, { useEffect, useState } from 'react';
import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';
import defaultAvatar from "./../../../assets/default_avatar.jpg";
import privateChannelImage from "./../../../assets/private_channel.png";
import AddGroupModal from './AddGroupModal';
import Avatar from 'anchor-ui/avatar';
import { ActiveChannelInterface } from '../../../interfaces/activeChannelInterface';
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { Functions } from 'firebase/functions';
import { UserSettingsInterface } from '../../../interfaces/userSettingsInterface';
import { ConversationInterface } from '../../../interfaces/conversationInterface';
import { checkIfUserIsBlocked } from '../../../utils/checkIfUserIsBlocked';
import {
  IconMore,
  IconTools,
  IconDelete,
  IconClose,
  IconSend,
  IconPeople,
} from 'anchor-ui/icons';
import IconMenu from 'anchor-ui/icon-menu';
import MenuItem from 'anchor-ui/menu-item';
import { getActiveColor } from '../../../utils/activeColor';
import DeleteMultipleConversationsModal from './DeleteMultipleConversationsModal';
import Button from 'anchor-ui/button';
import Divider from 'anchor-ui/divider';
import ConversationListItem from './ConversationListItem';
import { checkUserHasSubscription } from '../../../utils/checkSubscription';

const ConversationsListComponent = ({
  conversations,
  unreadCount,
  handleConversationClick,
  handleGroupConversationClick,
  initialConversation,
  currentChat,
  setCurrentChat,
  friends,
  refreshConversations,
  setUnreadCount,
  loggedInUser,
  blockedUsers,
  setAlert,
  isTimeline,
  functions,
  userSettings,

  handleRemoveConversation,

  messageToForward,
  setMessageToForward,

  handleSendMessage,

  isSideNav,
  handleInitiateCall,

  setNeedSubscriptionWarning,
}: {
  conversations: ConversationInterface[];
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  handleConversationClick: (conversation: CometChat.Conversation) => void;
  handleGroupConversationClick: (conversation: CometChat.Group) => void;
  initialConversation: CometChat.Conversation | null;
  currentChat: ActiveChannelInterface;
  setCurrentChat: (channel: ActiveChannelInterface) => void;
  friends: CometChat.User[];
  refreshConversations: () => void;
  setUnreadCount: React.Dispatch<React.SetStateAction<{ [id: string]: { nr: number, chat: ActiveChannelInterface } }>>;
  loggedInUser: CometChat.User | null;
  blockedUsers: CometChat.User[];
  setAlert: (alert: { message: string; type: string; } | null) => void;
  isTimeline: boolean;
  functions: Functions;
  userSettings: UserSettingsInterface;

  handleRemoveConversation: (conversation: CometChat.Conversation | CometChat.Group) => void;

  messageToForward?: CometChat.BaseMessage | null;
  setMessageToForward?: React.Dispatch<React.SetStateAction<CometChat.BaseMessage | null>>;

  handleSendMessage?: (event: any, receiver: string, isGroup: boolean, messageText?: string, isForwarded?: boolean) => void;

  isSideNav?: boolean;
  handleInitiateCall: (receiver: CometChat.User) => void;
  setNeedSubscriptionWarning: React.Dispatch<React.SetStateAction<React.ReactNode | null>>;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteMultipleConversationsMode, setDeleteMultipleConversationsMode] = useState(false);
  const [conversationsToDelete, setConversationsToDelete] = useState<ConversationInterface[]>([]);
  const [conversationsToForwardTo, setConversationsToForwardTo] = useState<ConversationInterface[]>([]);

  const [displayedConversations, setDisplayedConversations] = useState<ConversationInterface[]>(isTimeline ? conversations.slice(0, 5) : conversations);
  const [groupConversations, setGroupConversations] = useState<ConversationInterface[]>([]);

  const openModal = () => {
    const isPremium = checkUserHasSubscription(loggedInUser?.getRole() ?? "");

    if (!isPremium) {
      setNeedSubscriptionWarning(
        <>
          <p>Je hebt een abonnement nodig om een privé groep te maken.</p>
          <p>Upgrade je account voor meer mogelijkheden!</p>
        </>
      );

      return;
    }
    setIsModalOpen(true);
  }
  const closeModal = () => setIsModalOpen(false);

  const getConversationWith = (conversation: CometChat.Conversation) => {
    return conversation.getConversationWith() as CometChat.User;
  };

  const RemoveConversationButton = ({ conversation }: { conversation: CometChat.Conversation | CometChat.Group }) => {
    const handleOpenRemoveConversationModal = (conversation: CometChat.Conversation | CometChat.Group, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();

      handleRemoveConversation(conversation);
    };

    return (
      <div data-radium="true" style={{ position: 'relative' }}>
        <div>
          <button
            type="button"
            data-radium="true"
            className='li-button'
            onClick={(event) => handleOpenRemoveConversationModal(conversation, event)}
          >
            <IconClose />
          </button>
        </div>
      </div>
    );
  };

  const getIcon = (group: any) => {
    return group.icon ?? privateChannelImage;
  };

  const getUserAvatar = (conversation: CometChat.Conversation) => {
    return (conversation.getConversationWith() as CometChat.User).getAvatar() ?? defaultAvatar;
  };

  const getLastMessageText = (conversation: ConversationInterface) => {
    let text = conversation.isMyMsg ? 'Jij: ' : '';
    text += conversation.lastMsg;

    // If text is longer than 16 characters, truncate it
    if (text.length > 16) {
      text = text.substring(0, 16) + '...';
    }

    return text;
  };

  const formatDate = (timestamp: number) => {
    const daysOfWeek = ["zo.", "ma.", "di.", "wo.", "do.", "vr.", "za."];
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    const now = new Date();

    // Helper to zero-pad day or month numbers
    const padZero = (num: number) => num.toString().padStart(2, '0');

    // Check if date is today
    if (
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear()
    ) {
      const hours = padZero(date.getHours());
      const minutes = padZero(date.getMinutes());
      return `${hours}:${minutes}`;
    }

    // Check if date is yesterday
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);

    if (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    ) {
      return "gisteren";
    }

    // Check if date is within the same week
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - now.getDay()); // Set to start of the week (Sunday)

    if (date >= startOfWeek) {
      return daysOfWeek[date.getDay()];
    }

    // Otherwise, return date as dd-mm-yyyy
    const day = padZero(date.getDate());
    const month = padZero(date.getMonth() + 1); // Months are zero-based
    const year = date.getFullYear();

    // Check if day, month, or year is NaN
    if (day === "NaN" || month === "NaN") {
      return "-";
    }

    return `${day}-${month}-${year}`;
  };

  const getPrimaryText = (text: string) => {
    return text.length > 13 ? text.substring(0, 13) + '...' : text;
  };

  const handleClick = (conv: any, isGroup: boolean) => {
    // If deleteMultipleConversationsMode is enabled, add or remove conversation from conversationsToDelete
    if (deleteMultipleConversationsMode) {
      if (conversationsToDelete.includes(conv)) {
        setConversationsToDelete(conversationsToDelete.filter(conversation => conversation !== conv));
        return;
      }
      setConversationsToDelete([...conversationsToDelete, conv]);
      return;
    };

    // If messageToForward is set, add or remove conversation from conversationsToForwardTo
    if (messageToForward) {
      if (conversationsToForwardTo.includes(conv)) {
        setConversationsToForwardTo(conversationsToForwardTo.filter(conversation => conversation !== conv));
        return;
      }
      setConversationsToForwardTo([...conversationsToForwardTo, conv]);
      return;
    };

    // If conversation is a group, handle group conversation click
    if (isGroup) {
      handleGroupConversationClick(conv);
      return;
    }
    // Otherwise, handle private conversation click
    handleConversationClick(conv);
  };

  const handleCancelChecks = () => {
    setDeleteMultipleConversationsMode(false);
    setConversationsToDelete([]);
    setConversationsToForwardTo([]);

    if (setMessageToForward) setMessageToForward(null);
  };

  const handleForwardMessage = () => {
    if (!handleSendMessage || !messageToForward) return;
    if (setMessageToForward) setMessageToForward(null);

    // Loop through all conversations to forward to
    conversationsToForwardTo.forEach((conversation: any) => {
      // const handleSendMessage = async (event: any, messageText?: string, isForwarded?: boolean) => {

      const isTextMessage = messageToForward instanceof CometChat.TextMessage;
      const isMediaMessage = messageToForward instanceof CometChat.MediaMessage;

      const isGroup = conversation.isGroup ?? "guid" in conversation;
      const receiver = isGroup ? (conversation as any).guid : conversation.conversationWith.uid;

      if (isTextMessage) {
        handleSendMessage(0, receiver, isGroup, messageToForward.getText(), true);
      } else if (isMediaMessage) {
        // To be implemented...
      }
    });

    setConversationsToForwardTo([]);
  };

  useEffect(() => {
    const groupConversations = conversations.filter((conversation: ConversationInterface) => {
      return "guid" in conversation.conversation;
    });

    const privateConversations = conversations.filter((conversation: ConversationInterface) => {
      return !("guid" in conversation.conversation);
    });

    setGroupConversations(groupConversations);
    setDisplayedConversations(isTimeline ? privateConversations.slice(0, 5) : privateConversations);
  }, [conversations])

  const initialLastMessageText = 'Deel nooit gegevens met vreemden en open nooit onbekende urls. ChatPlaza en moderators zullen nooit vragen naar accountgegevens of vragen om betalingen te doen. Kom je iets verdachts tegen? Rapporteer het bericht en/of de gebruiker.';

  return (
    <>
      <DeleteMultipleConversationsModal
        isOpen={deleteModalIsOpen}
        closeModal={() => setDeleteModalIsOpen(false)}
        conversationsToDelete={conversationsToDelete}
        setConversationsToDelete={setConversationsToDelete}
        setDeleteMultipleConversationsMode={setDeleteMultipleConversationsMode}
        refreshConversations={refreshConversations}
        currentChat={currentChat}
        setCurrentChat={setCurrentChat}
        setUnreadCount={setUnreadCount}
        loggedInUser={loggedInUser!}
        setAlert={setAlert}
        functions={functions}
      />

      <div className="channel-list">
        <Divider />
        {!isTimeline && groupConversations.length > 0 &&
          <List header={
            <div className="conversationlist-header">
              {deleteMultipleConversationsMode ? <span>Verwijder gesprekken</span> :
                messageToForward ? (conversationsToForwardTo.length === 0 ?
                  <span>Doorsturen annuleren</span> :
                  <span>Stuur bericht door </span>
                ) :
                  <span>Groepsgesprekken</span>
              }

              {messageToForward && setMessageToForward ?
                <Button
                  iconButton
                  className="send-button"
                  onClick={handleForwardMessage}
                >
                  {conversationsToForwardTo.length === 0 ? <IconClose /> :
                    <IconSend color={getActiveColor()} />
                  }
                </Button> :
                <IconMenu icon={<IconMore />}>
                  {deleteMultipleConversationsMode ?
                    <>
                      <MenuItem
                        text={"Annuleren"}
                        icon={<IconClose />}
                        onClick={handleCancelChecks}
                      />
                      {conversationsToDelete.length > 0 &&
                        <MenuItem
                          text={`Verwijder ${conversationsToDelete.length} gesprekken`}
                          icon={<IconDelete />}
                          onClick={() => setDeleteModalIsOpen(true)}
                        />
                      }
                    </> :
                    <>
                      <MenuItem
                        text={"Start nieuwe privé-groep"}
                        icon={<IconPeople color={getActiveColor()} />}
                        onClick={() => {
                          openModal();
                        }}
                      />
                      <MenuItem
                        text={"Verwijder meerdere gesprekken"}
                        icon={<IconTools />}
                        onClick={() => setDeleteMultipleConversationsMode(true)}
                      />
                    </>
                  }
                </IconMenu>
              }
            </div>
          }>
            <React.Fragment key={'gesprekken'}>
              <AddGroupModal
                isOpen={isModalOpen}
                closeModal={closeModal}
                friends={friends}
                refreshConversations={refreshConversations}
                setAlert={setAlert}
                functions={functions}
              />

              {groupConversations.map((conversation: ConversationInterface, index: number) => {
                const conv = (conversation.conversation ?? conversation) as any;
                const isGroup = "guid" in conv;

                let unreadMessages;
                if (isGroup && unreadCount[conv.guid] !== undefined) {
                  // Get the unread messages count for the groupconversation
                  unreadMessages = unreadCount[conv.guid] ? unreadCount[conv.guid].nr : 0;
                } else if (!isGroup) {
                  // Get the unread messages count for the private conversation
                  const id = (conv as CometChat.Conversation).getConversationId();
                  unreadMessages = unreadCount[id] ? unreadCount[id].nr : 0;
                }

                return (
                  <span key={index} className={conversationsToDelete.includes(conv) || (messageToForward && conversationsToForwardTo.includes(conv)) ? "active-channels" : ""}>
                    {conversation &&
                      <ConversationListItem
                        getPrimaryText={getPrimaryText}
                        formatDate={formatDate}
                        isGroup={isGroup}
                        conv={conv}
                        getConversationWith={getConversationWith}
                        getLastMessageText={getLastMessageText}
                        getIcon={getIcon}
                        getUserAvatar={getUserAvatar}
                        userSettings={userSettings}
                        handleClick={handleClick}
                        conversation={conversation}
                        deleteMultipleConversationsMode={deleteMultipleConversationsMode}
                        messageToForward={messageToForward}

                        conversationsToDelete={conversationsToDelete}
                        conversationsToForwardTo={conversationsToForwardTo}

                        unreadMessages={unreadMessages}
                        blockedUsers={blockedUsers}

                        handleRemoveConversation={handleRemoveConversation}

                        isSideNav={isSideNav}

                        handleInitiateCall={handleInitiateCall}
                      />
                    }
                  </span>
                )
              })}
            </React.Fragment>
          </List>
        }

        <Divider />
        <List header={isTimeline ? "Recente gesprekken" :
          <div className="conversationlist-header">

            {deleteMultipleConversationsMode ? <span>Verwijder gesprekken</span> :
              messageToForward ? (conversationsToForwardTo.length === 0 ?
                <span>Doorsturen annuleren</span> :
                <span>Stuur bericht door</span>
              ) :
                <span>Privé gesprekken</span>
            }

            {groupConversations.length === 0 &&
              (messageToForward && setMessageToForward ?
                <Button
                  iconButton
                  className="send-button"
                  onClick={handleForwardMessage}
                >
                  {conversationsToForwardTo.length === 0 ? <IconClose /> :
                    <IconSend color={getActiveColor()} />
                  }
                </Button> :
                <IconMenu icon={<IconMore />}>
                  {deleteMultipleConversationsMode ?
                    <>
                      <MenuItem
                        text={"Annuleren"}
                        icon={<IconClose />}
                        onClick={handleCancelChecks}
                      />
                      {conversationsToDelete.length > 0 &&
                        <MenuItem
                          text={`Verwijder ${conversationsToDelete.length} gesprekken`}
                          icon={<IconDelete />}
                          onClick={() => setDeleteModalIsOpen(true)}
                        />
                      }
                    </> :
                    <>
                      <MenuItem
                        text={"Start nieuwe privé-groep"}
                        icon={<IconPeople color={getActiveColor()} />}
                        onClick={() => {
                          openModal();
                        }}
                      />
                      <MenuItem
                        text={"Verwijder meerdere gesprekken"}
                        icon={<IconTools />}
                        onClick={() => setDeleteMultipleConversationsMode(true)}
                      />
                    </>
                  }
                </IconMenu>
              )}
          </div>
        }>
          <React.Fragment key={'gesprekken'}>
            <AddGroupModal
              isOpen={isModalOpen}
              closeModal={closeModal}
              friends={friends}
              refreshConversations={refreshConversations}
              setAlert={setAlert}
              functions={functions}
            />

            {initialConversation &&
              <ListItem
                primaryText={getPrimaryText(initialConversation.getConversationWith().getName())}
                secondaryText={initialLastMessageText}
                avatar={
                  <Avatar
                    image={getUserAvatar(initialConversation)}
                    style={{ backgroundColor: userSettings.themeColor }}
                    status={checkIfUserIsBlocked(blockedUsers, getConversationWith(initialConversation).getUid()) ?
                      null :
                      getConversationWith(initialConversation).getStatus()}
                  />
                }
                onClick={() => handleConversationClick(initialConversation)}
                rightButton={<RemoveConversationButton conversation={initialConversation} />}
                blocked={checkIfUserIsBlocked(blockedUsers, getConversationWith(initialConversation).getUid())}
              />
            }

            {displayedConversations.map((conversation: ConversationInterface, index: number) => {
              const conv = (conversation.conversation ?? conversation) as any;
              const isGroup = "guid" in conv;

              let unreadMessages;
              if (isGroup && unreadCount[conv.guid] !== undefined) {
                // Get the unread messages count for the groupconversation
                unreadMessages = unreadCount[conv.guid] ? unreadCount[conv.guid].nr : 0;
              } else if (!isGroup) {
                // Get the unread messages count for the private conversation
                const id = (conv as CometChat.Conversation).getConversationId();
                unreadMessages = unreadCount[id] ? unreadCount[id].nr : 0;
              }

              return (
                <span key={index} className={conversationsToDelete.includes(conv) ||
                  (messageToForward && conversationsToForwardTo.includes(conv)) ? "active-channels" : ""}>
                  {conversation &&
                    <ConversationListItem
                      getPrimaryText={getPrimaryText}
                      formatDate={formatDate}
                      isGroup={isGroup}
                      conv={conv}
                      getConversationWith={getConversationWith}
                      getLastMessageText={getLastMessageText}
                      getIcon={getIcon}
                      getUserAvatar={getUserAvatar}
                      userSettings={userSettings}
                      handleClick={handleClick}
                      conversation={conversation}
                      deleteMultipleConversationsMode={deleteMultipleConversationsMode}
                      messageToForward={messageToForward}

                      conversationsToDelete={conversationsToDelete}
                      conversationsToForwardTo={conversationsToForwardTo}

                      unreadMessages={unreadMessages}
                      blockedUsers={blockedUsers}

                      handleRemoveConversation={handleRemoveConversation}

                      isSideNav={isSideNav}
                      handleInitiateCall={handleInitiateCall}
                    />
                  }
                </span>
              )
            })}
          </React.Fragment>
        </List>
      </div >
    </>
  );
};

export default ConversationsListComponent;
