import ChannelHeader from "anchor-ui/channel-header";
import "./styles.css";
import { SetStateAction, useEffect, useState } from "react";
import { Functions, httpsCallable } from "firebase/functions";
import Card from "anchor-ui/card";
import IconMenu from "anchor-ui/icon-menu";
import MenuItem from "anchor-ui/menu-item";
import { IconMore, IconHeart } from "anchor-ui/icons";
import Input from "anchor-ui/input";
import { checkIfUserIsSuperAdmin } from "../../utils/checkIfUserIsAdmin";

const BanList = ({
  functions,
  setAlert,
  loggedInUser,
}: {
  functions: Functions;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string } | null>>;
  loggedInUser: CometChat.User,
}) => {
  const [bannedUsers, setBannedUsers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState<any>(null);

  useEffect(() => {
    getBannedUsersAsync();
  }, []);

  const getBannedUsersAsync = async ({ filterWord }: { filterWord?: string } = {}) => {
    try {
      const fetchBannedUsers = httpsCallable(functions, "fetchBannedUsers");
      const result = await fetchBannedUsers({ perPage: 100, page: 1, filterWord });
      setBannedUsers(result.data as any);
    } catch (error) {
      console.error("Error fetching banned users:", error);
    }
  };

  const getDateTimeFromUnix = (unix: number) => {
    const date = new Date(unix * 1000);
    const formattedDate = date.getDate().toString().padStart(2, '0') + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getFullYear();
    const formattedTime = date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0');
    return formattedDate + " " + formattedTime;
  };

  const unbanUser = async (uid: string) => {
    try {
      const unbanUser = httpsCallable(functions, "unbanUser");
      await unbanUser({ uid });

      // Remove user from bannedUsers
      setBannedUsers(bannedUsers.filter((user: any) => user.uid !== uid));
      setAlert({ message: "Gebruiker succesvol geunbanned", type: "success" });
    } catch (error) {
      console.error("Error unbanning user:", error);
      setAlert({ message: "Er is iets fout gegaan bij het unbannen van de gebruiker", type: "error" });
    }
  };

  const searchUsers = async (searchTerm: string) => {
    // if input is less than 3 characters, don't search
    if (searchTerm.length < 3 && searchTerm.length > 0) {
      setIsSearching(false);
      return;
    }
    await getBannedUsersAsync({ filterWord: searchTerm });

    setIsSearching(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInput(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setIsSearching(true);
    setBannedUsers([]);
    setTypingTimeout(setTimeout(() => searchUsers(value), 500));
  };

  return (
    <section className="admin-banlist">
      <ChannelHeader
        name={"Lijst van verbannen gebruikers"}
      />

      <div className="admin-banlist-container">
        <Input
          label="Zoek naar een gebruiker"
          value={searchInput}
          onChange={handleInputChange}
          maxLength={500}
          style={{ marginLeft: "auto", marginRight: "auto" }}
        />

        {isSearching && <p className="searching-text">Zoeken...</p>}

        {
          bannedUsers.length > 0 && (
            <div className="admin-banlist-container">
              {bannedUsers.map((user: any, index: number) => {
                return (
                  <Card className="admin-banlist-card" key={index}>
                    <div className="admin-banlist-card-content">
                      {
                        checkIfUserIsSuperAdmin(loggedInUser) &&
                        <IconMenu icon={<IconMore />} className="unban-button">
                          <MenuItem
                            text={`Unban ${user.bannedUsername ?? user.uid}`}
                            icon={<IconHeart />}
                            onClick={() => unbanUser(user.uid)}
                          />
                        </IconMenu>
                      }
                      <div className="user-info">
                        <p><b>#{index + 1}</b></p>
                        <p>{user.bannedUsername ?? user.uid}</p>
                        <p>{user.email}</p>
                      </div>
                      <div className="ban-details">
                        <p><b>Reden:</b> {user.banReason}</p>
                        <p><b>Verbannen door:</b> {user.bannedBy ?? "admin"}</p>
                        <p><b>Verbannen vanaf:</b> {user.bannedAt ? getDateTimeFromUnix(user.bannedAt._seconds) : "N/A"}</p>
                        <p><b>Verbannen tot:</b> {getDateTimeFromUnix(user.bannedUntil._seconds)}</p>
                      </div>
                    </div>
                  </Card>)
              })}
            </div>
          )
        }
      </div>
    </section>
  );
};

export default BanList;