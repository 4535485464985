import { CometChat } from "@cometchat/chat-sdk-javascript";
import { metadataInterface } from "./../interfaces/metadataInterface";
import { IconGenderFemale, IconGenderMale, IconMaleFemale } from "anchor-ui/icons";

export const getGenderFromMetadata = (user: CometChat.User, isBig?: boolean) => {
  let metadata: metadataInterface | string = user.getMetadata() as unknown as metadataInterface;

  // Parse metadata if it's a JSON string
  if (typeof metadata === 'string') {
    try {
      metadata = JSON.parse(metadata);
    } catch (error) {
      console.error('Error parsing metadata:', error);
      return <IconMaleFemale />;
    }
  }

  // If metadata is not an object, return 'unknown'
  if (!metadata || typeof metadata !== 'object') {
    return <IconMaleFemale />;
  }

  return (
    <span className={`${!isBig ? 'gender-icon' : ''}`}>
      {metadata.about.gender === 'Man' ? (
        <IconGenderMale color={'rgb(93, 182, 221)'} />
      ) : metadata.about.gender === 'Vrouw' ? (
        <IconGenderFemale color={'rgb(244, 143, 177)'} />
      ) : (
        <IconMaleFemale />
      )}
    </span>
  );
};