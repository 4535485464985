import React, { useEffect, useState, useRef } from 'react';
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { ActiveChannelInterface } from './../../interfaces/activeChannelInterface';
import './styles.css';
import MessageComponent from './MessageComponent';
import MessageListComponent from './MessageListComponent';
import MessageInputComponent from './MessageInputComponent';
import ChannelHeaderComponent from './ChannelHeader';
import { getDateFromTimestamp } from '../getDateFromTimestamp';
import { NotificationInterface } from './../../interfaces/notificationInterface';
import EditMessageModal from './EditMessageModal';
import ReportMessageModal from './../../components/ReportModal/ReportMessageModal';
import { checkIfUserIsBlocked } from '../../utils/checkIfUserIsBlocked';
import Message from 'anchor-ui/message';
import chatBotImg from './../../assets/chatbot.jpg';
import AdminBadge from 'anchor-ui/admin-badge';
import Button from 'anchor-ui/button';
import { UserSettingsInterface } from '../../interfaces/userSettingsInterface';
import { ReplyMessageInterface } from '../../interfaces/replyMessageInterface';
import { ConversationInterface } from '../../interfaces/conversationInterface';
import { isGroupConversation } from '../../utils/isGroupConversationCheck';
import GoogleAdSense from '../GoogleAdSense';
import {
  checkIfUserHasPermissions,
  checkIfUserIsAdmin,
  checkIfUserIsModerator
} from '../../utils/checkIfUserIsAdmin';
import { getProfileFromMetadata } from '../../utils/updateMetadata';
import AudioChannelHeader from '../AudioChannelHeader';
import { premiumChannelIds } from '../../utils/premiumChannelIds';
import { checkUserHasSubscription, checkUserIsRoyal } from '../../utils/checkSubscription';

interface ChatComponentProps {
  currentChat: ActiveChannelInterface;
  setCurrentChat: React.Dispatch<React.SetStateAction<ActiveChannelInterface>>;
  conversations: any;
  loggedInUser: CometChat.User | null;
  loadIncomingFriendRequests: () => void;
  loadOutgoingFriendRequests: () => void;
  setNotifications: React.Dispatch<React.SetStateAction<NotificationInterface[] | null>>;
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;
  friends: CometChat.User[];
  hasSentFriendRequest: (user: CometChat.User) => boolean;
  hasReceivedFriendRequest: (user: CometChat.User) => boolean;
  handleAddOrRemoveFriend: (friend: CometChat.User) => void;
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  messages: CometChat.BaseMessage[];
  setMessages: React.Dispatch<React.SetStateAction<CometChat.BaseMessage[]>>;
  leftMenuOpen: boolean;
  setLeftMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  rightMenuOpen: boolean;
  setRightMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  groupMembers?: CometChat.GroupMember[];
  chatWithUser?: CometChat.User;
  showLoadMostRecentMsgBtn: boolean;
  setShowLoadMostRecentMsgBtn: React.Dispatch<React.SetStateAction<boolean>>;

  handleMuteUser: (user: CometChat.User) => void;
  handleKickUser: (user: CometChat.User, group: CometChat.Group) => void;
  handleLogoutKickUser: (user: CometChat.User) => void;
  handleBanUser: (user: CometChat.User) => void;
  handleDeleteMessageAsAdmin: (message: CometChat.BaseMessage) => void;
  userSettings: UserSettingsInterface;
  handleRemoveConversation: (conversation: CometChat.Conversation | CometChat.Group) => void;
  setUserToWarn: (user: CometChat.User) => void;
  setMessageToForward: (message: CometChat.BaseMessage) => void;
  handleSendImage: (event: React.ChangeEvent<HTMLInputElement>, receiver: string, isGroup: boolean, isForwarded?: boolean) => void;

  inputRef: React.RefObject<HTMLInputElement>;
  commandMenuOpen: boolean;
  setCommandMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  messageToReply: ReplyMessageInterface | null;
  setMessageToReply: React.Dispatch<React.SetStateAction<ReplyMessageInterface | null>>;
  handleSendMessage: (event: any, receiver: string, isGroup: boolean, messageText?: string) => void;
  isFetching: boolean;
  fetchMessages: () => void;
  buildMessagesRequest: (fetchFromTimestamp: number, limit: number, currentChat: any) => CometChat.MessagesRequest;

  showLoadMoreBtn: boolean;
  setShowLoadMoreBtn: React.Dispatch<React.SetStateAction<boolean>>;

  filterValidMessages: (
    timestamp: number,
    messageList: CometChat.BaseMessage[],
    messages: CometChat.BaseMessage[],
    currentChat: any,
    checkDuplicate?: boolean
  ) => CometChat.BaseMessage[];
  setActiveTabIndex: (index: number) => void;

  startedTyping: boolean;
  setStartedTyping: React.Dispatch<React.SetStateAction<boolean>>;
  typingNotification: CometChat.TypingIndicator;
  setTypingNotification: React.Dispatch<React.SetStateAction<CometChat.TypingIndicator>>;

  usersTyping: { name: string; typingStarted: number; }[] | null;
  setUsersTyping: React.Dispatch<React.SetStateAction<{ name: string; typingStarted: number; }[] | null>>;

  ongoingCall: CometChat.Call | null;
  setOngoingCall: React.Dispatch<React.SetStateAction<CometChat.Call | null>>;
  outgoingCall: { call: CometChat.Call, receiver: CometChat.User } | null;
  setOutgoingCall: React.Dispatch<React.SetStateAction<{ call: CometChat.Call, receiver: CometChat.User } | null>>;

  handleInitiateCall: (receiver: CometChat.User) => void;
  handleTerminateCall: () => void;
}

const ChatComponent: React.FC<ChatComponentProps> = ({
  currentChat,
  setCurrentChat,
  conversations,
  loggedInUser,
  loadIncomingFriendRequests,
  loadOutgoingFriendRequests,
  blockedUsers,
  handleBlockUser,
  friends,
  hasSentFriendRequest,
  hasReceivedFriendRequest,
  handleAddOrRemoveFriend,
  setUserToReport,
  setAlert,

  messages,
  setMessages,

  leftMenuOpen,
  setLeftMenuOpen,
  rightMenuOpen,
  setRightMenuOpen,
  unreadCount,

  groupMembers,
  chatWithUser,

  showLoadMostRecentMsgBtn,
  setShowLoadMostRecentMsgBtn,

  handleMuteUser,
  handleKickUser,
  handleLogoutKickUser,
  handleBanUser,
  handleDeleteMessageAsAdmin,

  userSettings,

  handleRemoveConversation,
  setUserToWarn,
  setMessageToForward,

  inputRef,
  commandMenuOpen,
  setCommandMenuOpen,
  messageToReply,
  setMessageToReply,
  handleSendMessage,
  handleSendImage,

  isFetching,
  fetchMessages,
  buildMessagesRequest,

  showLoadMoreBtn,
  setShowLoadMoreBtn,

  filterValidMessages,
  setActiveTabIndex,

  startedTyping,
  setStartedTyping,
  typingNotification,
  setTypingNotification,

  usersTyping,
  setUsersTyping,

  ongoingCall,
  setOngoingCall,
  outgoingCall,
  setOutgoingCall,

  handleInitiateCall,
  handleTerminateCall,
}) => {
  const [editMessage, setEditMessage] = useState<CometChat.TextMessage | null>(null);
  const [messageToReport, setMessageToReport] = useState<CometChat.BaseMessage | null>(null);

  const [emojiMenuOpen, setEmojiMenuOpen] = useState(false);

  const messageEndRef = useRef<HTMLDivElement | null>(null);

  const loadIncomingFriendRequestsRef = useRef(loadIncomingFriendRequests);
  const loadOutgoingFriendRequestsRef = useRef(loadOutgoingFriendRequests);

  const publicChannelIds = [
    "admin_chat",
    "familymeeting",
    "gaylife",
    "gewaagd",
    "lesbox",
    "ongewenst",
    "sm_dungeon",
    "zaadbunker",
    "pikant"
  ];

  // Fetch older messages for the current chat
  const fetchOlderMessages = async () => {
    const fetchFromTimestamp = messages[0]?.getSentAt() ?? 0;
    const scrollToId = messages[0]?.getId() + '';
    let messagesRequest = buildMessagesRequest(fetchFromTimestamp, 50, currentChat);

    try {
      const messageList = await messagesRequest.fetchPrevious();
      if (messageList.length < 50) setShowLoadMoreBtn(false);

      const validMessages = filterValidMessages(0, messageList, messages, currentChat, true);
      const updatedMessages = [...validMessages, ...messages];

      // If the updatedMessages array is over 150 messages, remove the last 50 messages
      if (updatedMessages.length > 150) {
        updatedMessages.splice(150, 50); // Remove older messages
        setShowLoadMostRecentMsgBtn(true);
      }

      setMessages(updatedMessages);

      // Scroll to the previous oldest message fetched
      const previousOldestMsg = document.getElementById(scrollToId);
      if (previousOldestMsg) previousOldestMsg.scrollIntoView();
    } catch (error) {
      console.error('Message fetching failed with error:', error);
      setAlert({ message: 'Berichten konden niet worden opgehaald', type: 'error' });
    }
  };

  useEffect(() => {
    loadIncomingFriendRequestsRef.current = loadIncomingFriendRequests;
    loadOutgoingFriendRequestsRef.current = loadOutgoingFriendRequests;
  }, [loadIncomingFriendRequests, loadOutgoingFriendRequests]);

  const handleDeleteMessage = (messageId: string) => {
    const metadata = getProfileFromMetadata(loggedInUser!);
    const isMuted = metadata.muted;

    if (isMuted) {
      setMessages(prevMessages => prevMessages.filter(message => message.getId() + '' !== messageId));
      return;
    }

    CometChat.deleteMessage(messageId).then(
      () => {
        setMessages(prevMessages => prevMessages.filter(message => message.getId() + '' !== messageId));
      },
      error => {
        console.error('Message deleting failed with error:', error);
        setAlert({ message: 'Bericht kon niet worden verwijderd', type: 'error' });
      }
    );
  }

  const getConversationFromMessage = (message: CometChat.BaseMessage) => {
    return conversations.find((conversation: ConversationInterface) => {
      if (isGroupConversation(conversation)) return false;
      const privateConversation = conversation.conversation as CometChat.Conversation;
      return privateConversation.getConversationId() === message.getReceiverId();
    });
  };

  useEffect(() => {
    // Fetch messages for the current chat when the chat is opened
    fetchMessages();

    // Reset the users typing array when a new chat is opened
    setUsersTyping([]);
    // Set the typing notification for the current chat
    setTypingNotification(currentChat.isGroup ?
      new CometChat.TypingIndicator(currentChat.id, CometChat.RECEIVER_TYPE.GROUP)
      : new CometChat.TypingIndicator(chatWithUser?.getUid(), CometChat.RECEIVER_TYPE.USER));

    const listenerId = 'message-listener';

    CometChat.addMessageListener(
      listenerId,
      new CometChat.MessageListener({
        onTypingStarted: (typingIndicator: CometChat.TypingIndicator) => {
          // If the typing indicator is for the current chat, add the user to the usersTyping array
          if (typingIndicator.getSender().getUid() === currentChat.receiverId ||
            currentChat.id === typingIndicator.getReceiverId()
          ) {
            setUsersTyping(prevUsersTyping => {
              if (!prevUsersTyping) return [{ name: typingIndicator.getSender().getName(), typingStarted: Date.now() }];

              const senderUid = typingIndicator.getSender().getUid();
              const senderName = typingIndicator.getSender().getName();

              // If the chat is a group chat, check if the user is a member of the group
              // and only add the user to the usersTyping array if they are a member
              if (currentChat.isGroup) {
                const isMember = groupMembers?.some(member => member.getUid() === senderUid);

                // if they are not a member, remove them from the array
                if (!isMember) {
                  return prevUsersTyping.filter(user => user.name !== senderName);
                }
              }

              const userIsAlreadyTyping = prevUsersTyping.find(user => user.name === senderName);
              if (userIsAlreadyTyping) return prevUsersTyping;

              return [...prevUsersTyping, { name: typingIndicator.getSender().getName(), typingStarted: Date.now() }];
            });
          }
        },
        onTypingEnded: (typingIndicator: CometChat.TypingIndicator) => {
          // If the typing indicator is for the current chat, remove the user from the usersTyping array
          if (typingIndicator.getSender().getUid() === currentChat.receiverId ||
            currentChat.id === typingIndicator.getReceiverId()
          ) {
            setUsersTyping(prevUsersTyping => {
              if (!prevUsersTyping) return prevUsersTyping;

              return prevUsersTyping.filter(user => user.name !== typingIndicator.getSender().getName());
            });
          }
        },
        onTextMessageReceived: (message: CometChat.TextMessage) => {
          // Do not show messages if the current group is premium and the user is not
          // if (
          //   currentChat.isGroup &&
          //   premiumChannelIds.includes(currentChat.id) &&
          //   !checkUserHasSubscription(loggedInUser?.getRole() ?? '')
          // ) {
          //   return;
          // }

          const senderUid = message.getSender().getUid();
          if (senderUid === loggedInUser?.getUid()) return;

          // Check if the message contains any forbidden characters or if name is empty
          const senderName = message.getSender().getName().replace(/[^a-zA-Z0-9]/g, ''); // remove special characters
          if (senderName === "") return;

          if (
            (message.getReceiverId() === currentChat.id ||
              message.getConversationId() === currentChat.id)
            && !showLoadMostRecentMsgBtn && !checkIfUserIsBlocked(blockedUsers, senderUid)
          ) {
            CometChat.markAsRead(message);
            setMessages(prevMessages => [...prevMessages, message]);
          }

          // Get the conversation and update the last message
          const conversation = getConversationFromMessage(message);

          // Update the last message in the conversation
          if (conversation) {
            conversation.lastMsg = message.getText();
            conversation.isMyMsg = message.getSender().getUid() === loggedInUser?.getUid();
          }
        },
        onMediaMessageReceived: (message: CometChat.BaseMessage) => {
          // Do not show messages if the current group is premium and the user is not
          // if (
          //   currentChat.isGroup &&
          //   premiumChannelIds.includes(currentChat.id) &&
          //   !checkUserHasSubscription(loggedInUser?.getRole() ?? '')
          // ) {
          //   return;
          // }
          const senderUid = message.getSender().getUid();

          if (senderUid === loggedInUser?.getUid()) return;

          if ((message.getReceiverId() === currentChat.id ||
            message.getConversationId() === currentChat.id)
            && !showLoadMostRecentMsgBtn && !checkIfUserIsBlocked(blockedUsers, senderUid)
          ) {
            CometChat.markAsRead(message);

            if (!showLoadMostRecentMsgBtn) setMessages(prevMessages => [...prevMessages, message]);
          }

          // Get the conversation and update the last message
          const conversation = getConversationFromMessage(message);

          // Update the last message in the conversation
          if (conversation) {
            conversation.lastMsg = 'Afbeelding';
            conversation.isMyMsg = message.getSender().getUid() === loggedInUser?.getUid();
          }
        },
        onCustomMessageReceived: (message: CometChat.CustomMessage) => {
          let tags = message.getTags();
          if (!tags) tags = (message.getCustomData() as any).tags;

          if (tags) {
            if (tags.includes('friend_request') || tags.includes('reload_requests')) {
              loadIncomingFriendRequestsRef.current();
              return;
            }

            if (tags.includes('reload_requests')) {
              loadOutgoingFriendRequestsRef.current();
              return;
            }
          }

          if (message.getType() === 'extension_poll' && !showLoadMostRecentMsgBtn) {
            setMessages(prevMessages => [...prevMessages, message]);
          }
        },
        onMessageDeleted: (message: CometChat.BaseMessage) => {
          setMessages(prevMessages => prevMessages.filter(msg => msg.getId() !== message.getId()));
        },
        onMessageEdited: (message: CometChat.BaseMessage) => {
          setMessages(prevMessages => {
            const updatedMessages = prevMessages.map(msg => {
              if (msg.getId() === message.getId()) {
                return message;
              }
              return msg;
            });
            return updatedMessages;
          });
        },
      })
    );

    return () => {
      CometChat.removeMessageListener(listenerId);
    };
  }, [currentChat]);

  const checkIfMessageContainsForbiddenChars = (message: CometChat.BaseMessage) => {
    if (!(message instanceof CometChat.TextMessage)) return false;
    const textMessage = message as CometChat.TextMessage;
    const invisibleCharacterRegex = /[\u200B\u200C\u200D\u200E\u200F\u202A\u202B\u202C\u202D\u202E\u2060\u2061\u2062\u2063\u2064\u2066\u2067\u2068\u2069\uFEFF\u00AD\u034F\u0300-\u036F\u180E]/;

    let containsForbiddenCharacter = false;
    const forbiddenCharacters = ["𝚛", "𝚑", "𝚊", "𝚝", "𝚒", "𝒞", "ℂ", "ℂ", "ḥ"];

    // Check if the message contains any of the forbidden characters
    forbiddenCharacters.forEach((char) => {
      if (textMessage.getText().includes(char)) {
        containsForbiddenCharacter = true;
      }
    });

    return invisibleCharacterRegex.test(textMessage.getText()) || containsForbiddenCharacter;
  };

  const getBotWelcomeMessage = (chatId: string) => {

    const getAdultWarning = () => {
      return (
        <>
          <b>🚨 Let op! 🚨</b> Deze chat is <b>18+</b> 🔞<br />
        </>
      )
    }

    switch (chatId) {
      case "admin_chat":
        return (
          <span className='chatbot-msg'>
            <b>Let op:</b> Deze chatroom is uitsluitend bedoeld voor het stellen van vragen over problemen of ondersteuning. Gebruik deze ruimte niet voor gewone gesprekken. Bedankt voor je begrip!
          </span>
        );
      case "familymeeting":
        return (
          <span className="chatbot-msg">
            📢 Welkom in Family Meeting! 🏡<br />
            ⚠️ Dit kanaal is vanaf 16 jaar, waardoor 18+ onderwerpen, profiel, en namen hier niet thuis horen.<br />
            ⚠️ Heb je last van iemand? Blokkeer deze chatter dan. <br />
            ⚠️ Zie je iets wat niet door de beugel kan? Meld het (het liefst met screenshot) in het Admin Hulp kanaal.<br />
            <br />
            Veel plezier, en laten we het een fijne en veilige omgeving houden voor iedereen! 🪻
          </span>
        )
      case "lesbox":
        return (
          <span className='chatbot-msg'>
            {getAdultWarning()}
            <hr />
            🎉 Welkom in de chatroom Lesbox! 🌸<br />
            Deze ruimte is speciaal voor vrouwen om in een veilige en respectvolle omgeving te praten. <br />
            <br />
            ⚠️ <b>Huisregels:</b><br />
            Respecteer elkaar en houd het gezellig.<br />
            Voel je vrij om vragen te stellen of gesprekken te starten! 😊<br />
            Veel plezier en laten we er samen iets moois van maken! 🌈<br />
          </span>
        );
      case "gaylife":
      case "gewaagd":
      case "zaadbunker":
      case "sm_dungeon":
      case "pikant":
      case "bibox":
        return (
          <span className='chatbot-msg'>
            {getAdultWarning()}
          </span>
        );
      default:
        return null;
    }
  }

  return (
    <div className="chat-component">
      <EditMessageModal
        editMessage={editMessage}
        setEditMessage={setEditMessage}
        setMessages={setMessages}
        setAlert={setAlert}
      />
      <ReportMessageModal
        messageToReport={messageToReport}
        setMessageToReport={setMessageToReport}
        setAlert={setAlert}
      />

      <AudioChannelHeader
        ongoingCall={ongoingCall}
        setOngoingCall={setOngoingCall}
        outgoingCall={outgoingCall}
        setOutgoingCall={setOutgoingCall}
      />

      <ChannelHeaderComponent
        blockedUsers={blockedUsers}
        handleBlockUser={handleBlockUser}
        currentChat={currentChat}
        setCurrentChat={setCurrentChat}
        friends={friends}
        hasSentFriendRequest={hasSentFriendRequest}
        hasReceivedFriendRequest={hasReceivedFriendRequest}
        handleAddOrRemoveFriend={handleAddOrRemoveFriend}
        setUserToReport={setUserToReport}
        leftMenuOpen={leftMenuOpen}
        setLeftMenuOpen={setLeftMenuOpen}
        rightMenuOpen={rightMenuOpen}
        setRightMenuOpen={setRightMenuOpen}
        unreadCount={unreadCount}
        userSettings={userSettings}
        handleRemoveConversation={handleRemoveConversation}
        loggedInUserIsAdmin={checkIfUserHasPermissions(loggedInUser!)}

        outgoingCall={outgoingCall}
        ongoingCall={ongoingCall}

        handleInitiateCall={handleInitiateCall}
        handleTerminateCall={handleTerminateCall}
      />

      <div className={`message-list ${emojiMenuOpen ? 'emoji-padding' : commandMenuOpen ? 'command-padding' : messageToReply ? 'reply-padding' : ''}`}>
        <GoogleAdSense
          adSlot="8285598752"
          className="chat-top-rectangle"
          isRoyal={checkUserIsRoyal(loggedInUser?.getRole() ?? "")}
        />

        <GoogleAdSense
          adSlot="5430939007"
          style={{ width: "300px", height: "60px" }}
          className="chat-top-rectangle-mobile"
          isRoyal={checkUserIsRoyal(loggedInUser?.getRole() ?? "")}
        />

        <MessageListComponent>
          {showLoadMoreBtn && messages.length > 0 && (
            <Button
              inverted
              onClick={fetchOlderMessages}
              style={{ marginTop: "150px" }}
            >
              Laad Meer Berichten
            </Button>
          )}

          {publicChannelIds.includes(currentChat.id) && !isFetching && (
            <Message
              avatar={chatBotImg}
              body={getBotWelcomeMessage(currentChat.id)}
              createdAt={
                <span className='chatbot-msg'>
                  {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                </span>
              }
              username={
                <>
                  <span>ChatBot </span>
                  <AdminBadge text="bot" />
                </>
              }
            />
          )}

          {/* {(currentChat.showPinnedMessages ? pinnedMessages : messages) */}
          {messages.map((message, index) => (
            checkIfMessageContainsForbiddenChars(message) ? null : (
              <MessageComponent
                key={message.getId()}
                message={message}
                myMessage={loggedInUser !== null && message.getSender().getUid() === loggedInUser.getUid()}
                showMessageSeparator={index === 0 || getDateFromTimestamp(message.getSentAt()) !== getDateFromTimestamp((messages)[index - 1].getSentAt())}
                isBlocked={checkIfUserIsBlocked(blockedUsers, message.getSender().getUid())}
                handleDeleteMessage={handleDeleteMessage}
                setEditMessage={setEditMessage}
                setMessageToReport={setMessageToReport}
                handleBlockUser={handleBlockUser}
                groupMembers={groupMembers}
                loggedInUser={loggedInUser ?? undefined}
                setCurrentChat={setCurrentChat}
                setAlert={setAlert}

                isAdmin={checkIfUserIsAdmin(message.getSender())}
                isModerator={checkIfUserIsModerator(message.getSender())}
                loggedInUserIsAdmin={checkIfUserIsAdmin(loggedInUser!)}
                loggedInUserIsModerator={checkIfUserIsModerator(loggedInUser!)}

                handleMuteUser={handleMuteUser}
                handleKickUser={handleKickUser}
                handleLogoutKickUser={handleLogoutKickUser}
                handleBanUser={handleBanUser}
                handleDeleteMessageAsAdmin={handleDeleteMessageAsAdmin}
                currentChat={currentChat}
                userSettings={userSettings}
                setMessageToReply={setMessageToReply}
                setUserToWarn={setUserToWarn}
                setMessageToForward={setMessageToForward}
                setActiveTabIndex={setActiveTabIndex}
              />
            )))}
          {/* Display a message from the ChatBot if the chat is a private chat and the user has not sent any messages yet: */}
          {(!currentChat.isGroup && chatWithUser) && messages.length === 0 && !isFetching && (
            <Message
              avatar={chatBotImg}
              body={
                <span className='chatbot-msg'>
                  Deel nooit gegevens met vreemden en open nooit onbekende urls. ChatPlaza en moderators zullen nooit vragen naar accountgegevens of vragen om betalingen te doen. Kom je iets verdachts tegen? Rapporteer het bericht en/of de gebruiker.
                </span>
              }
              createdAt={
                <span className='chatbot-msg'>
                  {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                </span>
              }
              username={
                <>
                  <span>ChatBot </span>
                  <AdminBadge text="bot" />
                  <small> Alleen zichtbaar voor jou</small>
                </>
              }
            />
          )}

          {showLoadMostRecentMsgBtn && messages.length > 0 && (
            <Button
              inverted
              onClick={fetchMessages}
            >
              Laad Recente Berichten
            </Button>
          )}
          <div ref={messageEndRef} />
        </MessageListComponent>
      </div>

      <MessageInputComponent
        inputRef={inputRef}
        handleSendMessage={handleSendMessage}
        handleSendImage={handleSendImage}
        groupMembers={groupMembers}
        chatWithUser={chatWithUser}

        emojiMenuOpen={emojiMenuOpen}
        setEmojiMenuOpen={setEmojiMenuOpen}
        setCommandMenuOpen={setCommandMenuOpen}
        currentChat={currentChat}
        userSettings={userSettings}

        messageToReply={messageToReply}
        setMessageToReply={setMessageToReply}

        startedTyping={startedTyping}
        setStartedTyping={setStartedTyping}
        typingNotification={typingNotification}

        usersTyping={usersTyping}
        setUsersTyping={setUsersTyping}
      />
    </div>
  );
};

export default ChatComponent; 