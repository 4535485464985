import Input from 'anchor-ui/input';
import Button from 'anchor-ui/button';
import { IconEdit } from 'anchor-ui/icons';
import Divider from 'anchor-ui/divider';
import { SetStateAction, useEffect, useState, useRef } from "react";
import {
  getAuth,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  updatePhoneNumber,
  User,
  PhoneAuthProvider,
  multiFactor,
  RecaptchaVerifier,
  PhoneMultiFactorGenerator,
} from "firebase/auth";
import { Functions, httpsCallable } from "firebase/functions"; // Added httpsCallable
import { validatePassword } from '../../../utils/validatePassword';
import DeleteAccountModal from "./DeleteAccountModal";

const AccountPage = ({
  loggedInUser,
  setAlert,
  functions,
  handleLogout,
  user,
}: {
  loggedInUser: CometChat.User,
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>,
  functions: Functions,
  handleLogout: () => void,
  user: User | null,
}) => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<{ input: string, msg: string } | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isDisabling2FA, setIsDisabling2FA] = useState(false);

  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState<string | null>(null);

  const [modalOpen, setModalOpen] = useState(false);

  // Invisible reCAPTCHA Ref
  const recaptchaRef = useRef(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<any>(null);

  const cancelChangePassword = () => {
    setShowChangePassword(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setError(null);
  };

  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    const auth = getAuth();
    const user = auth.currentUser;

    if (newPassword !== confirmPassword) {
      setError({ input: 'confirm-pw', msg: 'Wachtwoorden komen niet overeen.' });
      return;
    }

    // Validate password and show specific error if invalid
    const passwordValidationError = await validatePassword(auth, newPassword);
    if (passwordValidationError) {
      setError({ input: 'new-pw', msg: passwordValidationError });
      return;
    }

    if (user) {
      const credential = EmailAuthProvider.credential(user.email!, oldPassword);

      try {
        setIsSubmitting(true);
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, newPassword);
        setShowChangePassword(false);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setAlert({ message: 'Je wachtwoord is gewijzigd', type: 'success' });
      } catch (error) {
        if ((error as any).code === 'auth/invalid-credential') {
          setError({ input: 'old-pw', msg: 'Verkeerd wachtwoord' });
        } else {
          setError({ input: 'general', msg: 'Er is iets misgegaan. Probeer het later opnieuw.' });
          setAlert({ message: 'Er is iets misgegaan. Probeer het later opnieuw.', type: 'error' });
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (user) {
      if ('multiFactor' in user) {
        setTwoFactorEnabled((user.multiFactor as any).enabled ?? false);
      } else {
        // Fallback to checking if the user has any providers
        setTwoFactorEnabled(user.providerData.length > 1);
      }
    }
  }, [user]);

  const handleSetup2FA = async () => {
    try {
      if (!user || !recaptchaRef.current) {
        throw new Error("No active user");
      }

      const auth = getAuth();
      let verifier = recaptchaVerifier;
      if (!verifier) {
        // Create an invisible reCAPTCHA verifier
        verifier = new RecaptchaVerifier(auth, recaptchaRef.current, {
          size: "invisible",
          callback: (response: any) => {
            console.log("reCAPTCHA solved:", response);
          },
        });

        // Render the invisible reCAPTCHA
        await verifier.render();
        setRecaptchaVerifier(verifier);
      }

      // Start the phone auth flow with reCAPTCHA
      const phoneProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneProvider.verifyPhoneNumber(phoneNumber, verifier);

      // Enable multi-factor
      const phoneAuthCredential = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
      await multiFactor(user).enroll(multiFactorAssertion);

      setVerificationId(verificationId);
      setAlert({ message: 'Verification code sent to your phone.', type: 'success' });
    } catch (error) {
      console.error('Error setting up 2FA:', error);
      setAlert({ message: 'Failed to set up 2FA', type: 'error' });
    }
  };

  const handleVerifyCode = async () => {
    try {
      if (!user || !verificationId) {
        throw new Error('No active user or verification ID');
      }
      const credential = PhoneAuthProvider.credential(verificationId, verificationCode);

      await updatePhoneNumber(user, credential);
      setTwoFactorEnabled(true);
      setAlert({ message: '2FA successfully enabled.', type: 'success' });
      setVerificationCode("");
      setVerificationId(null);
    } catch (error) {
      console.error('Error verifying code:', error);
      setAlert({ message: 'Failed to verify code.', type: 'error' });
    }
  };

  const handleRemove2FA = async () => {
    try {
      if (!user) {
        throw new Error('No active user');
      }

      setIsDisabling2FA(true);
      
      // Call the Cloud Function to disable 2FA
      const disable2FAFunction = httpsCallable(functions, 'disable2FA');
      const result = await disable2FAFunction({});
      
      // Check the result
      const data = result.data as { success: boolean, message: string };
      
      if (data.success) {
        setTwoFactorEnabled(false);
        setAlert({ message: data.message, type: 'success' });
      } else {
        setAlert({ message: data.message, type: 'warning' });
      }
    } catch (error) {
      console.error('Error removing 2FA:', error);
      setAlert({ message: 'Failed to remove 2FA.', type: 'error' });
    } finally {
      setIsDisabling2FA(false);
    }
  };

  return (
    <div>
      <DeleteAccountModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        setAlert={setAlert}
        functions={functions}
        handleLogout={handleLogout}
      />

      <h2>Account Page</h2>

      <section className="account-section">
        <span className="account-label">
          <span className="account-label-text">
            Gebruikersnaam
          </span>
        </span>
        <p className="account-username">{loggedInUser.getName()}</p>
      </section>

      <section className="account-section">
        {showChangePassword ? (
          <form onSubmit={handleChangePassword}>
            <Input
              label="Oud Wachtwoord"
              required
              maxLength={256}
              value={oldPassword}
              onChange={(e: { target: { value: SetStateAction<string>; }; }) => setOldPassword(e.target.value)}
              error={error?.input === 'old-pw' ? error.msg : undefined}
              type="password"
            />
            <Input
              label="Nieuw Wachtwoord"
              required
              maxLength={256}
              value={newPassword}
              onChange={(e: { target: { value: SetStateAction<string>; }; }) => setNewPassword(e.target.value)}
              error={error?.input === 'new-pw' ? error.msg : undefined}
              type="password"
            />
            <Input
              label="Bevestig Wachtwoord"
              required
              maxLength={256}
              value={confirmPassword}
              onChange={(e: { target: { value: SetStateAction<string>; }; }) => setConfirmPassword(e.target.value)}
              error={error?.input === 'confirm-pw' ? error.msg : undefined}
              type="password"
            />

            <div>
              <Button type="submit" disabled={isSubmitting || !oldPassword || !newPassword || !confirmPassword}>
                Wijzig
              </Button>
              <Button
                inverted
                onClick={cancelChangePassword}
              >
                Annuleer
              </Button>
            </div>
            {error?.input === 'general' && <p className="error-message">{error.msg}</p>}
          </form>
        ) :
          <div className="change-password" onClick={() => setShowChangePassword(true)}>
            <Input
              label="Wachtwoord"
              value="*********"
            />
            <Button iconButton><IconEdit /></Button>
          </div>
        }
      </section>

      {loggedInUser.getName() === "Job" &&
        <section className="account-section">
          <Divider />
          <h3>Twee-Factor Authenticatie</h3>
          {twoFactorEnabled ? (
            <>
              <p>2FA is ingesteld.</p>
              <Button onClick={handleRemove2FA} disabled={isDisabling2FA}>
                {isDisabling2FA ? 'Removing...' : 'Remove 2FA'}
              </Button>
            </>
          ) : (
            <>
              <Input
                label="Phone Number"
                value={phoneNumber}
                onChange={(e: { target: { value: SetStateAction<string>; }; }) => setPhoneNumber(e.target.value)}
                placeholder="Enter your phone number"
              />
              {verificationId ? (
                <>
                  <Input
                    label="Verification Code"
                    value={verificationCode}
                    onChange={(e: { target: { value: SetStateAction<string>; }; }) => setVerificationCode(e.target.value)}
                    placeholder="Enter the verification code"
                  />
                  <Button onClick={handleVerifyCode}>Verify Code</Button>
                </>
              ) : (
                <>
                  <Button onClick={handleSetup2FA}>Enable 2FA</Button>
                </>
              )}
            </>
          )}
        </section>
      }

      <section className="account-section delete-account">
        <Divider />
        <Button onClick={() => setModalOpen(true)}>Verwijder Account</Button>
      </section>

      {/* Invisible reCAPTCHA element */}
      <div ref={recaptchaRef}></div>
    </div>
  );
};

export default AccountPage;